
import defaultSettingsGroup from "./defaultSettingsGroup";
import counterpartyGroup from "./counterpartyGroup";
import { fetchOldApi } from "./fetch";
import authGroup from "./authGroup";
import currenciesGroup from "./currenciesGroup";
import settingsGroup from "./settingsGroup";
import hiddenGroup from "./hiddenGroup";
import changeLogGroup from "./changeLogGroup";
import proxyGroup from "./proxiesGroup";
import walletGroup from "./walletGroup";
import walletTypesGroup from "./walletTypesGroup";
import countryGroup from "./countryGroup";
import walletsDebuggingGroup from "./walletsDebuggingGroup";

const API = {
	fetch: fetchOldApi,
	auth: authGroup,
	currencies: currenciesGroup,
	changeLog: changeLogGroup,
	defaultSetting: defaultSettingsGroup,
	walletType: walletTypesGroup,
	settings: settingsGroup,
	counterparty: counterpartyGroup,
	hidden: hiddenGroup,
	wallet: walletGroup,
	proxy: proxyGroup,
	country: countryGroup,
	walletsDebugging: walletsDebuggingGroup,
};

export default API;
