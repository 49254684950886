// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fields__header-Snl{display:flex;flex-direction:column;align-items:flex-start;margin:16px 0;gap:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Hidden/Fields/fields.module.scss"],"names":[],"mappings":"AAAA,oBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,OAAA","sourcesContent":[".header {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin: 16px 0;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Fields__header-Snl`
};
export default ___CSS_LOADER_EXPORT___;
