// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyTablePlaceholder__wrapper-n9Y{padding-top:14px;padding-bottom:14px;display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/components/EmptyTablePlaceholder/styles.module.scss"],"names":[],"mappings":"AAAA,oCACE,gBAAA,CACA,mBAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":[".wrapper {\n  padding-top: 14px;\n  padding-bottom: 14px;\n  display: flex;\n  justify-content: center;\n}\n\n.title {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EmptyTablePlaceholder__wrapper-n9Y`
};
export default ___CSS_LOADER_EXPORT___;
