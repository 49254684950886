import { GetFormTypesType, GetHintType } from "api/defaultSettingsGroup";
import CaretIcon from "components/Icons/CaretIcon";
import { useActionsContext } from "modules/DefaultSettings/ActionsProvider";
import Loader from "modules/DefaultSettings/LoadingHandler";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import { Dispatch, FC, ReactElement, SetStateAction, useState } from "react";

import styles from "./formTypesSelector.module.scss";

const FormTypesSelector: FC<FormTypesSelectorType> = ({
	children,
	formTypes,
	paymentType,
	setFormType,
	selectedPs,
}) => {
	const [activeTab, setActiveTab] = useState<FormTypeTabType>("requisite");
	const { open, isSaved } = useActionsContext();
	const renderFormTypes = () => {
		return formTypes?.map((formType, i) => {
			const formTypeStyle =
				activeTab === formType.type ? [styles.formType, styles.activeTab].join(" ") : styles.formType;
			return (
				!(paymentType === `api` && formType.type === `payment`) && (
					<div
						data-test-id="olhBbd_f-ppnXqT0j1FfE"
						key={i}
						className={formTypeStyle}
						onClick={() => {
							if (!isSaved) {
								open();
							} else {
								setActiveTab(formType.type);
								setFormType(formType.type);
							}
						}}
					>
						{formType.title}
						<CaretIcon className={styles.caret} />
					</div>
				)
			);
		});
	};

	return (
		<div className={styles.formTypeWrapper}>
			<div className={styles.formTypeContainer}>
				<div className={styles.header}>Форма</div>
				<Loader className={styles.loader} color="#C5CBD1" isLoading={!selectedPs}>
					<div className={styles.formTypesList}>{renderFormTypes()}</div>
				</Loader>
			</div>
			{children}
		</div>
	);
};

export default FormTypesSelector;

type FormTypesSelectorType = {
	readonly children: ReactElement;
	readonly formTypes: readonly GetFormTypesType[] | null;
	readonly paymentType?: PsOptionType["payment"];
	readonly setFormType: Dispatch<SetStateAction<GetHintType["form_type"]>>;
	readonly selectedPs: PsOptionType;
};

type FormTypeTabType = GetHintType["form_type"];
