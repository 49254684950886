import API from "api";
import { WalletTypesItemType } from "api/walletTypesGroup";
import { useCallback, useState } from "react";

const useGetWalletTypesList = () => {
	const [list, setList] = useState<readonly WalletTypesItemType[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load = useCallback(() => {
		setIsLoading(true);
		setError(null);

		API.walletType.getList()
			.then((res) => {
				if (res.status === "success") {
					setList(res.data);
				} else {
					throw new Error("Unexpected response in useGetWalletTypesList");
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useGetWalletTypesList;
