import ConfirmationModal from "../ConfirmationModal";
import useRemoveCurrencyHook from "./useRemoveCurrencyHook";
import styles from "./RemoveConfirmationModal.module.scss";

type RemoveConfirmationModalPropsType = {
	isOpen: boolean;
	onClose: () => void;
	code?: string;
	onSuccess: () => void;
};

const RemoveConfirmationModal: React.FC<RemoveConfirmationModalPropsType> = ({ isOpen, onClose, code, onSuccess }) => {
	const removeCurrencyHookAPI = useRemoveCurrencyHook({ onSuccess });

	return (
		<ConfirmationModal
			isOpen={isOpen}
			onConfirm={code ? () => removeCurrencyHookAPI.remove([code]) : () => {}}
			onClose={onClose}
			title={`Удаление валюты`}
			confirmButtonVariant="delete"
			text={
				<div className={styles.text}>
					<b>
						Валюта будет удалена <strong>навсегда</strong> из всех частей системы.
					</b>
					Вы уверены, что хотите удалить {code}?
				</div>
			}
			confirmText="Удалить"
			isLoading={removeCurrencyHookAPI.isLoading}
		/>
	);
};

export default RemoveConfirmationModal;
