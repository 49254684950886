import API from "api";
import { UpdateHintsParams, UpdateHintsType } from "api/defaultSettingsGroup";
import { StandardResponse } from "api/types";
import { useCallback, useState } from "react";

const useUpdateHints = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const update: UpdateType = useCallback((id, params, onSuccess, onError) => {
		setIsLoading(true);
		setError(null);

		API.defaultSetting
			.updateHints(id, params)
			.then((res) => {
				if (res.status === "success") {
					onSuccess && onSuccess(res);
					window.pushAlert({
						title: "Успех",
						type: "success",
						description: `Текстовые подсказки успешно обновлены`,
					});
				} else if (res.error_message) {
					onError && onError();
				} else {
					throw new Error("Unexpected response in useUpdateHints");
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		update,
		isLoading,
		error,
	};
};

export default useUpdateHints;

type UpdateType = (
	id: string,
	params: UpdateHintsParams,
	onSuccess?: (res: StandardResponse<UpdateHintsType>) => void,
	onError?: () => void
) => void;
