import { PenIcon } from "components/Icons/PenIcon";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { Button, Tooltip } from "pay-kit/lib";
import styles from "./Control.module.scss";

type ControlProps = {
	readonly actions: {
		readonly onEdit?: () => void;
		readonly onDelete?: () => void;
	};
	readonly displaySettings?: {
		readonly edit?: boolean;
		readonly delete?: boolean;
	};
};

export const Control = ({
	actions,
	displaySettings = {
		edit: true,
		delete: true,
	},
}: ControlProps) => {
	const handleEditClick = () => {
		actions.onEdit && actions.onEdit();
	};

	const handleDeleteClick = () => {
		actions.onDelete && actions.onDelete();
	};

	return (
		<div className={styles.control}>
			{displaySettings.edit && (
				<Tooltip tip="Редактировать">
					<div>
						<button
							data-test-id="1N0NKVcipl5OaywZSZVEP"
							className={styles.button}
							onClick={handleEditClick}
							>
							<PenIcon />
						</button>
					</div>
				</Tooltip>
			)}
			{displaySettings.delete && (
				<Tooltip tip="Удалить">
					<div>
						<button
							data-test-id="0da3E5f1pKDY6a0w0Sf4K"
							className={styles.button}
							type="button"
							onClick={handleDeleteClick}
						>
							<TrashIcon />
						</button>
					</div>
				</Tooltip>
			)}
		</div>
	);
};
