// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Control__control-j54{display:flex}.Control__button-WC8{min-width:0;padding:4px;margin-right:8px;border:none;background-color:rgba(0,0,0,0);cursor:pointer}.Control__button-WC8 svg{fill:#697077}`, "",{"version":3,"sources":["webpack://./src/components/Control/Control.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CAGD,qBACC,WAAA,CACA,WAAA,CACA,gBAAA,CACA,WAAA,CACA,8BAAA,CACA,cAAA,CAEA,yBACC,YAAA","sourcesContent":[".control {\n\tdisplay: flex;\n}\n\n.button {\n\tmin-width: 0;\n\tpadding: 4px;\n\tmargin-right: 8px;\n\tborder: none;\n\tbackground-color: transparent;\n\tcursor: pointer;\n\n\tsvg {\n\t\tfill: #697077;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `Control__control-j54`,
	"button": `Control__button-WC8`
};
export default ___CSS_LOADER_EXPORT___;
