import React, { useState } from "react";
import { CurrenciesListItem } from "api/currenciesGroup";
import CreateNewModal from "./components/CreateNewModal";
import EditModal from "./components/EditModal";
import UpdateRatesModal from "./components/UpdateRatesModal";
import RemoveConfirmationModal from "./components/RemoveConfirmationModal";

export const ActionsContext = React.createContext({
	remove: (code: string) => {},
	edit: (code: string) => {},
	create: () => {},
	updateRates: (codes: string[] | undefined) => {},
});

type ActionsProviderPropsType = {
	readonly children: React.ReactNode;
	readonly refreshList: () => void;
	readonly currencies: readonly CurrenciesListItem[];
};

const ActionsProvider = ({ children, refreshList, currencies }: ActionsProviderPropsType) => {
	const [removeConfirmationId, setRemoveConfirmationId] = useState<string | undefined>(undefined);
	const [editModalId, setEditModalId] = useState<string | undefined>(undefined);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [updateRateIds, setUpdateRateIds] = useState<string[] | undefined>(undefined);

	return (
		<ActionsContext.Provider
			value={{
				remove: (code: string) => setRemoveConfirmationId(code),
				edit: (code: string) => setEditModalId(code),
				create: () => setShowCreateModal(true),
				updateRates: (codes: string[] | undefined) => setUpdateRateIds(codes),
			}}
		>
			<RemoveConfirmationModal
				isOpen={removeConfirmationId !== undefined}
				code={removeConfirmationId}
				onClose={() => setRemoveConfirmationId(undefined)}
				onSuccess={() => {
					setRemoveConfirmationId(undefined);
					refreshList();
				}}
			/>

			<UpdateRatesModal
				isOpen={updateRateIds !== undefined}
				onClose={() => setUpdateRateIds(undefined)}
				codes={updateRateIds}
				onSuccess={() => {
					setUpdateRateIds(undefined);
					refreshList();
				}}
			/>

			<CreateNewModal
				isOpen={showCreateModal}
				onClose={() => setShowCreateModal(false)}
				onSuccess={() => {
					refreshList();
					setShowCreateModal(false);
				}}
			/>

			<EditModal
				isOpen={editModalId !== undefined}
				onClose={() => setEditModalId(undefined)}
				currencyData={currencies.find(({ code }) => code === editModalId) as CurrenciesListItem}
				onSuccess={() => {
					refreshList();
					setEditModalId(undefined);
				}}
			/>

			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;
