import { Panel } from "@paykassma/pay-kit";
import { blackListGroup, Case } from "api/blackListGroup";
import PageTitle from "modules/BlackList/components/PageTitle";
import RoutedTabs from "components/RoutedTabs";
import Details from "modules/BlackList/components/Details";
import { FC, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { LinkToIncidents } from "modules/BlackList/components/LinkToIncidents";

type Props = {
	id?: string;
};

export const OneIncident: FC<Props> = ({ id }) => {
	const [currentCase, setCurrentCase] = useState<Case | null>(null);

	const fetcher = async () => {
		try {
			const resp = await blackListGroup.getCase(id);

			if (resp.status === "success") {
				// Оттуда массив летит
				setCurrentCase(resp.data[0]);
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetcher();
	}, [id]);

	const tabs = [
		{
			label: `Реквизиты ${currentCase?.credentials_count || ""}`,
			value: `/black-list/incidents/${id}/requisites`,
		},
		{
			label: `Транзакции ${currentCase?.transactions_count || ""}`,
			value: `/black-list/incidents/${id}/transactions`,
		},
	];

	return (
		<>
			<LinkToIncidents />
			<PageTitle id={currentCase?.id} />
			<Details caseData={currentCase} onSuccess={fetcher} />
			<Panel title="">
				<RoutedTabs tabs={tabs} />
				<Outlet />
			</Panel>
		</>
	);
};
