import { useState } from "react";
import API, { CreateNewCurrencyType } from "api/currenciesGroup";

const useCreateNewHook = ({ onSuccess }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const create = (data: CreateNewCurrencyType) => {
		setIsLoading(true);

		API.createNew(data)
			.then((res) => {
				if (res.status === "success") {
                    onSuccess();
					window.pushAlert({
						description: "Валюта успешно добавлена",
						type: "success"
					})

					return res;
				}

				if (res.status === "error") {
					window.pushAlert({
						description: res.error_message,
						type: "error"
					})

					return res;
				}

				throw new Error("Unexpected response in useCreateNewHook");
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		create,
		isLoading,
	};
};

export default useCreateNewHook;
