import { useState, useEffect, useMemo } from "react";
import { Button, TextInput, Table, Tooltip } from "@paykassma/pay-kit";
import styles from "./Currencies.module.scss";
import { CurrenciesListItem } from "api/currenciesGroup";
import ActionsProvider, { ActionsContext } from "modules/Currencies/ActionsProvider";
import Money from "components/Money";
import useCurrenciesGroup from "./hooks/useCurrenciesGroup";
import CryptoIcon from "./icons/crypto.svg";
import EditIcon from "./icons/edit.svg";
import RefreshIcon from "./icons/refresh.svg";
import RemoveIcon from "./icons/remove.svg";
import Refresh16x16Icon from "./icons/refresh_16x16.svg";
import PlusIcon from "src/assets/icons/plus.svg";
import RelaunchButton from "components/RelaunchButton";

const Currencies = () => {
	const [codeFilter, setCodeFilter] = useState<CurrenciesListItem["code"]>("");
	const [searchCode, setSearch] = useState<CurrenciesListItem["code"]>("");
	const currenciesAPI = useCurrenciesGroup();

	const columns = [
		{
			title: "Код",
			dataIndex: "code",
			key: "code",
			className: styles.codeColumn,
			render: ({ code, is_crypto }: CurrenciesListItem) => (
				<>
					{code}{" "}
					{is_crypto && (
						<Tooltip tip="Криптовалюта" preferredSide="bottom">
							<CryptoIcon />
						</Tooltip>
					)}
				</>
			),
		},
		{
			title: "Название",
			dataIndex: "name",
			key: "name",
			render: ({ code, name }: CurrenciesListItem) => <>{name || code}</>,
		},
		{
			title: "Символ",
			dataIndex: "symbol",
			key: "symbol",
		},
		{
			title: "Позиция символа",
			dataIndex: "symbol_position",
			key: "symbol_position",
			render: ({ symbol_position }: CurrenciesListItem) => <>{{ 1: "Слева", 2: "Справа" }[symbol_position]}</>,
		},
		{
			title: "Курс",
			dataIndex: "rate",
			key: "rate",
			render: ({ rate }: CurrenciesListItem) => rate.rate,
		},
		{
			title: "Дата изменения курса",
			dataIndex: "rate_updated_at",
			key: "rate_updated_at",
			render: ({ rate }: CurrenciesListItem) => <>{rate.updated_at}</>,
		},
		{
			title: "Действия",
			dataIndex: "code",
			key: "actions",
			className: styles.actionsCol,
			render: ({ code }: CurrenciesListItem) => (
				<ActionsContext.Consumer>
					{({ remove, edit, updateRates }) => (
						<div className={styles.actions}>
							<Tooltip tip="Обновить курс валюты" preferredSide="bottom">
								<button onClick={() => updateRates([code])}>
									<RefreshIcon />
								</button>
							</Tooltip>
							<Tooltip tip="Редактировать" preferredSide="bottom">
								<button onClick={() => edit(code)}>
									<EditIcon />
								</button>
							</Tooltip>
							<Tooltip tip="Удалить" preferredSide="bottom">
								<button onClick={() => remove(code)}>
									<RemoveIcon />
								</button>
							</Tooltip>
						</div>
					)}
				</ActionsContext.Consumer>
			),
		},
	];

	const getList = () => currenciesAPI.load(codeFilter);

	useEffect(() => {
		getList();
	}, []);

	const foundCurrencies = useMemo(
		() =>
			currenciesAPI.list.filter(
				({ code, name }: CurrenciesListItem) =>
					!codeFilter ||
					`${code !== name ? `${code} ${name}` : code}`.toLocaleLowerCase().includes(codeFilter.toLocaleLowerCase())
			),
		[codeFilter, currenciesAPI.list]
	);

	return (
		<ActionsProvider refreshList={getList} currencies={currenciesAPI.list}>
			<div className={styles.topActions}>
				<form
					className={styles.filter}
					onSubmit={(e) => {
						e.preventDefault();
						getList();
					}}
				>
					<div className={styles.filters}>
						<TextInput
							name="code"
							value={searchCode}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Поиск"
							className={styles.select}
						/>
						<div className={styles.buttons}>
							<Button onClick={() => setCodeFilter(searchCode)}>Применить</Button>
							{codeFilter && <Button onClick={() => {
								setCodeFilter("");
								setSearch("");
							}}>Очистить фильтры</Button>}
						</div>
					</div>
				</form>

				<ActionsContext.Consumer>
					{({ create, updateRates }) => (
						<div className={styles.actions}>
							<Button variant="primary" onClick={create}>
								<PlusIcon />
								&nbsp; Добавить валюту
							</Button>
							<Button variant="secondary" onClick={() => updateRates(currenciesAPI.list.map((c) => c.code))}>
								<Refresh16x16Icon />
								&nbsp; Обновить курсы валют
							</Button>
						</div>
					)}
				</ActionsContext.Consumer>
			</div>

			<RelaunchButton 
				onClick={getList} 
				btnProps={{ 
					variant: "text-primary", 
					isFullWidth: false,
				}}
				className={styles.refreshBtn}
			>
				Обновить
			</RelaunchButton>

			<Table
				className={styles.table}
				stickyHead
				data={foundCurrencies}
				columns={columns}
				rowKey="code"
				isLoading={currenciesAPI.isLoading}
				emptyPlaceholder={currenciesAPI.list.length > 0 ? "Валюта не найдена" : undefined}
				skeleton={{
					rowsCount: 20
				}}
			/>
		</ActionsProvider>
	);
};

export default Currencies;
