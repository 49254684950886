import Relaunch from "assets/icons/relaunch.svg";
import { Button, Loader } from "pay-kit";
import { ComponentProps, FC } from "react";

import styles from "./relaunchButton.module.scss";

type RelaunchButtonType = {
	readonly onClick?: () => void;
	readonly children: JSX.Element | string;
	readonly className?: string;
	readonly loaderColor?: string;
	readonly isLoading?: boolean;
	readonly isDisabled?: boolean;
	readonly btnProps?: ComponentProps<typeof Button>;
};

const RelaunchButton: FC<RelaunchButtonType> = ({
	className,
	onClick,
	children,
	isLoading,
	isDisabled = false,
	loaderColor,
	btnProps,
}) => {
	return (
		<Button
			data-test-id="y0GLBrYOkMVrVA5jZIskq"
			classname={[styles.restartCheck, className].join(" ")}
			onClick={onClick}
			disabled={isDisabled}
			{...btnProps}
		>
			<div className={styles.relaunch}>
				{isLoading ? (
					<span>
						<Loader color={loaderColor} />
					</span>
				) : (
					<Relaunch />
				)}
			</div>
			{children}
		</Button>
	);
};

export default RelaunchButton;
