// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectionDisplay__selectionDisplay-YfZ{width:100%;cursor:pointer;font-size:16px;padding:8px 8px 8px 16px;border-radius:8px;border:1px solid #dde1e6}.SelectionDisplay__selectionDisplay-YfZ:hover{background:#dde1e6}.SelectionDisplay__selectionDisplay-YfZ .SelectionDisplay__loader-FjE{display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/components/SelectionDisplay/selectionDisplay.module.scss"],"names":[],"mappings":"AAAA,wCACE,UAAA,CACA,cAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,wBAAA,CAEA,8CACE,kBAAA,CAGF,sEACE,YAAA,CACA,sBAAA","sourcesContent":[".selectionDisplay {\n  width: 100%;\n  cursor: pointer;\n  font-size: 16px;\n  padding: 8px 8px 8px 16px;\n  border-radius: 8px;\n  border: 1px solid #DDE1E6;\n\n  &:hover {\n    background: #DDE1E6;\n  }\n\n  .loader {\n    display: flex;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectionDisplay": `SelectionDisplay__selectionDisplay-YfZ`,
	"loader": `SelectionDisplay__loader-FjE`
};
export default ___CSS_LOADER_EXPORT___;
