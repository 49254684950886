import { FC } from "react";

export const ChatIcon: FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			d="M6 7C6 6.72386 6.22386 6.5 6.5 6.5H9.5C9.77614 6.5 10 6.72386 10 7C10 7.27614 9.77614 7.5 9.5 7.5H6.5C6.22386 7.5 6 7.27614 6 7ZM6.5 8.5C6.22386 8.5 6 8.72386 6 9C6 9.27614 6.22386 9.5 6.5 9.5H8.5C8.77614 9.5 9 9.27614 9 9C9 8.72386 8.77614 8.5 8.5 8.5H6.5ZM2.00001 8C2.00001 4.68629 4.68631 2 8.00001 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8.00001 14C6.90639 14 5.87989 13.707 4.99618 13.195L2.65813 13.9743C2.48217 14.033 2.2882 13.9894 2.15422 13.8612C2.02025 13.7329 1.9683 13.541 2.01925 13.3626L2.73086 10.872C2.2648 10.0186 2.00001 9.03957 2.00001 8ZM8.00001 3C5.23859 3 3.00001 5.23858 3.00001 8C3.00001 8.93308 3.25513 9.80506 3.69913 10.5517C3.76952 10.67 3.78797 10.8122 3.75014 10.9446L3.24119 12.7259L4.89938 12.1732C5.04199 12.1256 5.19843 12.1448 5.32536 12.2253C6.09854 12.7159 7.01533 13 8.00001 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8.00001 3Z"
			fill="#697077"
		/>
	</svg>
);
