// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RelaunchButton__restartCheck-DWo{margin-top:28px;margin-bottom:28px;max-width:91px;color:#0072c3 !important}.RelaunchButton__restartCheck-DWo .RelaunchButton__relaunch-oaJ{width:28px;color:#0072c3}.RelaunchButton__restartCheck-DWo .RelaunchButton__relaunch-oaJ>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/RelaunchButton/relaunchButton.module.scss"],"names":[],"mappings":"AAAA,kCACC,eAAA,CACA,kBAAA,CACA,cAAA,CACA,wBAAA,CAEA,gEACC,UAAA,CAEA,aAAA,CAEA,oEACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".restartCheck {\n\tmargin-top: 28px;\n\tmargin-bottom: 28px;\n\tmax-width: 91px;\n\tcolor: #0072c3 !important;\n\n\t.relaunch {\n\t\twidth: 28px;\n\n\t\tcolor: #0072c3;\n\n\t\t& > svg {\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tline-height: initial;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restartCheck": `RelaunchButton__restartCheck-DWo`,
	"relaunch": `RelaunchButton__relaunch-oaJ`
};
export default ___CSS_LOADER_EXPORT___;
