import { Modal } from "pay-kit";
import CurrencyForm, { FormStateType } from "../CurrencyForm";
import { CreateNewCurrencyType } from "api/currenciesGroup";
import createNewCurrencyHook from "./createNewCurrencyHook"
import styles from "./CreateNewModal.module.scss";

type CreateNewModalType = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
};

const CreateNewModal: React.FC<CreateNewModalType> = ({ isOpen, onClose, onSuccess }) => {
	const createNewCurrencyAPI = createNewCurrencyHook({ onSuccess })

	const submitHandler = (formData: FormStateType) => {
		createNewCurrencyAPI.create(formData as CreateNewCurrencyType)
	}

	return (
		<Modal isOpen={isOpen} title="Создание валюты" onClose={onClose} className={styles.modalContent}>
			<CurrencyForm
				onSubmit={submitHandler}
				initialState={{ symbol_position: 1, is_crypto: false }}
				isLoading={createNewCurrencyAPI.isLoading}
			/>
		</Modal>
	);
};

export default CreateNewModal;
