// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaveChangesDialog__saveChangesDialog-mbk{width:100%}.SaveChangesDialog__saveChangesDialog-mbk .SaveChangesDialog__desc-jcn{font-size:14px}.SaveChangesDialog__saveChangesDialog-mbk .SaveChangesDialog__actions-Rzm{display:flex;gap:16px;justify-content:end;margin-top:32px}.SaveChangesDialog__saveChangesDialog-mbk .SaveChangesDialog__actions-Rzm .SaveChangesDialog__cancel-hL7{color:var(--blue-gray-70, #697077);border-radius:4px;border:1px solid #c5cbd1;background:#eaecf0}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/SaveChangesDialog/saveChangesDialog.module.scss"],"names":[],"mappings":"AAAA,0CACE,UAAA,CAEA,uEACE,cAAA,CAGF,0EACE,YAAA,CACA,QAAA,CACA,mBAAA,CACA,eAAA,CAEA,yGACE,kCAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":[".saveChangesDialog {\n  width: 100%;\n\n  .desc {\n    font-size: 14px;\n  }\n\n  .actions {\n    display: flex;\n    gap: 16px;\n    justify-content: end;\n    margin-top: 32px;\n\n    .cancel {\n      color: var(--blue-gray-70, #697077);\n      border-radius: 4px;\n      border: 1px solid #C5CBD1;\n      background: #EAECF0;\n    }\n\n    .save {}\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveChangesDialog": `SaveChangesDialog__saveChangesDialog-mbk`,
	"desc": `SaveChangesDialog__desc-jcn`,
	"actions": `SaveChangesDialog__actions-Rzm`,
	"cancel": `SaveChangesDialog__cancel-hL7`
};
export default ___CSS_LOADER_EXPORT___;
