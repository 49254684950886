import RoutedTabs from "components/RoutedTabs";
import { Panel } from "pay-kit";
import { Outlet } from "react-router-dom";

const HiddenSettingsPage = () => {
	return (
		<Panel title="Скрытые настройки">
			<RoutedTabs
				tabs={[
					{
						label: "Настройки",
						value: "/hidden_settings",
					},
					{
						label: "Поля",
						value: "/hidden_settings/fields",
					},
				]}
			/>
			<Outlet />
		</Panel>
    );
};

export default HiddenSettingsPage;