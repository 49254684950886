// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeLog__logsFiltersWrapper-aUM{margin-top:4px}.ChangeLog__logsFiltersWrapper-aUM .ChangeLog__logsField-q80{min-width:175px}.ChangeLog__changeLogsTableWrapper-ZAV{margin-top:38px;margin-bottom:38px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_{margin-top:26px;margin-bottom:26px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(1){width:140px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(2){width:121px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(3){width:120px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(4){width:200px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(5){width:120px}`, "",{"version":3,"sources":["webpack://./src/modules/ChangeLog/changeLog.module.scss"],"names":[],"mappings":"AAAA,mCACC,cAAA,CAEA,6DACC,eAAA,CAIF,uCACC,eAAA,CACA,kBAAA,CAEA,uEACC,eAAA,CACA,kBAAA,CAEA,wFACC,WAAA,CAGD,wFACC,WAAA,CAGD,wFACC,WAAA,CAGD,wFACC,WAAA,CAGD,wFACC,WAAA","sourcesContent":[".logsFiltersWrapper {\n\tmargin-top: 4px;\n\n\t.logsField {\n\t\tmin-width: 175px;\n\t}\n}\n\n.changeLogsTableWrapper {\n\tmargin-top: 38px;\n\tmargin-bottom: 38px;\n\n\t.changeLogsTable {\n\t\tmargin-top: 26px;\n\t\tmargin-bottom: 26px;\n\n\t\tcol:nth-child(1) {\n\t\t\twidth: 140px;\n\t\t}\n\n\t\tcol:nth-child(2) {\n\t\t\twidth: 121px;\n\t\t}\n\n\t\tcol:nth-child(3) {\n\t\t\twidth: 120px;\n\t\t}\n\n\t\tcol:nth-child(4) {\n\t\t\twidth: 200px;\n\t\t}\n\n\t\tcol:nth-child(5) {\n\t\t\twidth: 120px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logsFiltersWrapper": `ChangeLog__logsFiltersWrapper-aUM`,
	"logsField": `ChangeLog__logsField-q80`,
	"changeLogsTableWrapper": `ChangeLog__changeLogsTableWrapper-ZAV`,
	"changeLogsTable": `ChangeLog__changeLogsTable-ax_`
};
export default ___CSS_LOADER_EXPORT___;
