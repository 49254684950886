import SaveChangesDialog from "modules/DefaultSettings/SaveChangesDialog";
import { Modal } from "pay-kit";
import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";

export const ActionsContext = createContext<ActionsContextType>({} as ActionsContextType);
const ActionsProvider: FC<ActionsProviderType> = ({ children }) => {
	const [isSaved, setIsSaved] = useState(true);
	const [confirmed, setIsConfirmed] = useState(false);
	const [openSaveChangesDialog, setOpenSaveChangesDialog] = useState(false);
	const [isCanceled, setIsCanceled] = useState<boolean>(false);
	const [disabled, setDisabled] = useState(false);

	const onOpen = useCallback(() => setOpenSaveChangesDialog((open) => !open), []);
	const onCheck = useCallback((isCompleted: boolean) => setIsSaved(isCompleted), []);
	const onConfirm = useCallback((isConfirmed: boolean) => setIsConfirmed(isConfirmed), []);
	const onDisable = useCallback((disabled: boolean) => setDisabled(disabled), []);

	const defaultValue = useMemo(
		() => ({
			open: onOpen,
			check: onCheck,
			confirm: onConfirm,
			disable: onDisable,
			disabled,
			isSaved,
			confirmed,
			isCanceled,
			openSaveChangesDialog,
		}),
		[confirmed, isSaved, onCheck, onConfirm, onOpen, isCanceled, disabled, openSaveChangesDialog]
	);

	useEffect(() => {
		return () => {
			setIsCanceled(false);
		};
	}, [isCanceled]);

	const onSave = () => {
		setIsConfirmed(true);
		setOpenSaveChangesDialog(false);
	};

	const onCancel = () => {
		setOpenSaveChangesDialog(false);
		setIsCanceled(true);
		setIsSaved(true);
		setIsConfirmed(false);
	};

	return (
		<ActionsContext.Provider value={defaultValue}>
			<Modal
				title="Сохранение изменений"
				onClose={() => setOpenSaveChangesDialog(false)}
				isOpen={openSaveChangesDialog}
			>
				<SaveChangesDialog save={onSave} cancel={onCancel} disabled={disabled} />
			</Modal>
			{children}
		</ActionsContext.Provider>
	);
};

export default ActionsProvider;

export const useActionsContext = () => useContext(ActionsContext);

export type ActionsProviderType = { readonly children: ReactElement };

export type ActionsContextType = {
	readonly open: () => void;
	readonly check: (isCompleted: boolean) => void;
	readonly confirm: (isConfirmed: boolean) => void;
	readonly disable: (disabled: boolean) => void;
	readonly disabled: boolean;
	readonly isSaved: boolean;
	readonly confirmed: boolean;
	readonly isCanceled: boolean;
	readonly openSaveChangesDialog: boolean;
};
