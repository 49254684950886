import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";

export type DeleteFieldModal = {
    id: number | null,
    name: string,
    onDelete: (id: number) => Promise<any>,
    closeModal: () => void,
}

const DeleteFieldModal = ({ id, onDelete, name, closeModal }: DeleteFieldModal) => {
    if (!id) return null;
    return (
        <DeleteModal
            closeModal={closeModal}
            actions={{
                onDelete: () => onDelete(id),
            }} 
            text={
                <div>
                    Вы действительно хотите удалить поле <b>{name}</b>?
                </div>
            }
        />
    )
}

export default DeleteFieldModal;