import { Button, Modal, Table, Tooltip } from "@paykassma/pay-kit";
import { Case } from "api/blackListGroup";
import Filter from "components/Filter";
import CreateIncidentModal from "./components/CreateIncidentModal";
import styles from "./Index.module.scss";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { Link } from "react-router-dom";
import { useIncidents } from "./useIncidents";
import { trimText } from "./utils";
import ReloadButton from "components/ReloadButton";
import { useContext } from "react";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

const Incidents = () => {
	const {
		load,
		paginationInfo,
		currentPage,
		limit,
		setCurrentPage,
		setLimit,
		cases,
		isAddCaseModalOpen,
		isCasesLoading,
		filter,
		setFilter,
		toggleAddCaseModal,
	} = useIncidents();

	const { hasRole } = useContext(AuthContext);

	return (
		<div className={styles.index}>
			{hasRole(Roles.MANAGE_BLACK_LIST) && (
				<Button classname={styles.addBtn} variant="primary" onClick={toggleAddCaseModal(true)}>
					<PlusIcon />
					<span
						style={{
							marginLeft: "8px",
						}}
					>
						Создать инцидент
					</span>
				</Button>
			)}

			<Filter
				fields={[
					{
						name: `id`,
						placeholder: "ID Инцидента",
						component: "textInput",
						className: styles.idField,
					},
					{
						name: `details`,
						placeholder: "Комментарий",
						component: "textInput",
						className: styles.detailsField,
					},
					{
						name: `created_at`,
						component: "dateRange",
						fromPlaceholder: "Создан от",
						toPlaceholder: "Создан до",
						dateTimeFormat: "YYYY-MM-DD",
						className: styles.createdField,
					},
				]}
				onApply={setFilter}
				values={filter}
			/>

			<div className={styles.content}>
				<Modal isOpen={isAddCaseModalOpen} title="Создание инцидента" onClose={toggleAddCaseModal(false)}>
					<CreateIncidentModal onClose={toggleAddCaseModal(false)} />
				</Modal>

				<Table
					data={cases}
					columns={[
						{
							title: "ID инцидента",
							dataIndex: "id",
							key: "id",
							render: (form: Case) => <Link to={`/black-list/incidents/${form.id}/requisites`}>{form.id}</Link>,
						},
						{
							title: "Комментарий",
							dataIndex: "details",
							key: "details",
							render(form: Case) {
								return (
									<div className={styles.commentCol}>
										<Tooltip tip={form.details}>
											<span>{trimText(form.details, 40)}</span>
										</Tooltip>
									</div>
								);
							},
						},
						{
							title: "Создан",
							dataIndex: "created_at",
							key: "created_at",
							render: (form: Case) => form.created_at.date,
						},
						{
							title: "Реквизитов",
							dataIndex: "credentials_count",
							key: "credentials_count",
						},
						{
							title: "Транзакций",
							dataIndex: "transactions_count",
							key: "transactions_count",
						},
					]}
					paginator={
						paginationInfo
							? {
									currentPage: paginationInfo?.offset / limit + 1,
									setCurrentPage: (page: any) => setCurrentPage(page as number),
									limit,
									setLimit: (newLimit) => {
										setCurrentPage(1);
										setLimit(newLimit);
									},
									lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
									className: styles.paginator,
									bottomPaginatorProps: {
										className: styles.bottomPaginator,
									},
									prefixElement: (
										<ReloadButton
											data-test-id=""
											isLoading={false}
											onClick={() => {
												load({
													id: filter.id,
													details: filter.details,
													created_from: filter.created_at?.from,
													created_to: filter.created_at?.to,
													limit,
													offset: (currentPage - 1) * limit,
												});
											}}
										/>
									),
							  }
							: undefined
					}
					isLoading={isCasesLoading}
					rowKey="id"
				/>
			</div>
		</div>
	);
};

export default Incidents;
