import { FC } from "react";
import { blackListGroup, Credential } from "api/blackListGroup";
import styles from "./DeleteRequisitesModal.module.scss";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";

type Props = {
	onClose: () => void;

	requisiteData?: Credential;
	onSuccess?: () => void;
};

export const DeleteRequisiteModal: FC<Props> = (props) => {
	const { requisiteData: { id, type, value, created_at } = {}, onClose, onSuccess } = props;

	const onDelete = async () => {
		try {
			if (!id) return;
			const resp = await blackListGroup.deleteCredential(`${id}`);

			if (resp.status === "success") {
				onSuccess?.();
				onClose();
			}
		} catch (e) {
			console.log(e);
			window.pushAlert({ description: "Не удалось удалить реквизит", type: "error" });
		}
	};

	return (
		<div className={styles.modal}>
			<div>
				<p>
					ID: <b>{id}</b> Тип: <b>{type}</b> Значение: <b>{value}</b>
				</p>
				<p>
					Создан: <b>{created_at?.date}</b>
				</p>
			</div>

			<ConfirmationModalButtons onCancel={onClose} onConfirm={onDelete} isDeleteBtn deleteText="Удалить" />
		</div>
	);
};
