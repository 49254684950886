import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, FC } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export const TanStackQuery: FC<PropsWithChildren> = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		{children}
		{/* <ReactQueryDevtools /> */}
	</QueryClientProvider>
);
