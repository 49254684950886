import countepartyApi, { Counterparty } from "api/counterpartyGroup";

import axios from "axios";
import { useEffect, useState } from "react";

type Args = {
	isLoadOnMount: boolean;
};

export function useCounterparties(args: Args = { isLoadOnMount: false }) {
	const [counterparties, setCounterparties] = useState<Array<Counterparty>>([]);
	const [isCounterpartiesLoading, setIsCounterpartiesLoading] = useState(false);

	const load = async () => {
		try {
			setIsCounterpartiesLoading(false);

			const data = await countepartyApi.loadList();

			setCounterparties(data);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({ description: e.message, type: "error" });
			}
		} finally {
			setIsCounterpartiesLoading(false);
		}
	};

	useEffect(() => {
		if (args.isLoadOnMount) {
			load();
		}
	}, [args.isLoadOnMount]);

	const getOptions = () => counterparties.map((c) => ({ label: c.identifier, value: c.id }));

	return {
		counterparties,
		isCounterpartiesLoading,
		load,
		getOptions,
	};
}
