// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Details__comment-gWR{display:flex;align-items:center;margin-bottom:8px}.Details__details-wmx{margin-left:10px;margin-right:10px;overflow:hidden;color:var(--Blue_gray-90, #21272a);text-overflow:ellipsis;font-family:Inter;font-size:14px;font-style:normal;font-weight:400;line-height:20px}.Details__btn-RUV{align-self:flex-start;border:none;background-color:rgba(0,0,0,0);width:16px;height:16px;padding:0;margin:0;cursor:pointer}.Details__dateWrapper-b51{display:flex;align-items:center;gap:24px}.Details__date-d3r{gap:10px;display:flex;align-items:center;font-size:14px}.Details__date-d3r span{font-weight:500}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/components/Details/styles.module.scss"],"names":[],"mappings":"AAGA,sBACC,YAAA,CACA,kBAAA,CACC,iBAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CACD,eAAA,CACA,kCAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGD,kBACE,qBAAA,CACA,WAAA,CACA,8BAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,QAAA,CACA,cAAA,CAGF,0BACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,mBACE,QAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,wBACE,eAAA","sourcesContent":[".details {\n}\n\n.comment {\n\tdisplay: flex;\n\talign-items: center;\n  margin-bottom: 8px;\n}\n\n.details {\n  margin-left: 10px;\n  margin-right: 10px;\n\toverflow: hidden;\n\tcolor: var(--Blue_gray-90, #21272a);\n\ttext-overflow: ellipsis;\n\tfont-family: Inter;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 20px;\n}\n\n.btn {\n  align-self: flex-start;\n  border: none;\n  background-color: transparent;\n  width: 16px;\n  height: 16px;\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n}\n\n.dateWrapper {\n  display: flex;\n  align-items: center;\n  gap: 24px;\n}\n\n.date {\n  gap: 10px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n\n  span {\n    font-weight: 500;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `Details__comment-gWR`,
	"details": `Details__details-wmx`,
	"btn": `Details__btn-RUV`,
	"dateWrapper": `Details__dateWrapper-b51`,
	"date": `Details__date-d3r`
};
export default ___CSS_LOADER_EXPORT___;
