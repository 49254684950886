import { PayKitForm, ScrollBox } from "@paykassma/pay-kit";
import { blackListGroup } from "api/blackListGroup";
import { isRequired } from "utils/validate";

import styles from "./styles.modules.scss";
import { FC, useState } from "react";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { useCredentialTypes } from "modules/BlackList/hooks/useCredentialTypes";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { hasErrors } from "modules/BlackList/utils";

type Props = {
	caseId?: string;
	onSuccess?: () => void;
	onClose: () => void;
};

export const CreateIncidentModal: FC<Props> = ({ caseId, onSuccess, onClose }) => {
	const { getOptions } = useCredentialTypes({ isLoadOnMount: true });
	const f = useFormDataAPI({});
	const [isLoading, setIsLoading] = useState(false);
	const [count, setCount] = useState(1);

	const onSubmit = async (form: { requisites: Array<{ type: string; value: string }> }) => {
		if (!form?.requisites || !form?.requisites?.length) {
			return;
		}

		const promises = form.requisites.map((requisite) => {
			return blackListGroup.updateCredentialPost({
				fraud_cases_id: caseId,
				...requisite,
			});
		});

		setIsLoading(true);
		Promise.all(promises)
			.then(() => onSuccess && onSuccess())
			.finally(() => setIsLoading(false));
	};

	return (
		<div className={styles.modal}>
			<ScrollBox scrollDirection={"vertical"} className={styles.scroller}>
				<PayKitForm.Builder
					formDataAPI={f}
					schema={[
						{
							label: "Реквизиты",
							name: "requisites",
							type: "Repeat",
							render: (children, props) => (
								<div>
									<button
										onClick={() => {
											props.insertItem({ type: "", value: "", clientId: count }, "insertAfter");
											setCount((count) => count + 1);
										}}
										className={styles.addBtn}
									>
										+ Добавить еще
									</button>
									{children}
								</div>
							),
							elements: [
								{
									type: "Group",
									render: (children, props) => {
										return (
											<>
												<div className={styles.requisiteHeader}>
													<p className={styles.requisiteTitle}>
														Реквизит{" "}
														{props.formDataAPI.formState.requisites.findIndex(
															({ clientId }) => clientId === props.value.clientId
														) + 1}
													</p>
													<button
														onClick={() => {
															props.removeCurrentItem();
														}}
														className={styles.btn}
													>
														<TrashIcon />
													</button>
												</div>
												{children}
											</>
										);
									},
									elements: [
										{
											type: "Group",
											render: (children) => <div>{children}</div>,
											elements: [
												{
													name: "type",
													label: "Тип",
													isRequired: true,
													validation: [isRequired],
													options: getOptions(),
													type: "Select",
												},
												{
													name: "value",
													label: "Значение",
													isRequired: true,
													validation: [isRequired],
													type: "TextInput",
												},
											],
										},
									],
								},
							],
						},
					]}
				></PayKitForm.Builder>
			</ScrollBox>
			<ConfirmationModalButtons
				onCancel={onClose}
				isConfirmDisabled={hasErrors(f.errors)}
				onConfirm={() => onSubmit(f.formState)}
				isLoading={isLoading}
			/>
		</div>
	);
};
