import { PayKitForm } from "@paykassma/pay-kit";
import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { blackListGroup } from "api/blackListGroup";
import axios from "axios";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import { hasErrors } from "modules/BlackList/utils";
import { FC, useState } from "react";
import { isRequired } from "utils/validate";

type Props = {
	id?: string;
	comment?: string;
	onSuccess?: () => void;
	onClose: () => void;
};

export const EditCommentModal: FC<Props> = ({ id, comment, onSuccess, onClose }) => {
	const formAPI = useFormDataAPI({
		initialState: {
			details: comment,
		},
	});

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (form: any) => {
		if (id) {
			setIsLoading(true);
			try {
				const resp = await blackListGroup.updateCase({ id, details: form.details });
				if (resp.status === "success") {
					onSuccess && onSuccess();
				}
			} catch (e) {
				if (axios.isAxiosError(e)) {
					window.pushAlert({
						description: e.message,
						type: "error",
					});
				}
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<div>
			<PayKitForm.Builder
				formDataAPI={formAPI}
				schema={[
					{
						name: "details",
						label: "Комментарий",
						type: "Textarea",
						validation: [isRequired],
						isRequired: true,
						placeholder: "Опишите детали инцидента",
					},
				]}
			></PayKitForm.Builder>
			<ConfirmationModalButtons
				isLoading={isLoading}
				isConfirmDisabled={hasErrors(formAPI.errors)}
				onConfirm={() => onSubmit(formAPI.formState)}
				onCancel={onClose}
			/>
		</div>
	);
};
