import { fetchApi } from "api/fetch";

export type ChangeLogParamsType = {
	readonly offset: number;
	readonly limit: number;
	readonly username?: string;
	readonly object_type?: ("wallet" | "proxy")[];
	readonly object_id?: string;
	readonly action_type?: ("insert" | "update" | "delete")[];
	readonly dateFrom?: string;
	readonly dateTo?: string;
};

export type ObjectType = {
	name: string;
	id: string;
}

export type ActionType = ObjectType

const changeLogGroup = {
	getChangeLogList: (params: ChangeLogParamsType) => fetchApi<GetChangeLogListType>({ url: `change-log`, params, method: "GET" }),
	getChangeLogObjectTypes: () => fetchApi<Array<ObjectType>>({ url: `change-log/object-types`, method: "GET" }),
	getChangeLogActionTypes: () => fetchApi<Array<ActionType>>({ url: `change-log/action-types`, method: "GET" }),
};

export default changeLogGroup;

export type ChangeLogActionType = {
	readonly id: string;
	readonly name: string;
};

export type GetChangeLogActionListType = {
	readonly data: readonly ChangeLogActionType[];
};

export type ChangeLogObject = {
	readonly id: string;
	readonly name: string;
};

export type GetChangeLogObjectListType = {
	readonly data: readonly ChangeLogObject[];
};

export type ChangeLogUsersDataType = {
	readonly name: string;
};

export type GetChangeLogUsersType = {
	readonly code: number;
	readonly data: readonly ChangeLogUsersDataType[];
	readonly message?: string;
	readonly paginate: {
		readonly offset: number;
		readonly total: number;
		readonly limit: number;
	};
};

export type ChangeLogType = {
	readonly uuid: string
	readonly counterparty_identifier: string
	readonly counterparty_uuids: string[];
	readonly action_type: string;
	readonly object_type: string;
	readonly object_id: string;
	readonly username: string;
	readonly old_value: string[];
	readonly new_value: string[];
	readonly created_at: string;
};

export type GetChangeLogListType = {
	status: "success";
	message: string;
	code: number;
	data: ChangeLogType[];
	paginate: PaginationInfoType;
};

type PaginationInfoType = {
	readonly offset: number;
	readonly total: number;
	readonly limit: number;
};
