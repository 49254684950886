import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const LinkToIncidents: FC = () => {
	return (
		<div className={styles.wrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
				<path
					d="M9.53033 4.21967C9.82322 4.51256 9.82322 4.98744 9.53033 5.28033L6.81066 8L9.53033 10.7197C9.82322 11.0126 9.82322 11.4874 9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L5.21967 8.53033C4.92678 8.23744 4.92678 7.76256 5.21967 7.46967L8.46967 4.21967C8.76256 3.92678 9.23744 3.92678 9.53033 4.21967Z"
					fill="#697077"
				/>
			</svg>
			<Link className={styles.link} to="/black-list/incidents">
				К списку инцидентов
			</Link>
		</div>
	);
};
