// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hidden__wrapper-qpS{display:flex;flex-direction:column;flex-grow:1}.Hidden__valueLabel-YNF{font-size:14px;color:#697077}.Hidden__valueHeading-Jsm{display:flex;justify-content:space-between}.Hidden__valueField-sOC{display:flex;gap:16px}.Hidden__valueField-sOC div{flex-grow:1}.Hidden__addIcon-EbP{color:#697077 !important}.Hidden__errorWrapper-Xwx{margin-bottom:16px}.Hidden__btnWrapper-e3u{margin-top:16px;display:flex;justify-content:center}div[class*=TextInput__message]{margin-top:4px}div[class*=Select__errorMessage]{font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/modules/Hidden/hiddenStyles.module.scss"],"names":[],"mappings":"AAAA,qBACI,YAAA,CACA,qBAAA,CACA,WAAA,CAGJ,wBACI,cAAA,CACA,aAAA,CAGJ,0BACI,YAAA,CACA,6BAAA,CAGJ,wBACI,YAAA,CACA,QAAA,CAEA,4BACI,WAAA,CAIR,qBACI,wBAAA,CAGJ,0BACI,kBAAA,CAGJ,wBACI,eAAA,CACA,YAAA,CACA,sBAAA,CAGJ,+BACI,cAAA,CAGJ,iCACI,kBAAA","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.valueLabel {\n    font-size: 14px;\n    color: #697077;\n}\n\n.valueHeading {\n    display: flex;\n    justify-content: space-between;\n}\n\n.valueField {\n    display: flex;\n    gap: 16px;\n\n    div {\n        flex-grow: 1;\n    }\n}\n\n.addIcon {\n    color: #697077 !important;\n}\n\n.errorWrapper {\n    margin-bottom: 16px;\n}\n\n.btnWrapper {\n    margin-top: 16px;\n    display: flex;\n    justify-content: center;\n}\n\ndiv[class*='TextInput__message']{\n    margin-top: 4px;\n}\n\ndiv[class*='Select__errorMessage'] {\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Hidden__wrapper-qpS`,
	"valueLabel": `Hidden__valueLabel-YNF`,
	"valueHeading": `Hidden__valueHeading-Jsm`,
	"valueField": `Hidden__valueField-sOC`,
	"addIcon": `Hidden__addIcon-EbP`,
	"errorWrapper": `Hidden__errorWrapper-Xwx`,
	"btnWrapper": `Hidden__btnWrapper-e3u`
};
export default ___CSS_LOADER_EXPORT___;
