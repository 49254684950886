import {StandardResponse} from "api/types";
import { LoginData } from "contexts/AuthContext/utils/types";
import {
	getRefreshToken,
	getToken,
	removeRefreshToken,
	TOKEN_UPDATE_EVENT_NAME,
	tokenUpdater
} from "helpers/tokenHelper";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import API from "../../api";
import { Roles } from "./utils/enums";

type IAuthContext = {
	readonly token: string;
	readonly isAuth: boolean;
	readonly login: (login: string, password: string) => Promise<StandardResponse<LoginData>>;
	readonly hasRole: (role: Roles, options?: hasRoleOptionsType) => boolean;
	readonly user: any;
	readonly logout: () => Promise<any>;
};

export type ColumnUpdateData = {
	readonly column: string;
	readonly name: string;
	readonly show: boolean;
};

type hasRoleOptionsType = {
	readonly redirectToErrorPage: boolean,
}

const initialState: IAuthContext = {
	hasRole: () => true,
	user: {},
	logout: () => new Promise(() => {}),
	token: "",
	isAuth: false,
	login: () => {
		return new Promise(() => {});
	},
};

const LOCAL_STORAGE_USER_NAME_TOKEN = "SA_USER_NAME"

const authRoutes = ["/login"];

export const AuthContext = React.createContext<IAuthContext>(initialState);
AuthContext.displayName = "AuthContext";

export default function AuthContextProvider(props: any) {
	const [token, setLocalToken] = useState<string>(getToken());
	const [roles, setRoles] = useState<readonly Roles[]>([]);
	const [rolesIsLoading, setRolesIsLoading] = useState<boolean>(true);
	const [user, setUser] = useState<String>(localStorage.getItem(LOCAL_STORAGE_USER_NAME_TOKEN) || "");
	const isAuth = useMemo(() => !!token, [token]);

	document.addEventListener(TOKEN_UPDATE_EVENT_NAME, (e: any) => {
		const { detail: newToken } = e;
		setLocalToken(() => newToken);
	});

	const navigate = useNavigate();

	function login(login: string, password: string) {
		setRolesIsLoading(true);
		return API.auth.login(login, password).then((res) => {
			if (res.status === "ok") {
				const { token: newToken, refresh_token, user } = res;
				tokenUpdater(newToken, refresh_token);

				const { roles, name } = user;
				setRoles(roles);
				localStorage.setItem(LOCAL_STORAGE_USER_NAME_TOKEN, name);
				setUser(name);

				return res;
			}

			return res;
		}).finally(() => setRolesIsLoading(false));
	}

	const { pathname } = useLocation();

	useEffect(() => {
		if (!isAuth && !authRoutes.includes(pathname)) {
			navigate("/login", { replace: true });
		}
	}, [isAuth]);

	async function logout() {
		const refreshToken = getRefreshToken();
		removeRefreshToken();
		if (refreshToken) {
			return API.auth.logout(refreshToken).finally(() => {
				tokenUpdater("", "");
				setUser({});
				setRoles([]);
				setRolesIsLoading(false);
			});
		}
	}

	function loadPermissions() {
		API.auth.me().then((resp) => {
			if (resp.status === "ok") {
				const {
					roles, name
				} = resp.user;
				setUser(name);
				setRoles(roles);
			}
		}).finally(() => setRolesIsLoading(false));
	}

	useEffect(() => {
		if (token) {
			loadPermissions();
		}
	}, [token]);

	const hasRole = (role: Roles, options?: hasRoleOptionsType): boolean => {
		const hasAccess = roles.includes(role);

		if (!hasAccess && options?.redirectToErrorPage && !rolesIsLoading) {
			navigate("/error?type=noAccess");
		}

		return hasAccess;
	};

	const value = {
		hasRole,
		user,
		logout,
		token,
		isAuth,
		login,
	};

	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);