// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Options__scrollBox-qyL{max-height:358px;scrollbar-width:thin}.Options__scrollBox-qyL .Options__option-CIb{padding:6px 16px;cursor:pointer;display:flex;align-items:center;gap:8px}.Options__scrollBox-qyL .Options__option-CIb:hover{background:#f1faff}.Options__scrollBox-qyL .Options__option-CIb .Options__wrapper-DEJ{display:flex;align-items:center;flex-direction:row;gap:10px}.Options__scrollBox-qyL .Options__option-CIb .Options__psLogo-dr5.Options__psLogo-dr5{width:40px;background-position:center}.Options__scrollBox-qyL .Options__selectedPS-mVC{background:#f1faff}.Options__scrollBox-qyL.Options__scrollBox-qyL::-webkit-scrollbar{width:8px}.Options__searchBar-mfn{display:none}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/components/Options/options.module.scss"],"names":[],"mappings":"AAAA,wBACE,gBAAA,CACA,oBAAA,CAEA,6CACE,gBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,mDACE,kBAAA,CAGF,mEACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sFACE,UAAA,CACA,0BAAA,CAIJ,iDACE,kBAAA,CAKF,kEACE,SAAA,CAIJ,wBACE,YAAA","sourcesContent":[".scrollBox {\n  max-height: 358px;\n  scrollbar-width: thin;\n\n  .option {\n    padding: 6px 16px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n\n    &:hover {\n      background: #F1FAFF;\n    }\n\n    .wrapper {\n      display: flex;\n      align-items: center;\n      flex-direction: row;\n      gap: 10px;\n    }\n\n    .psLogo.psLogo {\n      width: 40px;\n      background-position: center;\n    }\n  }\n\n  .selectedPS {\n    background: #F1FAFF;\n  }\n}\n\n.scrollBox.scrollBox {\n  &::-webkit-scrollbar {\n    width: 8px;\n  }\n}\n\n.searchBar {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollBox": `Options__scrollBox-qyL`,
	"option": `Options__option-CIb`,
	"wrapper": `Options__wrapper-DEJ`,
	"psLogo": `Options__psLogo-dr5`,
	"selectedPS": `Options__selectedPS-mVC`,
	"searchBar": `Options__searchBar-mfn`
};
export default ___CSS_LOADER_EXPORT___;
