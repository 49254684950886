// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemoveConfirmationModal__text-uIo b{display:block;font-weight:600;margin-bottom:24px}.RemoveConfirmationModal__text-uIo b strong{color:#dc3545}`, "",{"version":3,"sources":["webpack://./src/modules/Currencies/ActionsProvider/components/RemoveConfirmationModal/RemoveConfirmationModal.module.scss"],"names":[],"mappings":"AACI,qCACI,aAAA,CACA,eAAA,CACA,kBAAA,CAEA,4CACI,aAAA","sourcesContent":[".text {\n    b {\n        display: block;\n        font-weight: 600;\n        margin-bottom: 24px;\n\n        strong {\n            color: #DC3545;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `RemoveConfirmationModal__text-uIo`
};
export default ___CSS_LOADER_EXPORT___;
