import API from "api";
import changeLogGroup, { GetChangeLogListType, ObjectType } from "api/changeLogGroup";
import axios from "axios";
import { useState } from "react";

const useChangeLogList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const [list, setList] = useState<GetChangeLogListType["data"]>([]);
	const [objectTypes, setObjectTypes] = useState<Array<ObjectType>>([]);
	const [isObjectTypesLoading, setIsObjectTypesLoading] = useState(false);
	const [actionTypes, setActionTypes] = useState<Array<ObjectType>>([]);
	const [isActionTypesLoading, setIsActionTypesLoading] = useState(false);

	const [paginationInfo, setPaginationInfo] = useState<GetChangeLogListType["paginate"]>(
		{} as GetChangeLogListType["paginate"]
	);

	const loadObjectTypes = async () => {
		try {
			setIsObjectTypesLoading(true);
			const res = await changeLogGroup.getChangeLogObjectTypes();
			if (res.status === "success") {
				setObjectTypes(res.data);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({
					type: "error",
					title: e.message,
					description: e.response?.data.error_message,
				});
			}
		} finally {
			setIsObjectTypesLoading(false);
		}
	};

	const loadActionTypes = async () => {
		try {
			setIsActionTypesLoading(true);
			const res = await changeLogGroup.getChangeLogActionTypes();
			if (res.status === "success") {
				setActionTypes(res.data);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({
					type: "error",
					title: e.message,
					description: e.response?.data.error_message,
				});
			}
		} finally {
			setIsActionTypesLoading(false);
		}
	};

	const load = (params: any) => {
		setIsLoading(true);
		setError(null);
		setList([]);

		const { date, ...restFilter } = params.filter;

		API.changeLog
			.getChangeLogList({
				limit: params.limit,
				offset: params.offset,
				...restFilter,
				dateFrom: date?.from,
				dateTo: date?.to,
			})
			.then((res) => {
				if (res.status === "success") {
					const { data, paginate } = res;
					setPaginationInfo(paginate);
					setList(data as any);
				} else {
					throw new Error("Unexpected response in useChangeLogList");
				}
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	};

	return {
		load,
		list,
		paginationInfo,
		isLoading,
		error,

		objectTypes,
		isObjectTypesLoading,
		loadObjectTypes,

		actionTypes,
		isActionTypesLoading,
		loadActionTypes,
	};
};

export default useChangeLogList;
