import { AlertsContext } from "contexts/AlertsProvider";
import { useContext, useEffect } from "react";
import { errorsMap } from "utils/enums";

export const PAYKASSMA_ERROR_MSG = `PAYKASSMA_ERROR_MSG`;


const ErrorPage = () => {
	const errorMessage = localStorage.getItem(PAYKASSMA_ERROR_MSG) || ``;
	const urlParams = new URLSearchParams(window.location.search);
	const type = urlParams.get('type') as keyof typeof errorsMap;

	//@ts-ignore
	const {
		alerts: { push },
	} = useContext(AlertsContext);

	useEffect(() => {
		if (push && errorMessage) {
			push({
				title: errorsMap.any,
				type: "error",
				description: errorMessage,
				pinned: true,
			});
			localStorage.removeItem(PAYKASSMA_ERROR_MSG);
		}
	}, [push, errorMessage]);

	const message = type ? errorsMap[type] || errorsMap.any : errorsMap.any;

	return (
		<div>{typeof message === "string" ? message : errorsMap.any}</div>
	);
};

export default ErrorPage;
