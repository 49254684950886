import { AuthContext } from "contexts/AuthContext/AuthContext";
import DefaultSettingsPage from "layout/pages/DefaultSettingsPage";
import ErrorPage from "layout/pages/ErrorPage";
import LogoutPage from "layout/pages/LogoutPage";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./layout/pages/LoginPage";
import Main from "./layout/pages/Main";
import Currencies from "./layout/pages/Currencies";
import { counterpartyPathes } from "./modules/Counterparty/routes";
import { IndexPage } from "./modules/Counterparty/pages/IndexPage";
import HiddenPage from "./layout/pages/Hidden";
import HiddenSettingsPage from "./layout/pages/Hidden/HiddenSettingsPage";
import HiddenFieldsPage from "./layout/pages/Hidden/HiddenFieldsPage";
import AppSettingsProfile from "layout/pages/AppSettingsProfile";
import ChangeLogPage from "layout/pages/ChangeLogPage";
import WalletsPage from "layout/pages/WalletsPage";
import ArchivedWalletsPage from "layout/pages/WalletsPage/ArchivedWalletsPage";
import DisabledWalletsPage from "layout/pages/WalletsPage/DisabledWalletsPage";
import WorkingWalletsPage from "layout/pages/WalletsPage/WorkingWalletsPage";
import LogsListPage from "layout/pages/LogsListPage";
import ProxiesPage from "layout/pages/ProxiesPage";
import BlackList from "layout/pages/BlackList";
import Incidents from "layout/pages/BlackList/Incidents";
import Incident from "layout/pages/BlackList/Incident";
import Requisites from "layout/pages/BlackList/Requisites";
import RequisitesForIncident from "layout/pages/BlackList/Incident/Requisites";
import TransactionsForIncident from "layout/pages/BlackList/Incident/Transactions";
import Transactions from "layout/pages/BlackList/Transactions";

const ProtectedRoutes = () => {
	const authContext = useContext(AuthContext);
	if (!authContext.isAuth) {
		return <Navigate to="login" replace />;
	}

	return <Main />;
};

export default function Router() {
	return (
		<Routes>
			<Route path="login" element={<LoginPage />} />
			<Route path="/" element={<ProtectedRoutes />}>
				<Route path="error" element={<ErrorPage />} />
				<Route path="logout" element={<LogoutPage />} />
				<Route path="" element={<IndexPage />} />
				<Route path="default-settings" element={<DefaultSettingsPage />} />
				<Route path="currencies" element={<Currencies />} />
				<Route path="black-list" element={<BlackList />}>
					<Route path="incidents" index element={<Incidents />} />
					{/* <Route path="incidents/:id" element={<Incident />} /> */}
					<Route path="incidents/:id/requisites"  element={<RequisitesForIncident />} />
					<Route path="incidents/:id/transactions" element={<TransactionsForIncident />} />
					<Route path="requisites" element={<Requisites />} />
					<Route path="transactions" element={<Transactions />} />
				</Route>
				<Route path="settings" element={<AppSettingsProfile />} />
				<Route path="change-logs" element={<ChangeLogPage />} />
				<Route path={counterpartyPathes.index} element={<IndexPage />} />
				<Route path="hidden-settings" element={<HiddenPage />}>
					<Route index element={<HiddenSettingsPage />} />
					<Route path="fields" element={<HiddenFieldsPage />} />
				</Route>
				<Route path="settings" element={<AppSettingsProfile />} />
				<Route path="hidden_settings" element={<HiddenPage />}>
					<Route index element={<HiddenSettingsPage />} />
					<Route path="fields" element={<HiddenFieldsPage />} />
				</Route>
				{/* <Route path="wallets" element={<WalletsPage />}>
					<Route index element={<WorkingWalletsPage />} />
					<Route path="disabled" element={<DisabledWalletsPage />} />
					<Route path="archived" element={<ArchivedWalletsPage />} />
				</Route> */}
				{/* <Route path="proxies" element={<ProxiesPage />} /> */}
				{/* <Route path="logs" element={<LogsListPage />} /> */}
			</Route>
		</Routes>
	);
}
