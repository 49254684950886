// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrencyForm__actions-KNF{display:flex;justify-content:end}`, "",{"version":3,"sources":["webpack://./src/modules/Currencies/ActionsProvider/components/CurrencyForm/CurrencyForm.module.scss"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,mBAAA","sourcesContent":[".actions {\n    display: flex;\n    justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `CurrencyForm__actions-KNF`
};
export default ___CSS_LOADER_EXPORT___;
