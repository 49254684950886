import { blackListGroup, CredentialParams, Pagination } from "api/blackListGroup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCredentialTypes } from "../hooks/useCredentialTypes";

export function useRequisites() {
	const { getOptions, isCredentialTypesLoading } = useCredentialTypes({ isLoadOnMount: true });

	const [filter, setFilter] = useState<
		Omit<CredentialParams, "created_from" | "created_to"> & {
			created_at?: {
				from: string;
				to: string;
			};
		}
	>({});

	const [paginationInfo, setPaginationInfo] = useState<Pagination | null>(null);
	const [limit, setLimit] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [credentials, setCredentials] = useState<Array<Credential>>([]);
	const [isCredentialsLoading, setIsCredentialsLoading] = useState(true);

	const [isAddModalOpen, setIsAddModalOpen] = useState(false);

	const toggleAddModal = (flag: boolean) => () => setIsAddModalOpen(flag);

	const onAddSuccess = () => {
		toggleAddModal(false)();
		load({
			id: filter.id,
			fraud_cases_id: filter.fraud_cases_id,
			created_from: filter.created_at?.from,
			created_to: filter.created_at?.to,
			type: filter.type,
			value: filter.value,
		});
	};

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const toggleDeleteModal = (flag: boolean) => () => setIsDeleteModalOpen(flag);

	const onDeleteSuccess = () => {
		toggleDeleteModal(false)();
		load({
			id: filter.id,
			fraud_cases_id: filter.fraud_cases_id,
			created_from: filter.created_at?.from,
			created_to: filter.created_at?.to,
			type: filter.type,
			value: filter.value,
		});
	};

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const toggleEditModal = (flag: boolean) => () => setIsEditModalOpen(flag);

	const onEditSuccess = () => {
		toggleEditModal(false)();
		load({
			id: filter.id,
			fraud_cases_id: filter.fraud_cases_id,
			created_from: filter.created_at?.from,
			created_to: filter.created_at?.to,
			type: filter.type,
			value: filter.value,
		});
	};

	const load = async (params: CredentialParams) => {
		setIsCredentialsLoading(true);
		try {
			const resp = await blackListGroup.getCredentialsList(params);

			if (resp.status === "success") {
				setCredentials(resp.data);
				setPaginationInfo(resp.paginate);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({ description: e.message, type: "error" });
			}
		} finally {
			setIsCredentialsLoading(false);
		}
	};

	useEffect(() => {
		load({
			id: filter.id,
			fraud_cases_id: filter.fraud_cases_id,
			created_from: filter.created_at?.from,
			created_to: filter.created_at?.to,
			type: filter.type,
			value: filter.value,
			limit,
			offset: (currentPage - 1) * limit,
		});
	}, [filter, limit, currentPage]);

	return {
		paginationInfo,

		limit,
		setLimit,

		currentPage,
		setCurrentPage,

		credentials,
		isCredentialsLoading,

		credentialTypes: getOptions(),
		isCredentialTypesLoading,

		filter,
		setFilter,

		isAddModalOpen,
		toggleAddModal,
		onAddSuccess,

		isDeleteModalOpen,
		toggleDeleteModal,
		onDeleteSuccess,

		isEditModalOpen,
		toggleEditModal,
		onEditSuccess,
		load,
	};
}
