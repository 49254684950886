import { Modal } from "pay-kit";
import CurrencyForm from "../CurrencyForm";
import { CurrenciesListItem, CreateNewCurrencyType } from "api/currenciesGroup";
import styles from "./EditModal.module.scss";
import useEditCurrencyHook from './useEditCurrencyHook';

type EditModalType = {
	isOpen: boolean;
	onClose: () => void;
	currencyData?: CurrenciesListItem;
	onSuccess: () => void;
};

const omitInitialState = (currencyData?: CurrenciesListItem) => {
	if (currencyData === undefined) return {};

	const { code, symbol, name, is_crypto, symbol_position } = currencyData;
	return { code, symbol, name, is_crypto, symbol_position };
};

const EditModal: React.FC<EditModalType> = ({ isOpen, onClose, currencyData, onSuccess }) => {
	const editCurrencyAPI = useEditCurrencyHook({ onSuccess });

	return (
		<Modal isOpen={isOpen} title="Редактирование валюты" onClose={onClose} className={styles.modalContent}>
			<CurrencyForm
				onSubmit={(formData) => editCurrencyAPI.edit(formData as CreateNewCurrencyType)}
				initialState={omitInitialState(currencyData)}
				isLoading={editCurrencyAPI.isLoading}
				editMode
			/>
		</Modal>
	);
};

export default EditModal;
