import { Button } from "@paykassma/pay-kit/lib";
import { FC } from "react";

import styles from "./saveChangesDialog.module.scss";

const SaveChangesDialog: FC<SaveChangesDialogType> = ({ save, cancel, disabled }) => {
	return (
		<div className={styles.saveChangesDialog}>
			<div className={styles.desc}>Вы хотите сохранить изменения?</div>
			<div className={styles.actions}>
				<Button data-test-id="h-5yOz1BdDN2_6mgxLklA" classname={styles.cancel} onClick={cancel}>
					Отмена
				</Button>
				<Button data-test-id="dXp2eZxmyBMDkKcV57FVR" classname={styles.save} onClick={save}>
					Сохранить
				</Button>
			</div>
		</div>
	);
};

export default SaveChangesDialog;

type SaveChangesDialogType = {
	readonly save: () => void;
	readonly cancel: () => void;
	readonly disabled: boolean;
};
