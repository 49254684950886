import { fetchApi, fetchOldApi } from "./fetch";

const currenciesGroup = {
	// getCurrenciesList: (code: string) => fetchApi<CurrenciesListItem[]>("currencies/list", undefined, "GET"), // code filter doesn't work
	// createNew: (data: CreateNewCurrencyType) => fetchApi(`currencies/`, data, "POST"),
	// update: (data: CreateNewCurrencyType) => fetchApi(`currencies/`, data, "PUT"),
	// updateRates: (codes: string[]) => fetchApi("currencies/init-rate-update/", { codes }, "GET"),
	// remove: (codes: string[]) => fetchApi(`currencies/`, { codes }, "DELETE"),
	getCurrenciesList: (code: string) => fetchApi<CurrenciesListItem[]>({ url: "currencies/list", method: "GET"}), // code filter doesn't work
	createNew: (data: CreateNewCurrencyType) => fetchApi({url: `currencies/`, config: { data }, method: "POST"}),
	update: (data: CreateNewCurrencyType) => fetchApi({url: `currencies/`, config: { data }, method: "PUT"}),
	updateRates: (codes: string[]) => fetchApi({ url: "currencies/init-rate-update/", params: { codes }, method: "GET"}),
	remove: (codes: string[]) => fetchApi({ url: `currencies/`, config: { data: { codes } }, method: "DELETE"}),
};

export default currenciesGroup;

export enum SymbolPositionENUM {
	LEFT = 1,
	RIGHT = 2,
}

export type CurrenciesListItem = {
	code: string;
	symbol: string;
	name: string;
	is_crypto: boolean;
	symbol_position: SymbolPositionENUM;
	rate: {
		rate: number;
		source: number;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	updated_at: string;
};

export type CreateNewCurrencyType = {
	code: string;
	symbol: string;
	name: string;
	is_crypto: boolean;
	symbol_position: SymbolPositionENUM;
};
