import { Counterparty } from "api/counterpartyGroup";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useEffect, useState } from "react";

export function useIndexPage() {
	const [openedModal, setOpenedModal] = useState<null | "delete" | "edit">(null);

	const [selectedElement, setSelectedElement] = useState<Counterparty | null>(null);
	const { hasRole } = useAuthContext();

	hasRole(Roles.COUNTERPARTY_LIST, { redirectToErrorPage: true });

	const handleCloseModal = () => {
		setOpenedModal(null);
		setSelectedElement(null);
	};

	useEffect(() => {
		document.title = "Контрагенты";
	}, []);

	return {
		openedModal,
		setOpenedModal,

		selectedElement,
		setSelectedElement,

		handleCloseModal,
	};
}
