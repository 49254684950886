import ConfirmationModal from "../ConfirmationModal";
import useUpdateCurrencyRateHook from './useUpdateCurrencyRateHook';

type UpdateRatesModalPropsType = {
    isOpen: boolean;
    onClose: () => void;
	codes?: string[];
	onSuccess: () => void;
}

const UpdateRatesModal: React.FC<UpdateRatesModalPropsType> = ({ isOpen, onClose, codes, onSuccess }) => {
	const manyCurrencies = codes && codes.length > 1;
	const updateCurrencyRateAPI = useUpdateCurrencyRateHook({ onSuccess });

	return (
		<ConfirmationModal
			isOpen={isOpen}
			onConfirm={codes ? () => updateCurrencyRateAPI.update(codes) : () => {}}
			onClose={onClose}
			title={manyCurrencies ? "Запустить обновление курсов валют" : "Обновить курс валюты"}
			text={manyCurrencies ? "Вы уверены, что хотите запустить обновление курсов всех валют?" : `Вы уверены, что хотите запустить обновление курса ${codes?.join('')}?`}
			confirmText="Запустить"
			isLoading={updateCurrencyRateAPI.isLoading}
		/>
	);
};

export default UpdateRatesModal;
