import { blackListGroup, Case } from "api/blackListGroup";
import { FC, useState } from "react";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import { ChatIcon } from "components/Icons/ChatIcon";
import { SyncIcon } from "components/Icons/SyncIcon";
import styles from "./styles.module.scss";
import { Modal } from "@paykassma/pay-kit";
import EditCommentModal from "modules/BlackList/Incidents/components/EditCommentModal";
import { BluePencilIcon } from "components/Icons/BluePencilIcon";

type Props = {
	caseData: Partial<Pick<Case, "details" | "created_at" | "id">>;
	onSuccess?: () => void;
};

export const Details: FC<Props> = ({ caseData, onSuccess }) => {
	const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false);

	return (
		<article>
			<Modal
				isOpen={isEditCommentModalOpen}
				title="Редактирование комментария"
				onClose={() => setIsEditCommentModalOpen(false)}
			>
				<EditCommentModal
					id={caseData?.id}
					comment={caseData?.details}
					onSuccess={() => {
						setIsEditCommentModalOpen(false);
						onSuccess && onSuccess();
					}}
					onClose={() => setIsEditCommentModalOpen(false)}
				/>
			</Modal>

			<div className={styles.comment}>
				<ChatIcon />
				<p className={styles.details}>{caseData?.details}</p>

				<button onClick={() => setIsEditCommentModalOpen(true)} className={styles.btn} type="button">
					<BluePencilIcon />
				</button>
			</div>
			<div className={styles.dateWrapper}>
				<p className={styles.date}>
					<CalendarIcon /> <span>Создан:</span> {caseData?.created_at?.date}
				</p>
				<p className={styles.date}>
					<SyncIcon />  <span>Обновлен: </span> {caseData?.updated_at?.date}
				</p>
			</div>
		</article>
	);
};
