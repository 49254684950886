import { useState } from "react";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import { useSearchParams } from "react-router-dom";
import { CounterpartyGroup } from "api/counterpartyGroup";

type UseFilters = {
	/**
	 * Значение из строки запроса
	 * Example: searchParams.get("group")
	 *  */
	selectedFilter: string | null;
	/**
	 * Хэндлер для установки выбранной группы в селекте
	 * @param val unknown as CounterpartyGroup
	 */
	handleGroupChange: (val: unknown) => void;
	/**
	 * Хэндлер для фетчинга данных по группе
	 */
	handleFetchByFilters: () => void;
	/**
	 * Хэндлер сброса фильтров
	 */
	handleResetFilters: () => void;
};

export function useFilters(): UseFilters {
	const [searchParams, setSearchParams] = useSearchParams();
	/**
	 * Состояние определяет, нужно ли фетчить данные по группе
	 */
	const [isSearchedByFilters, setIsSearchedByFilters] = useState(false);

	const { fetchCounterparties } = useCounterparty({
		group: searchParams.get("group"),
		isNeedToFetchByGroup: isSearchedByFilters,
	});

	const selectedFilter = searchParams.get("group");

	const handleResetFilters = () => {
		setIsSearchedByFilters(false);
		setSearchParams();
	};

	const handleGroupChange = (val: unknown) => {
		if (val === "0") {
			handleResetFilters();
			return;
		}
 
		setSearchParams({ group: val as CounterpartyGroup });
		setIsSearchedByFilters(false);
	};

	const handleFetchByFilters = () => {
		setIsSearchedByFilters(true);
		fetchCounterparties();
	};



	return {
		selectedFilter,
		handleGroupChange,
		handleFetchByFilters,
		handleResetFilters,
	};
}
