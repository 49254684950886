import { Tabs } from "pay-kit";
import { useLocation, useNavigate } from "react-router-dom";

export type RoutedTabsPropType = {
	readonly tabs: readonly {
		readonly value: string,
		readonly label: string,
	}[]

	readonly className?: string
}

const RoutedTabs: React.FC<RoutedTabsPropType> = ({ tabs, className }) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Tabs
			tabs={tabs}
			value={location.pathname}
			onChange={(path) => navigate(path as string)}
			data-test-id="tabs"
			className={className}
		/>
	);
};

export default RoutedTabs;
