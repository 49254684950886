import { blackListGroup, CredentialType } from "api/blackListGroup";
import axios from "axios";
import { useEffect, useState } from "react";

type Args = {
	isLoadOnMount: boolean;
};

export function useCredentialTypes(args: Args = { isLoadOnMount: false }) {
	const [credentialsTypes, setCredentialsTypes] = useState<Array<CredentialType>>([]);
	const [isCredentialTypesLoading, setIsCredentialTypesLoading] = useState(false);

	const load = async () => {
		try {
			setIsCredentialTypesLoading(false);

			const res = await blackListGroup.getCredentialTypes();

			if (res.status === "success") {
				setCredentialsTypes(res.data);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({ description: e.message, type: "error" });
			}
		} finally {
			setIsCredentialTypesLoading(false);
		}
	};

	useEffect(() => {
		if (args.isLoadOnMount) {
			load();
		}
	}, [args.isLoadOnMount]);

	const getOptions = () => {
		return credentialsTypes.map((el) => ({ label: el.type, value: el.type }));
	};

	return {
		credentialsTypes,
		isCredentialTypesLoading,
		load,
		getOptions,
	};
}
