// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Incidents__index-vJk{margin-top:24px}.Incidents__commentCol-klq{max-width:216px}.Incidents__addBtn-sQP{margin-bottom:24px}.Incidents__idField-sR6{width:217px}.Incidents__detailsField-wIL{width:217px}.Incidents__createdField-M1i{width:230px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Incidents/Index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,2BACE,eAAA,CAGF,uBACE,kBAAA,CAGF,wBACE,WAAA,CAGF,6BACE,WAAA,CAGF,6BACE,WAAA","sourcesContent":[".index {\n  margin-top: 24px;\n}\n\n.commentCol {\n  max-width: 216px\n}\n\n.addBtn {\n  margin-bottom: 24px;\n}\n\n.idField {\n  width: 217px;\n}\n\n.detailsField {\n  width: 217px;\n}\n\n.createdField {\n  width: 230px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Incidents__index-vJk`,
	"commentCol": `Incidents__commentCol-klq`,
	"addBtn": `Incidents__addBtn-sQP`,
	"idField": `Incidents__idField-sR6`,
	"detailsField": `Incidents__detailsField-wIL`,
	"createdField": `Incidents__createdField-M1i`
};
export default ___CSS_LOADER_EXPORT___;
