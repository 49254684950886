import { fetchOldApi } from "./fetch";

const settingsGroup = {
	setTimeZone: (data: TimezoneType[`params`][`timezone`]) =>
		fetchOldApi({ url: `settings`, params: { timezone: data }, method: `PUT` }),
	getTimezone: () => fetchOldApi<TimezoneType>({ url: `settings` }),
	getTimezoneList: () => fetchOldApi<AvailableTimezonesType>({ url: `timezones/list` }),
};

export type AvailableTimezonesType = {
	readonly available_timezones: readonly { readonly name: string; readonly value: string }[];
};

export type TimezoneType = {
	readonly params: {
		timezone: string;
	};
};

export default settingsGroup;
