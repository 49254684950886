import API from "api";
import { CreateHintsParams, CreateHintsType } from "api/defaultSettingsGroup";
import { StandardResponse } from "api/types";
import { useCallback, useState } from "react";

const useCreateHints = () => {
	const [data, setData] = useState<CreateHintsType | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const create: Create = useCallback((params, onSuccess, onError) => {
		setIsLoading(true);
		setError(null);

		API.defaultSetting
			.createHints(params)
			.then((res) => {
				if (res.status === "success") {
					onSuccess && onSuccess(res);
					setData(res.data);
					window.pushAlert({
						title: "Успех",
						type: "success",
						description: `Перевод успешно создан`,
					});
				} else if (res.error_message) {
					onError && onError();
				} else {
					throw new Error("Unexpected response in useCreateHints");
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		create,
		data,
		isLoading,
		error,
	};
};

export default useCreateHints;

type Create = (
	params: CreateHintsParams,
	onSuccess?: (res: StandardResponse<CreateHintsType>) => void,
	onError?: () => void
) => void;
