import { GetHintType, GetLanguagesType, TranslationType } from "api/defaultSettingsGroup";
import { useActionsContext } from "modules/DefaultSettings/ActionsProvider";
import useCreateHints from "modules/DefaultSettings/hooks/useCreateHints";
import useGetHints from "modules/DefaultSettings/hooks/useGetHints";
import useRemoveHints from "modules/DefaultSettings/hooks/useRemoveHints";
import useUpdateHints from "modules/DefaultSettings/hooks/useUpdateHints";
import Loader from "modules/DefaultSettings/LoadingHandler";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import AddTranslation from "modules/DefaultSettings/TranslationsList/components/AddTranslation";
import Remove from "modules/DefaultSettings/TranslationsList/components/Remove";
import { customSelectStyles } from "modules/DefaultSettings/TranslationsList/customSelectStyles";
import {
	arraysAreEqual,
	findHint,
	isEnglishTranslationRequired,
	isLanguageRequired,
	isRequired,
	processTranslationOptions,
} from "modules/DefaultSettings/TranslationsList/helpers";
import { PayKitForm } from "pay-kit";
import { FC, MouseEvent, ReactElement, useEffect, useState } from "react";

import styles from "./translationsList.module.scss";

const TranslationsList: FC<TranslationsListType> = ({ selectedPs, formType, languageList }) => {
	const [isPulsating, setIsPulsating] = useState(false);
	const [englishTranslationExists, setEnglishTranslationExists] = useState(true);
	const createHintsAPI = useCreateHints();
	const updateAPI = useUpdateHints();
	const removeAPI = useRemoveHints();
	const getHintsAPI = useGetHints();
	const [removeID, setRemoveID] = useState<string | undefined>(undefined);
	const [currentState, setCurrentState] = useState<readonly TranslationType[]>([]);
	const { check, confirmed, isSaved, confirm, isCanceled, disable, openSaveChangesDialog, disabled } =
		useActionsContext();

	useEffect(() => {
		getHintsAPI.load();
	}, [getHintsAPI.load]);

	const isLoading = !selectedPs || !getHintsAPI.list || !languageList;

	const hint = findHint(getHintsAPI.list, isLoading, formType, selectedPs);

	const translations = hint?.translations || [];
	const handleOnStateChange: HandleOnStateChangeType = (prevState, newState) => {
		setCurrentState(newState.translations);
		confirm(false);
		const englishTranslation = newState.translations.find((translation) => translation.language === `en`);
		const invalidTranslation = newState.translations.some((translation) => !translation.text || !translation.language);
		setEnglishTranslationExists(!!englishTranslation);

		if (invalidTranslation) disable(true);
		else disable(false);

		if (!arraysAreEqual(translations, newState.translations)) check(false);
		else check(true);
	};

	const formDataAPI = PayKitForm.useFormDataAPI<UseFormDataAPIType>({
		initialState: {
			translations,
		},
		onStateChange: handleOnStateChange,
	});

	useEffect(() => {
		if (isCanceled) {
			formDataAPI.resetState();
			formDataAPI.setValue(`translations`, translations);
		}
	}, [isCanceled]);

	useEffect(() => {
		if (openSaveChangesDialog) formDataAPI.showAllErrors();
	}, [openSaveChangesDialog]);

	useEffect(() => {
		formDataAPI.setValue(`translations`, translations);
	}, [selectedPs, formType, translations.length]);

	const options = languageList?.map((language) => ({ label: language, value: language })) || [];

	const onSave = (formData: { readonly translations: readonly TranslationType[] }) => {
		setIsPulsating(true);
		check(true);
		const onSuccess = () => {
			getHintsAPI.load();
			setIsPulsating(false);
		};

		const onError = () => {
			window.pushAlert({
				title: "Ошибка",
				type: "error",
				description: `При обновлении текстовых подсказок возникла ошибка`,
			});
			setIsPulsating(false);
		};

		if (!removeID) {
			if (hint) {
				updateAPI.update(hint.uuid, { name: hint.name, translations: formData.translations }, onSuccess, onError);
			}
			if (!hint) {
				createHintsAPI.create(
					{
						form_type: formType,
						name: `${selectedPs?.value} (default)`,
						wallet_type: selectedPs?.value,
						translations: formData.translations,
					},
					onSuccess,
					onError
				);
			}
		}

		if (removeID && hint?.uuid)
			removeAPI.remove(hint.uuid, { name: hint.name, translations: formData.translations }, onSuccess);
	};

	useEffect(() => {
		if (confirmed && !disabled && englishTranslationExists) {
			onSave({ translations: currentState });
		}
	}, [confirmed, currentState, isSaved]);

	useEffect(() => {
		if (isCanceled) setEnglishTranslationExists(true);
		return () => {
			setEnglishTranslationExists(true);
		};
	}, [isCanceled]);

	const isDisabled = isPulsating || isLoading;
	const translationsWrapperStyle = isDisabled ? [styles.translations, styles.pulsating].join(" ") : styles.translations;

	const SCHEMA: any = [
		{
			type: "Group",
			render: (children: readonly ReactElement[]) => {
				return <div className={translationsWrapperStyle}>{children}</div>;
			},
			elements: [
				{
					type: "Repeat",
					name: "translations",
					validation: [isLanguageRequired, isEnglishTranslationRequired],
					render: (children: readonly ReactElement[], { insertItem, error }: InsertItemType) => (
						<AddTranslation
							insertItem={insertItem}
							error={error}
							textAreaClassName={styles.textAreaLabel}
							englishTranslationExists={englishTranslationExists}
							isDisabled={isDisabled}
						>
							{children}
						</AddTranslation>
					),
					elements: [
						{
							type: "Group",
							render: (
								children: readonly ReactElement[],
								{ removeCurrentItem }: { readonly removeCurrentItem: RemoveCurrentItemType }
							) => (
								<Remove removeCurrentItem={removeCurrentItem} setRemoveID={() => setRemoveID(hint?.uuid)}>
									{children}
								</Remove>
							),
							elements: [
								{
									name: "language",
									type: "Select",
									placeholder: "",
									className: styles.select,
									styles: customSelectStyles,
									options: processTranslationOptions(options),
									validation: [isRequired],
								},
								{
									name: "text",
									type: "Textarea",
									placeholder: "",
									className: styles.textAreaLabel,
									rows: 1,
									validation: [isRequired],
								},
							],
						},
					],
				},
				{
					type: "Group",
					render: (elements: readonly ReactElement[]) => <div className={styles.submit}>{elements}</div>,
					elements: [
						{
							name: "submit",
							type: "SubmitButton",
							label: "Сохранить",
							disabled: isDisabled || !englishTranslationExists,
							onSubmit: onSave,
						},
					],
				},
			],
		},
	];

	return (
		<div className={styles.translationsWrapper}>
			<div className={styles.header}>Перевод</div>
			<Loader className={styles.loader} color="#C5CBD1" isLoading={isLoading}>
				<PayKitForm.Builder key={`${formType + selectedPs?.value}`} schema={SCHEMA} formDataAPI={formDataAPI} />
			</Loader>
		</div>
	);
};

export default TranslationsList;

type UseFormDataAPIType = { readonly translations: readonly TranslationType[] };

type TranslationsListType = {
	readonly selectedPs: PsOptionType;
	readonly formType: GetHintType["form_type"];
	readonly languageList: readonly GetLanguagesType[] | null;
};

export type InsertItemType = {
	readonly insertItem: (
		defaultParams: { readonly language: undefined; readonly text: undefined },
		insertPos: "insertAfter" | "insertBefore"
	) => undefined;
	readonly error?: string;
	readonly formDataAPI: { readonly showAllErrors: () => void };
};

export type RemoveCurrentItemType = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;

type HandleOnStateChangeType = (
	prevState: { readonly translations: readonly TranslationType[] },
	newState: { readonly translations: readonly TranslationType[] }
) => void;
