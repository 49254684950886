// TODO:
// date filter - date format?
// pagination - total doesn't change
// primary key

import { changeLogColumns } from "modules/ChangeLog/columns";
import useChangeLogList from "modules/ChangeLog/hooks/useChangeLogList";
import { ChangeLogParamsType } from "api/changeLogGroup";
import ReloadButton from "components/ReloadButton";
import Filter from "components/Filter";
import { Table } from "pay-kit";
import { useEffect, useState } from "react";

import styles from "./changeLog.module.scss";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";

const ChangeLog = () => {
	const changeLogAPI = useChangeLogList();
	const [limit, setLimit] = useState<number>(20);
	const [page, setCurrentPage] = useState<number>(1);
	const [filter, setFilter] = useState<ChangeLogParamsType>({} as ChangeLogParamsType);

	const { counterparties, isListLoading, fetchCounterparties } = useCounterparty({
		group: null,
	});

	const loadChangeLogList = () => {
		changeLogAPI.load({ limit, offset: (page - 1) * limit, filter });
	};


	useEffect(loadChangeLogList, [page, limit, filter]);

	useEffect(() => {
		changeLogAPI.loadObjectTypes();
	}, []);

	useEffect(() => {
		changeLogAPI.loadActionTypes();
	}, []);

	return (
		<div>
			<Filter
				classNames={{
					wrapper: styles.logsFiltersWrapper,
				}}
				values={filter}
				fields={[
					{
						name: "username",
						className: styles.logsField,
						placeholder: "Пользователь",
						component: "textInput",
					},
					{
						name: `counterparty_uuid`,
						placeholder: "Контрагент",
						component: "select",
						options: [
							{
								value: ``,
								label: `Все`,
							},
							...(counterparties || []).map((counterparty) => ({
								value: counterparty.id,
								label: counterparty.identifier,
							})),
						],
						className: styles.logsField,
					},
					{
						placeholder: "Период",
						name: "date",
						component: "dateRange",
						fromPlaceholder: "Создан от",
						toPlaceholder: "Создан до",
						dateTimeFormat: "YYYY-MM-DD",
						className: styles.picker,
					},
					{
						name: "object_type",
						className: styles.logsField,
						placeholder: "Тип объекта",
						component: "select",
						isLoading: changeLogAPI.isObjectTypesLoading,
						options: changeLogAPI.objectTypes.map((el) => ({ label: el.name, value: el.id })),
						// options: [
						// 	{ value: "wallet", label: "Кошелек" },
						// 	{ value: "proxy", label: "Прокси" },
						// ],
					},
					{
						name: "object_id",
						className: styles.logsField,
						placeholder: "ID объекта",
						component: "textInput",
					},
					{
						name: "action_type",
						className: styles.logsField,
						placeholder: "Вид действия",
						component: "select",
						options: changeLogAPI.actionTypes.map((el) => ({ label: el.name, value: el.id })),
						isLoading: changeLogAPI.isActionTypesLoading,
						// options: [
						// 	{ value: "insert", label: "Создание" },
						// 	{ value: "update", label: "Изменение" },
						// 	{ value: "delete", label: "Удаление" },
						// ],
					},
	
				]}
				onApply={(values) => {
					setCurrentPage(1);
					setFilter(values);
				}}
			/>

			<div className={styles.changeLogsTableWrapper}>
				<Table
					rowKey="uuid"
					className={styles.changeLogsTable}
					data={changeLogAPI.list}
					columns={changeLogColumns}
					isLoading={changeLogAPI.isLoading}
					stickyHead
					paginator={
						changeLogAPI.paginationInfo
							? {
									currentPage: page || 1,
									lastAvailablePage: changeLogAPI.paginationInfo?.total || 1,
									setCurrentPage: (page: any) => setCurrentPage(page as number),
									limit,
									setLimit,
									prefixElement: (
										<ReloadButton data-test-id="" isLoading={changeLogAPI.isLoading} onClick={loadChangeLogList} />
									),
									className: styles.paginator,
									bottomPaginatorProps: {
										className: styles.bottomPaginator,
									},
							  }
							: undefined
					}
				/>
			</div>
		</div>
	);
};

export default ChangeLog;
