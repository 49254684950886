// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteRequisiteModal__modal-Xzl{min-width:400px;min-height:140px;display:flex;flex-direction:column;justify-content:space-between}.DeleteRequisiteModal__deleteRow-YBj{justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Requisites/components/DeleteRequisiteModal/DeleteRequisitesModal.module.scss"],"names":[],"mappings":"AAAA,iCACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CAGF,qCACE,wBAAA","sourcesContent":[".modal {\n  min-width: 400px;\n  min-height: 140px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.deleteRow {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteRequisiteModal__modal-Xzl`,
	"deleteRow": `DeleteRequisiteModal__deleteRow-YBj`
};
export default ___CSS_LOADER_EXPORT___;
