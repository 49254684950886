// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkToIncidents__wrapper-iop{display:flex;align-items:center}.LinkToIncidents__link-jKw{text-decoration:none;color:var(--Blue_gray-70, #697077);font-family:Inter;font-size:14px;font-style:normal;font-weight:400;line-height:20px}.LinkToIncidents__link-jKw:visited{color:var(--Blue_gray-70, #697077)}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/components/LinkToIncidents/styles.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,kBAAA,CAGF,2BACC,oBAAA,CAEA,kCAAA,CAEA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEC,mCACE,kCAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.link {\n\ttext-decoration: none;\n\n\tcolor: var(--Blue_gray-70, #697077);\n  \n\tfont-family: Inter;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 20px;\n  \n  &:visited {\n    color: var(--Blue_gray-70, #697077);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LinkToIncidents__wrapper-iop`,
	"link": `LinkToIncidents__link-jKw`
};
export default ___CSS_LOADER_EXPORT___;
