// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectBox__searchBarWrapper-SeQ{width:100%;position:absolute;left:0;margin-top:4px;border-radius:4px;background:#fff;box-shadow:0 4px 10px 0 rgba(0,0,0,.15)}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/components/SelectBox/selectBox.module.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CACA,iBAAA,CACA,MAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,uCAAA","sourcesContent":[".searchBarWrapper {\n  width: 100%;\n  position: absolute;\n  left: 0;\n  margin-top: 4px;\n  border-radius: 4px;\n  background: #FFF;\n  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarWrapper": `SelectBox__searchBarWrapper-SeQ`
};
export default ___CSS_LOADER_EXPORT___;
