import { Button } from "@paykassma/pay-kit";
import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
	btnText: string;
	onClick?: () => void;
};

export const EmptyTablePlaceholder: FC<Props> = ({ btnText, onClick }) => {
	return (
		<div className={styles.wrapper}>
			<div>
				<p className={styles.title}>Нет данных</p>
				<Button onClick={onClick} variant="text-primary">
					+ {btnText}
				</Button>
			</div>
		</div>
	);
};
