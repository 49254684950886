// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filters__filters-TQ3{display:flex}.Filters__select-eiK{min-width:180px;width:180px}.Filters__buttons-rv4{display:flex}.Filters__buttons-rv4 button{margin-left:12px}`, "",{"version":3,"sources":["webpack://./src/modules/Counterparty/components/Filters/Filters.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CAGD,qBACC,eAAA,CACA,WAAA,CAGD,sBACC,YAAA,CAEA,6BACC,gBAAA","sourcesContent":[".filters {\n\tdisplay: flex;\n}\n\n.select {\n\tmin-width: 180px;\n\twidth: 180px;\n}\n\n.buttons {\n\tdisplay: flex;\n\n\tbutton {\n\t\tmargin-left: 12px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `Filters__filters-TQ3`,
	"select": `Filters__select-eiK`,
	"buttons": `Filters__buttons-rv4`
};
export default ___CSS_LOADER_EXPORT___;
