import PageTitle from "components/PageTitle";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import ChangeLog from "modules/ChangeLog";
import { useEffect } from "react";

const ChangeLogPage = () => {
	const { hasRole } = useAuthContext();

	useEffect(() => {
		hasRole(Roles.VIEW_CHANGE_LOG, { redirectToErrorPage: true });
	}, [hasRole]);

	return (
		<>
			<PageTitle title="Журнал изменений" />
			<ChangeLog />
		</>
	);
};

export default ChangeLogPage;
