// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteRequisiteModal__modal-frh{display:flex;flex-direction:column;justify-content:space-between;min-width:496px;min-height:150px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Incidents/components/DeleteRequisiteModal/styles.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".modal {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-width: 496px;\n  min-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteRequisiteModal__modal-frh`
};
export default ___CSS_LOADER_EXPORT___;
