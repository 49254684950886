import Loader from "modules/DefaultSettings/LoadingHandler";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import { FC, ReactElement } from "react";

import styles from "./selectionDisplay.module.scss";

const SelectionDisplay: FC<SelectionDisplayType> = ({ children, value }) => {
	return (
		<div className={styles.selectionDisplay}>
			<Loader className={styles.loader} color="#C5CBD1" isLoading={!value}>
				{children}
			</Loader>
		</div>
	);
};

export default SelectionDisplay;

type SelectionDisplayType = {
	readonly children: ReactElement;
	readonly value: PsOptionType | null;
};
