// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar__searchBarWrapper-Lx4{width:100%;border-radius:4px;background:#fff;box-shadow:0 4px 10px 0 rgba(0,0,0,.15)}.SearchBar__searchBarWrapper-Lx4 .SearchBar__searchBar-y43{display:flex;align-items:center;gap:10px;padding:6px 4px 6px 12px;color:#697077;border-radius:4px;border:1px solid var(--blue-gray-40, #C5CBD1);background:var(--white-100, #FFF)}.SearchBar__searchBarWrapper-Lx4 .SearchBar__searchBar-y43 .SearchBar__searchBarInputField-jTA{width:100%;max-width:216px;font-size:14px;border:initial;outline:initial}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/components/SearchBar/searchBar.module.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CAMA,iBAAA,CACA,eAAA,CACA,uCAAA,CAEA,2DACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,wBAAA,CACA,aAAA,CACA,iBAAA,CACA,6CAAA,CACA,iCAAA,CAEA,+FACE,UAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".searchBarWrapper {\n  width: 100%;\n  //position: absolute;\n  //background: #82cfff;\n  //top: 100%;\n  //left: 0;\n  //margin-top: 4px;\n  border-radius: 4px;\n  background: #FFF;\n  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);\n\n  .searchBar {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    //background: #82cfff;\n    padding: 6px 4px 6px 12px;\n    color: #697077;\n    border-radius: 4px;\n    border: 1px solid var(--blue-gray-40, #C5CBD1);\n    background: var(--white-100, #FFF);\n\n    .searchBarInputField {\n      width: 100%;\n      max-width: 216px;\n      font-size: 14px;\n      border: initial;\n      outline: initial;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarWrapper": `SearchBar__searchBarWrapper-Lx4`,
	"searchBar": `SearchBar__searchBar-y43`,
	"searchBarInputField": `SearchBar__searchBarInputField-jTA`
};
export default ___CSS_LOADER_EXPORT___;
