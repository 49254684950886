import { blackListGroup, Case, Credential, CredentialByIdParams } from "api/blackListGroup";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Index.module.scss";
import Filter from "components/Filter";
import { Button, Modal, Table } from "@paykassma/pay-kit";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { Control } from "components/Control/Control";

import AddRequisitesModal from "../../components/AddRequisitesModal";
import DeleteRequisiteModal from "../../components/DeleteRequisiteModal";
import EditCommentModal from "../../components/EditCommentModal";
import EditRequisiteModal from "../../components/EditRequisiteModal";
import { useRequisites } from "./useRequisites";
import ReloadButton from "components/ReloadButton";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import EmptyTablePlaceholder from "modules/BlackList/components/EmptyTablePlaceholder";

export const Requisites = () => {
	const {
		load,
		paginationInfo,
		currentPage,
		limit,
		setCurrentPage,
		setLimit,
		caseId,
		credentialTypes,
		credentials,
		filter,
		isAddModalOpen,
		isCredentialTypesLoading,
		isCredentialsLoading,
		isDeleteModalOpen,
		isEditModalOpen,
		onAddSuccess,
		onDeleteSuccess,
		onEditSuccess,
		setFilter,
		toggleAddModal,
		toggleDeleteModal,
		toggleEditModal,
	} = useRequisites();

	const { hasRole } = useContext(AuthContext);

	const [selectedId, setSelectedId] = useState(undefined);

	return (
		<div className={styles.index}>
			{hasRole(Roles.MANAGE_BLACK_LIST) && (
				<Button variant="primary" classname={styles.addBtn} onClick={toggleAddModal(true)}>
					<PlusIcon />
					<span
						style={{
							marginLeft: "8px",
						}}
					>
						Добавить реквизит
					</span>
				</Button>
			)}

			<Filter
				fields={[
					{
						name: `id`,
						placeholder: "ID",
						component: "textInput",
					},
					{
						name: `type`,
						placeholder: "Тип",
						component: "select",
						options: [{ label: "Все", value: "" }, ...credentialTypes],
						isLoading: isCredentialTypesLoading,
						className: styles.typeField,
					},
					{
						name: `value`,
						placeholder: "Значение",
						component: "textInput",
						className: styles.valueField,
					},
					{
						name: `created_at`,
						component: "dateRange",
						fromPlaceholder: "Создан от",
						toPlaceholder: "Создан до",
						dateTimeFormat: "YYYY-MM-DD",
						className: styles.createdField,
					},
				]}
				onApply={setFilter}
				values={filter}
			/>

			<Modal isOpen={isAddModalOpen} title="Добавление реквизита" onClose={toggleAddModal(false)}>
				<AddRequisitesModal caseId={caseId} onSuccess={onAddSuccess} onClose={toggleAddModal(false)} />
			</Modal>

			<Modal isOpen={isDeleteModalOpen} title="Удалить реквизит?" onClose={toggleDeleteModal(false)}>
				<DeleteRequisiteModal
					onClose={toggleDeleteModal(false)}
					onSuccess={onDeleteSuccess}
					credential={credentials.find((el) => el.id === selectedId)}
				/>
			</Modal>

			<Modal isOpen={isEditModalOpen} title="Редактирование реквизита" onClose={toggleEditModal(false)}>
				<EditRequisiteModal
					id={selectedId}
					fraudCaseId={caseId}
					onClose={toggleEditModal(false)}
					onSuccess={onEditSuccess}
					credential={credentials.find((el) => el.id === selectedId)}
				/>
			</Modal>

			<Table
				data={credentials}
				columns={[
					{
						title: "ID",
						dataIndex: "id",
						key: "id",
					},
					{
						title: "Тип",
						dataIndex: "type",
						key: "type",
					},
					{
						title: "Значение",
						dataIndex: "value",
						key: "value",
					},
					{
						title: "Создан",
						dataIndex: "created_at",
						key: "created_at",
						render: (form: Case) => form.created_at.date,
					},
					{
						title: "Действия",
						dataIndex: "actions",
						key: "actions",
						render: (data) => {
							return (
								<div>
									{hasRole(Roles.MANAGE_BLACK_LIST) && (
										<Control
											actions={{
												onEdit: () => {
													setSelectedId(data.id);
													toggleEditModal(true)();
												},
												onDelete: () => {
													setSelectedId(data.id);
													toggleDeleteModal(true)();
												},
											}}
										/>
									)}
								</div>
							);
						},
					},
				]}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.offset / limit + 1,
								setCurrentPage: (page: any) => setCurrentPage(page as number),
								limit,
								setLimit: (newLimit) => {
									setCurrentPage(1);
									setLimit(newLimit);
								},
								lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
								prefixElement: (
									<ReloadButton
										data-test-id=""
										isLoading={false}
										onClick={() =>
											load(caseId, {
												id: filter.id,
												type: filter.type,
												value: filter.value,
												created_to: filter.created_at?.to,
												created_from: filter.created_at?.from,
												limit,
												offset: (currentPage - 1) * limit,
											})
										}
									/>
								),
						  }
						: undefined
				}
				isLoading={isCredentialsLoading}
				rowKey="id"
				emptyPlaceholder={
					hasRole(Roles.MANAGE_BLACK_LIST) ? (
						<EmptyTablePlaceholder onClick={toggleAddModal(true)} btnText="Добавить реквизит" />
					) : (
						"Нет данных"
					)
				}
			/>
		</div>
	);
};
