import { FC, useEffect, useState } from "react";
import { PayKitForm } from "@paykassma/pay-kit";
import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { blackListGroup, Credential } from "api/blackListGroup";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import { useCredentialTypes } from "modules/BlackList/hooks/useCredentialTypes";
import { isRequired } from "utils/validate";
import { hasErrors } from "modules/BlackList/utils";

type Props = {
	id?: string;
	fraudCaseId?: string;

	credential?: Partial<Credential>;

	onSuccess?: () => void;
	onClose: () => void;
};

export const EditRequisiteModal: FC<Props> = ({ id, fraudCaseId, onClose, onSuccess, credential }) => {
	const { getOptions, isCredentialTypesLoading } = useCredentialTypes({ isLoadOnMount: true });

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		formAPI.setValue("type", getOptions().find((el) => el.value === credential?.type)?.value);
	}, [getOptions().find((el) => el.value === credential?.type)?.value]);

	const formAPI = useFormDataAPI({
		initialState: {
			value: credential?.value,
		},
	});

	const onSubmit = async (form: any) => {
		setIsLoading(true);
		try {
			const resp = await blackListGroup.updateCredential({
				id,
				fraud_cases_id: fraudCaseId,
				...form,
			});

			if (resp.status === "success") {
				onSuccess && onSuccess();
			}
		} catch (e) {
			console.log(e);
			window.pushAlert({ description: "Не удалось сохранить реквизит", type: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<PayKitForm.Builder
				formDataAPI={formAPI}
				schema={[
					{
						name: "type",
						label: "Тип",
						isRequired: true,
						validation: [isRequired],
						options: getOptions(),
						type: "Select",
						isLoading: isCredentialTypesLoading,
					},
					{
						name: "value",
						label: "Значение",
						isRequired: true,
						validation: [isRequired],
						type: "TextInput",
					},
				]}
			></PayKitForm.Builder>
			<ConfirmationModalButtons
				isLoading={isLoading}
				onCancel={onClose}
				isConfirmDisabled={hasErrors(formAPI.errors)}
				onConfirm={() => onSubmit(formAPI.formState)}
			/>
		</div>
	);
};
