// import "assets/styles/main.sass";
import "@paykassma/pay-kit/lib/paykit.css";

import axios from "axios";
import AlertsProvider from "contexts/AlertsProvider";
import AuthContextProvider from "contexts/AuthContext/AuthContext";
import QueryClientProvider from "contexts/TanStackQuery";
import WalletTypesContextProvider from "contexts/WalletTypesContext";
import { Loader } from "pay-kit";
import { FC, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import Router from "./Router";

const App: FC = () => {
	const [state, setState] = useState("init");

	const envUrl = "/config.json";

	useEffect(() => {
		setState("loading");

		axios
			.get(envUrl)
			.then((resp) => {
				window.config = resp.data;
				setState("default");
				return resp;
			})
			.catch((e) => {
				console.error(e);
				setState("error");
			});
	}, []);

	switch (state) {
		case "init":
		case "loading":
			return (
				<>
					loading <Loader />
				</>
			);
		case "error":
			return <>Something went wrong!</>;
		case "default":
			return (
				<AlertsProvider>
					<BrowserRouter>
						<AuthContextProvider>
							<WalletTypesContextProvider>
								<QueryClientProvider>
									<Router />
								</QueryClientProvider>
							</WalletTypesContextProvider>
						</AuthContextProvider>
					</BrowserRouter>
				</AlertsProvider>
			);
		default:
			break;
	}
};

export default App;
