// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddTranslation__addTranslation-hCb .AddTranslation__headers-jS0{display:flex;gap:16px;color:#697077;font-size:14px;font-weight:400;margin-top:16px}.AddTranslation__addTranslation-hCb .AddTranslation__headers-jS0 .AddTranslation__language-YFA{width:100%;max-width:140px}.AddTranslation__addTranslation-hCb .AddTranslation__headers-jS0 .AddTranslation__translation-LpJ{width:100%;max-width:622px}.AddTranslation__errorMessage-wgV{color:red}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/TranslationsList/components/AddTranslation/addTranslation.module.scss"],"names":[],"mappings":"AAEE,iEACE,YAAA,CACA,QAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAEA,+FACE,UAAA,CACA,eAAA,CAGF,kGACE,UAAA,CACA,eAAA,CAKN,kCACE,SAAA","sourcesContent":[".addTranslation {\n\n  .headers {\n    display: flex;\n    gap: 16px;\n    color: #697077;\n    font-size: 14px;\n    font-weight: 400;\n    margin-top: 16px;\n\n    .language {\n      width: 100%;\n      max-width: 140px;\n    }\n\n    .translation {\n      width: 100%;\n      max-width: 622px;\n    }\n  }\n}\n\n.errorMessage {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addTranslation": `AddTranslation__addTranslation-hCb`,
	"headers": `AddTranslation__headers-jS0`,
	"language": `AddTranslation__language-YFA`,
	"translation": `AddTranslation__translation-LpJ`,
	"errorMessage": `AddTranslation__errorMessage-wgV`
};
export default ___CSS_LOADER_EXPORT___;
