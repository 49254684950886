import { useState } from "react";
import API, { CurrenciesListItem } from "api/currenciesGroup";

const useCurrenciesGroup = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<CurrenciesListItem[]>([]);
	const [pagination, setPagination] = useState(undefined);

	const load = (code: CurrenciesListItem["code"]) => {
		setIsLoading(true);

		API.getCurrenciesList(code)
			.then((res) => {
				if (res.status === "success" && res.data) {
					setList(res.data);
					setPagination(res.paginate as any);
					return res;
				}

				throw new Error("Unexpected response in useCurrenciesGroup");
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		load,
		isLoading,
		list,
		pagination,
	};
};

export default useCurrenciesGroup;
