// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateIncidentModal__modal-opg{min-width:496px;min-height:180px;display:flex;flex-direction:column;justify-content:space-between}.CreateIncidentModal__text-wwL{height:120px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Incidents/components/CreateIncidentModal/styles.module.scss"],"names":[],"mappings":"AAAA,gCACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CAGF,+BACE,YAAA","sourcesContent":[".modal {\n  min-width: 496px;\n  min-height: 180px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.text {\n  height: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CreateIncidentModal__modal-opg`,
	"text": `CreateIncidentModal__text-wwL`
};
export default ___CSS_LOADER_EXPORT___;
