import { FC } from "react";
import { Button, Modal } from "@paykassma/pay-kit";
import styles from "./DeleteModal.module.scss";
import { useDeleteCounterparty } from "modules/Counterparty/hooks/deleteCounterparty";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

type Props = {
	onClose: () => void;
	isOpen: boolean;
	/** Идентификатор контрагента */
	idForDelete: string;
};

export const DeleteModal: FC<Props> = ({ onClose, isOpen, idForDelete }) => {
	const client = useQueryClient();
	const [searchParams] = useSearchParams();
	const { deleteCounterparty } = useDeleteCounterparty({
		id: idForDelete,
		onSuccess: () => {
			onClose();
			window.pushAlert({ type: "success", description: "Контрагент удален" });
			client.invalidateQueries({
				queryKey: searchParams.get("group") ? ["counterPartiesByGroup", searchParams.get("group")] : ["counterparties"],
			});
		},
	});

	const handleDelete = () => {
		deleteCounterparty({ id: idForDelete });
	};

	return (
		<Modal title="Удаление контрагента" onClose={onClose} isOpen={isOpen}>
			<ErrorBoundary fallback={<div>Error</div>}>
				<div className={styles.modal}>
					<p className={styles.text}>Вы действительно хотите удалить контрагента?</p>
					<div className={styles.buttons}>
						<Button onClick={onClose}>Отмена</Button>
						<Button variant="delete" onClick={handleDelete}>
							Удалить
						</Button>
					</div>
				</div>
			</ErrorBoundary>
		</Modal>
	);
};
