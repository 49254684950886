import { Modal } from "pay-kit";
import { Button } from "pay-kit";

import styles from "./ConfirmationModal.module.scss";

type ConfirmationModalPropsType = {
	readonly isOpen: boolean;
	readonly text: React.ReactNode;
	readonly title: string;
	readonly confirmText: string;
	readonly onConfirm: () => void;
	readonly onClose: () => void;
	readonly isLoading: boolean;
	readonly confirmButtonVariant?: "primary" | "delete";
};

const ConfirmationModal = ({
	isOpen,
	text,
	title,
	confirmText,
	onConfirm,
	onClose,
	isLoading,
	confirmButtonVariant = "primary",
}: ConfirmationModalPropsType) => {
	return (
		<Modal isOpen={isOpen} title={title} onClose={onClose} className={styles.modalContent}>
			<div className={styles.ConfirmationModal}>
				<div className={styles.text}>{text}</div>
				<div className={styles.actions}>
					<Button data-test-id="cancel" onClick={onClose} variant="secondary">
						Отмена
					</Button>
					<Button
						data-test-id="confirm"
						onClick={onConfirm}
						disabled={isLoading}
						isLoading={isLoading}
						variant={confirmButtonVariant}
					>
						{confirmText}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
