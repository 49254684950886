// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteModal__modal-zaN{display:flex;flex-direction:column;width:100%}.DeleteModal__text-TYz{margin-bottom:32px}.DeleteModal__buttons-Q4q{display:flex;margin-right:0;margin-left:auto}.DeleteModal__buttons-Q4q button{margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/modules/Counterparty/components/DeleteModal/DeleteModal.module.scss"],"names":[],"mappings":"AAAA,wBACC,YAAA,CACA,qBAAA,CACA,UAAA,CAGD,uBACC,kBAAA,CAGD,0BACC,YAAA,CACA,cAAA,CACA,gBAAA,CAEA,iCACC,gBAAA","sourcesContent":[".modal {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n}\n\n.text {\n\tmargin-bottom: 32px;\n}\n\n.buttons {\n\tdisplay: flex;\n\tmargin-right: 0;\n\tmargin-left: auto;\n\n\tbutton {\n\t\tmargin-left: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteModal__modal-zaN`,
	"text": `DeleteModal__text-TYz`,
	"buttons": `DeleteModal__buttons-Q4q`
};
export default ___CSS_LOADER_EXPORT___;
