import { FC, ReactElement } from "react";

import styles from "./selectPanel.module.scss";

const SelectPanel: FC<SelectPanelType> = ({ children }) => {
	return children;
};

export default SelectPanel;

export type SelectPanelType = {
	readonly children: ReactElement;
};
