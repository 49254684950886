import Debugging from "assets/icons/debugging.svg";
// import TransactionsIcon from "components/Icons/TransactionsIcon";
import { ReactNode, useContext } from "react";
import RoleContext from "src/contexts/AuthContext";
import WalletCoins from "assets/icons/walletCoins.svg";

import AdminIco from "./icons/admin.svg";
// import ConstructorIco from "./icons/constructor.svg";
import HomeIco from "./icons/home.svg";
// import PaymentsIco from "./icons/payments.svg";
import CurrenciesIco from "./icons/currencies.svg";
import styles from "./Menu.module.scss";
import MenuPageItem from "./MenuPageItem";
import CounterpartiesIco from "./icons/counterparties.svg";
import ReportIco from "./icons/report.svg";
import BlackListIco from "./icons/black-list.svg";
import { Roles } from "contexts/AuthContext/utils/enums";

export type MenuPageType = {
	readonly link: string;
	readonly title: string;
	readonly icon: ReactNode;
	readonly groups?: ReadonlyArray<MenuGroupType>;
	readonly isVisible?: boolean;
};

export type MenuGroupType = {
	readonly elements: ReadonlyArray<MenuElementType>;
	readonly title?: string;
};

export type MenuElementType = {
	readonly link: string;
	readonly title: string;
	readonly isVisible?: boolean;
};

const Menu = () => {
	const { hasRole, user } = useContext(RoleContext);

	const home: MenuPageType = {
		link: "/",
		title: "Контрагенты",
		icon: <CounterpartiesIco />,
		isVisible: hasRole(Roles.COUNTERPARTY_LIST),
	};

	const paymentSystems = {
		link: "/default-settings",
		title: "Платежные системы",
		icon: <WalletCoins />,
	};

	const currencies: MenuPageType = {
		title: "Валюты",
		link: "/currencies",
		icon: <CurrenciesIco />,
		isVisible: hasRole(Roles.MANAGE_CURRENCIES),
	};

	const blackList: MenuPageType = {
		title: "Черный список",
		link: "/black-list/incidents",
		icon: <BlackListIco />,
		isVisible: hasRole(Roles.VIEW_BLACK_LIST),
		groups: [
			{
				title: "Черный список",
				elements: [
					{
						link: "black-list/incidents",
						title: "Инциденты",
					},
					{
						link: "black-list/requisites",
						title: "Реквизиты",
					},
					{
						link: "black-list/transactions",
						title: "Транзакции",
					},
				],
			},
		],
	};

	const settings: MenuPageType = {
		title: "Настройки",
		link: "/settings",
		icon: <HomeIco />,
	};

	const changeLogs: MenuPageType = {
		title: "Журнал изменений",
		link: "/change-logs",
		icon: <ReportIco />,
		isVisible: hasRole(Roles.VIEW_CHANGE_LOG),
	};

	const administration: MenuPageType = {
		link: "admin",
		title: user,
		icon: <AdminIco />,
		groups: [
			{
				elements: [
					{
						link: "logout",
						title: "Выйти",
					},
				],
			},
		],
	};

	const payments: MenuPageType = {
		link: "/wallets",
		title: "Кошельки",
		icon: <WalletCoins />,
		groups: [
			{
				title: "Кошельки",
				elements: [
					{
						link: "wallets",
						title: "Рабочие",
						isVisible: hasRole(Roles.WALLET_LIST),
					},
					{
						link: "wallets/disabled",
						title: "Отключенные",
						isVisible: hasRole(Roles.WALLETS_DISABLED),
					},
					{
						link: "wallets/archived",
						title: "Архивные",
						isVisible: hasRole(Roles.ARCHIVED_WALLETS_VIEW),
					},
				],
			},
		],
	};

	const debugging: MenuPageType = {
		link: "/debugging",
		title: "Отладка",
		icon: <Debugging />,
		groups: [
			{
				title: "Отладка",
				elements: [
					{
						link: "proxies",
						title: "Прокси",
						isVisible: hasRole(Roles.PROXY_LIST),
					},
					{
						link: "logs",
						title: "Логи кошелька",
						isVisible: hasRole(Roles.LOGS),
					},
				],
			},
		],
	};

	const elements: ReadonlyArray<MenuPageType> = [
		home,
		// payments,
		paymentSystems,
		currencies,
		blackList,
		settings,
		changeLogs,
		// debugging,
		administration,
	];

	const menuItemsGenerator = (elements: ReadonlyArray<MenuPageType>) => {
		elements = elements
			.filter((element) => element.isVisible || element.isVisible === undefined)
			.map((element) => ({
				...element,
				groups: element.groups?.map((groupElems) => ({
					...groupElems,
					elements: groupElems.elements.filter((elemField) => elemField.isVisible || elemField.isVisible === undefined),
				})),
			}));

		elements = elements
			.map((element) => ({
				...element,
				groups: element.groups?.filter((groupElement) => groupElement.elements.length),
			}))
			.filter((element) => element.groups === undefined || element.groups?.length);

		return elements.map((element, i) => <MenuPageItem key={i} item={element} />);
	};

	return <div className={styles.Menu}>{menuItemsGenerator(elements)}</div>;
};

export default Menu;
