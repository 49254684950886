import { FC } from "react";

import styles from "./dateTime.module.scss";

const DateTime: FC<DateTimeType> = ({ dateTime }) => {

	const [date, time] = dateTime.split(" ");
	return (
		<>
			<div>{date}</div>
			<div className={styles.time}>{time}</div>
		</>
	);
};

export default DateTime;

type DateTimeType = {
	readonly dateTime: string
}