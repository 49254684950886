import { NavLink } from "react-router-dom";

import Logo from "./img/logo.svg";
import styles from "./PayLogo.module.scss";

export const PayLogo = () => (
	<NavLink to="/" className={styles.PayLogo}>
		<Logo />
	</NavLink>
);
