import { ValueCast } from "api/hiddenGroup"
import { isJSON, isRequired, isValidNumber } from "utils/validate"
import styles from './hiddenStyles.module.scss';
import { Button, Tooltip } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import QuestionCircle from "./Settings/assets/questionIcon.svg";

export const getInputTypeByCast = (cast: ValueCast | null): string => {
    switch (cast) {
        // case "integer":
        //     return "NumberInput"
        case ValueCast.JSON:
            return "Textarea"
        case ValueCast.BOOLEAN:
            return "Toggler"
        default:
            return "TextInput"
    }
}

export const getValidationByCast = (cast: ValueCast | null) => {
    const validationFuncs = [];
    if (cast !== ValueCast.BOOLEAN) {
        validationFuncs.push(isRequired);
    }
    switch (cast) {
        case ValueCast.JSON:
            validationFuncs.push(isJSON)
            break;
        case ValueCast.INTEGER:
            validationFuncs.push(isValidNumber)
            break
        default:
            break
    }

    return validationFuncs;
}

export const getValueArrayField = (label: any, desc: string = "") => {
    return {
        type: "Repeat",
        render: (children, { insertItem, error }) => (
            <>
                <div className={styles.valueHeading}>
                    <div className={styles.valueLabel}>
                        {desc ? (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}>
                                    <div>
                                        {label}
                                    </div>
                                    <Tooltip tip={desc}>
                                        <QuestionCircle/>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div>
                                    {label}
                                </div>
                            )}
                    </div>
                    <Button
                        data-testid="add-value"
                        variant="text-secondary"
                        onClick={() => insertItem({ value: undefined }, "insertAfter")}
                    >
                        <PlusIcon className={styles.addIcon}/>
                    </Button>
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}
                {children}
            </>
        ),
        elements: [
            {
                type: "Group",
                render: (children, { removeCurrentItem }) => (
                    <div className={styles.valueField}>
                        {children}
                        <Button data-testid="removeValue" variant="text-secondary" onClick={removeCurrentItem}>
                            <TrashIcon />
                        </Button>
                    </div>
                ),
                elements: [
                    {
                        name: "value",
                        type: "TextInput",
                        validation: [isRequired],
                    },
                ],
            },
        ],
    }
}

export const getValueField = (cast: ValueCast | null, label = "Значение") => {
    const commonProps = {
        name: "value",
        label,
        isRequired: cast !== ValueCast.BOOLEAN,
        existsIf: !!cast,
    }

    return cast === ValueCast.ARRAY ? {
        ...commonProps,
        ...getValueArrayField(label),
    } : {
        ...commonProps,
        type: getInputTypeByCast(cast),
        validation: getValidationByCast(cast),
    }
}