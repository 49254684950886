import { blackListGroup, CredentialByIdParams } from "api/blackListGroup";
import axios from "axios";
import { useCredentialTypes } from "modules/BlackList/hooks/useCredentialTypes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function useRequisites() {
	const { id: caseId } = useParams();
	const { getOptions, isCredentialTypesLoading } = useCredentialTypes({ isLoadOnMount: true });

	const [filter, setFilter] = useState<
		Omit<CredentialByIdParams, "created_from" | "created_to"> & {
			created_at?: {
				from: string;
				to: string;
			};
		}
	>({});
	const [paginationInfo, setPaginationInfo] = useState<Pagination | null>(null);
	const [limit, setLimit] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [credentials, setCredentials] = useState<Array<Credential>>([]);
	const [isCredentialsLoading, setIsCredentialsLoading] = useState(false);

	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const toggleAddModal = (flag: boolean) => () => setIsAddModalOpen(flag);
	const onAddSuccess = () => {
		setIsAddModalOpen(false);
		load(caseId, {
			id: filter.id,
			type: filter.type,
			value: filter.value,
			created_to: filter.created_at?.to,
			created_from: filter.created_at?.from,
		});
	};

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const toggleDeleteModal = (flag: boolean) => () => setIsDeleteModalOpen(flag);
	const onDeleteSuccess = () => {
		setIsDeleteModalOpen(false);
		load(caseId, {
			id: filter.id,
			type: filter.type,
			value: filter.value,
			created_to: filter.created_at?.to,
			created_from: filter.created_at?.from,
		});
	};

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const toggleEditModal = (flag: boolean) => () => setIsEditModalOpen(flag);
	const onEditSuccess = () => {
		setIsEditModalOpen(false);
		load(caseId, {
			id: filter.id,
			type: filter.type,
			value: filter.value,
			created_to: filter.created_at?.to,
			created_from: filter.created_at?.from,
		});
	};

	const load = async (caseId, params: CredentialByIdParams) => {
		try {
			setIsCredentialsLoading(true);
			const res = await blackListGroup.getCredentialsByCaseId(caseId, params);
			setCredentials(res.data);
			setPaginationInfo(res.paginate);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({ description: e.message, type: "error" });
			}
		} finally {
			setIsCredentialsLoading(false);
		}
	};

	useEffect(() => {
		if (caseId) {
			load(caseId, {
				id: filter.id,
				type: filter.type,
				value: filter.value,
				created_to: filter.created_at?.to,
				created_from: filter.created_at?.from,
				limit,
				offset: (currentPage - 1) * limit,
			});
		}
	}, [caseId, filter]);

	return {
		paginationInfo,

		limit,
		setLimit,

		currentPage,
		setCurrentPage,

		load,
		caseId,

		filter,
		setFilter,

		credentialTypes: getOptions(),
		isCredentialTypesLoading,

		credentials,
		isCredentialsLoading,

		isAddModalOpen,
		toggleAddModal,
		onAddSuccess,

		isDeleteModalOpen,
		toggleDeleteModal,
		onDeleteSuccess,

		isEditModalOpen,
		toggleEditModal,
		onEditSuccess,
	};
}
