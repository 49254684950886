import { PayKitForm } from "pay-kit";
import { isRequired, minLength, maxLength, isValidCode, isValidSymbol, isValidName } from "./utils";
import styles from "./CurrencyForm.module.scss";

export type FormStateType = {
	code?: string;
	symbol?: string;
	name?: string;
	is_crypto?: boolean;
	symbol_position?: 1 | 2;
};

type CurrencyFormProps = {
	initialState: FormStateType;
	onSubmit: (state: FormStateType) => void;
	isLoading: boolean;
	editMode: boolean;
}

const CurrencyForm: React.FC<CurrencyFormProps> = ({ initialState = {}, onSubmit, isLoading, editMode }) => {
	const schema: any = [
		{
			type: "TextInput",
			label: "Код",
			name: "code",
			validation: [isRequired, isValidCode, minLength(3), maxLength(8)],
			transform: (value: string) => value ? value.toLocaleUpperCase() : "",
			disabled: editMode,
		},
		{
			type: "TextInput",
			label: "Символ",
			name: "symbol",
			validation: [isRequired, isValidSymbol, minLength(1), maxLength(10)],
		},
		{
			type: "Select",
			label: "Позиция символа",
			name: "symbol_position",
			options: [
				{
					label: "Слева",
					value: 1,
				},
				{
					label: "Справа",
					value: 2,
				},
			],
		},
		{
			type: "Toggler",
			label: "Криптовалюта",
			name: "is_crypto",
		},
		{
			type: "TextInput",
			label: "Название",
			name: "name",
			computeValue: ({ is_crypto, code, name }: FormStateType) => !is_crypto ? code : name,
			validation: isValidName,
			transform: (name: string) => name ? name.trim() : "",
			disabled: ({ is_crypto }: FormStateType) => !is_crypto,
		},
		{
			type: "Group",
			render: (children: React.ReactElement) => <div className={styles.actions}>{children}</div>,
			elements: [
				{
					type: "SubmitButton",
					label: editMode ? "Сохранить" : "Создать",
					onSubmit: (formData: FormStateType) => onSubmit(formData),
					isLoading,
				},
			],
		},
	];

	return <PayKitForm.Builder<FormStateType> schema={schema} initialState={initialState} showDebugger={false} />;
};

export default CurrencyForm;
