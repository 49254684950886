import Magnifier from "assets/icons/magnifier.svg";
import { useActionsContext } from "modules/DefaultSettings/ActionsProvider";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import { ChangeEvent, Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from "react";

import styles from "./searchBar.module.scss";
const SearchBar: FC<SearchBarType> = ({ children, setFilteredOptions, options }) => {
	const [value, setValue] = useState(``);
	const { isSaved } = useActionsContext();

	useEffect(() => {
		setFilteredOptions(
			options.filter((option) => `${option.value}`.replace(/_/g, ` `).toLowerCase().includes(value.toLowerCase()))
		);
	}, [value, value.length]);

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => isSaved && setValue(e.target.value);

	return (
		<div data-test-id="Wfapm7re1SQSFehcy2H_F" className={styles.searchBarWrapper}>
			<div className={styles.searchBar}>
				<input className={styles.searchBarInputField} value={value} placeholder="Search" onChange={handleOnChange} />
				<Magnifier />
			</div>
			{children}
		</div>
	);
};
export default SearchBar;

type SearchBarType = {
	readonly children: ReactElement;
	readonly setFilteredOptions: Dispatch<SetStateAction<readonly PsOptionType[]>>;
	readonly options: readonly PsOptionType[];
};
