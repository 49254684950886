import { useMutation } from "@tanstack/react-query";
import API from "api";

type UseDeleteCounterparty = {
	/**
	 * id
	 *
	 * Идентификатор контрагента для удаления
	 */
	id: string;

	/**
	 * onSuccess
	 *
	 * Хэндлер, вызываемый после успешного удаления контрагента
	 */
	onSuccess?: () => void;
};

export function useDeleteCounterparty({ id, onSuccess }: UseDeleteCounterparty) {
	const { mutate: deleteCounterparty} = useMutation({
		mutationFn: ({ id }: { id: string }) => API.counterparty.delete(id),
		mutationKey: ["counterparty-delete", id],
		onSuccess,
	});

	return { deleteCounterparty };
}
