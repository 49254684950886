import { fetchApi, fetchOldApi } from "./fetch";

const hiddenGroup = {
    getCounterparties: () => fetchOldApi<CounterpartiesResponse>({url: `counterparty/for-filter`}),
    getHiddenSettings: (counterpartyId: string) => fetchOldApi<SettingsResponse>({url: `hidden-settings/${counterpartyId}`}),
    setHiddenSettings: (counterpartyId: string, form: any) => fetchOldApi<SettingsResponse>({url: `hidden-settings/${counterpartyId}`, method: "PUT", params: form}),
    getHiddenFields: () => fetchApi<HiddenField[]>({ url: `hidden-settings/settings/fields/`}),
    deleteHiddenField: (id: number) => fetchApi({ url: `hidden-settings/settings/fields/${id}`, method: "DELETE"}),
    createHiddenField: (data: any) => fetchApi({ url: `hidden-settings/settings/fields`, params: data, method: "POST"}),
}

export enum ValueCast {
    STRING = "1",
    INTEGER = "2",
    BOOLEAN = "3",
    JSON = "4",
    ARRAY = "5",
}

export type HiddenField = {
    id: number,
    description?: string,
    name: string,
    value: string,
    value_cast: ValueCast,
}

export type HiddenSetting = {
    description: string,
    field: string,
    value: string,
    value_cast: ValueCast,
}

type SettingsResponse = {
    fields: HiddenSetting[]
}

type CounterpartiesResponse = {
    counterparties: {
        value: string,
        text: string,
    }[]
}

export default hiddenGroup;