import { Button } from "@paykassma/pay-kit"
import { fetchOldApi } from "api/fetch"
import styles from "./Domain.module.scss";

type DomainProps = {
    link: string
    domain: string
    id: string
}

const redirect = (link: string, counterparty_id: string, old: number) => {
    fetchOldApi({
        url: link,
        method: `POST`,
        params: {
            counterparty_id
        }
    }).then((resp) => {
        if (resp.status === "ok") {
            const url = `${window.config.apiUrl}/counterparty/login?referral_id=${resp?.referral_id}&old_front=${old}`
            window.open(url, `_blank`);
        }
    });
}

export const Domain = ({ link, domain, id }: DomainProps) => {
    return (
        <div className={styles.wrapper}>
            <a 
                className={styles.domain}
                href="#" 
                onClick={() => redirect(link, id, 0)}
            >
                {domain}
            </a>
            <Button variant="link-primary" onClick={() => redirect(link, id, 1)}>
                OLD
            </Button>
        </div>
    )
}