import { blackListGroup, Transaction, TransactionParams } from "api/blackListGroup";
import axios from "axios";
import { useCounterparties } from "modules/BlackList/hooks/useCounterparty";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function useTransactions() {
	const { id: caseId } = useParams();
	const { getOptions, isCounterpartiesLoading } = useCounterparties({ isLoadOnMount: true });

	const [filter, setFilter] = useState<
		Omit<TransactionParams, "created_from" | "created_to"> & {
			created_at?: {
				from: string;
				to: string;
			};
		}
	>({});

	const [paginationInfo, setPaginationInfo] = useState<Pagination | null>(null);
	const [limit, setLimit] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [transactions, setTransactions] = useState<Array<Transaction>>([]);

	const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

	const load = async (caseId: string, params: TransactionParams) => {
		setIsTransactionsLoading(true);
		try {
			const resp = await blackListGroup.getTransactionsByCaseId(caseId, params);

			if (resp.status === "success") {
				setTransactions(resp.data);
				setPaginationInfo(resp.paginate);
			}
		} catch (e) {
			if (axios.isAxiosError(e)) {
				window.pushAlert({ description: e.message, type: "error" });
			}
		} finally {
			setIsTransactionsLoading(false);
		}
	};

	useEffect(() => {
		load(caseId, {
			id: filter.id,
			counterparty_uuid: filter.counterparty_uuid,
			fraud_cases_id: filter.fraud_cases_id,
			transactions_id: filter.transactions_id,
			created_from: filter.created_at?.from,
			created_to: filter.created_at?.to,
			limit,
			offset: (currentPage - 1) * limit,
		});
	}, [caseId, filter]);

	return {
		paginationInfo,

		limit,
		setLimit,

		currentPage,
		setCurrentPage,

		load,
		caseId,

		transactions,
		isTransactionsLoading,

		filter,
		setFilter,

		counterparties: getOptions(),
		isCounterpartiesLoading,
	};
}
