import { PayKitForm, Select, Tooltip, TextInput, Button } from "@paykassma/pay-kit";
import API from "api";
import { HiddenField, HiddenSetting, ValueCast } from "api/hiddenGroup";
import { useEffect, useRef, useState } from "react";
import styles from "./settings.module.scss";
import QuestionCircle from "./assets/questionIcon.svg";
import SearchIcon from "./assets/searchIcon.svg";
import { createPortal } from "react-dom";
import { getInputTypeByCast, getValidationByCast, getValueArrayField } from "../utils";
import { deepClone } from "helpers/deepClone";

const CrossIcon = () => {
	return <div className={styles.crossIcon}>X</div>;
};

const HiddenSettings = () => {
	const [counterparties, setCounterparties] = useState<any[]>([]);
	const [chosenCounterparty, setChosen] = useState<string>("");
	const [hiddenFields, setHiddenFields] = useState<HiddenSetting[]>([]);

	const [inputSearch, setInput] = useState<string>("");
	const [search, setSearch] = useState<string>("");

	const searchRef = useRef();

	useEffect(() => {
		API.hidden.getCounterparties().then((resp) => {
			if (resp.status === "ok") {
				setCounterparties(
					resp.counterparties.map((c) => ({
						label: c.text,
						...c,
					}))
				);
			}
		});
	}, []);

	useEffect(() => {
		if (chosenCounterparty) {
			API.hidden.getHiddenSettings(chosenCounterparty).then((resp) => {
				if (resp.status === "ok") {
					setHiddenFields(resp.fields);
				}
			});
		}
	}, [chosenCounterparty]);

	const transformValue = (value: any, cast: ValueCast) => {
		switch (cast) {
			case "json":
				return JSON.stringify(value);
			case "array":
				return [{ value }];
			default:
				return value;
		}
	};

	const initState = hiddenFields.reduce(
		(prev, { field, value, value_cast }) => ({
			[field]: transformValue(value, value_cast),
			...prev,
		}),
		{}
	);

	const onSubmit = (values: any) => {
		const data = deepClone(values);
		const form: any[] = [];
		for (const key of Object.keys(data)) {
			const sourceField = hiddenFields.find((f) => f.field === key);
			const cast = sourceField?.value_cast;
			if (cast === "array") {
				data[key] = data[key][0].value;
				// TODO: look into and provide proper solution
				// data[key] = data[key].map((v: any) => v.value);
				// data[key] = Array.from(data[key], (v: any) => v.value);
			} else if (cast === "json") {
				data[key] = JSON.parse(data[key]);
			}
			form.push({
				...sourceField,
				field: key,
				value: data[key],
			});
		}
		API.hidden.setHiddenSettings(chosenCounterparty, form).then((resp) => {
			if (resp.status === "ok") {
				window.pushAlert({
					type: "success",
					description: "Запись обновлена",
				});
			}
		});
	};

	const foundFields = hiddenFields.filter(({ field }) =>
		search ? field.toLowerCase().includes(search.toLowerCase()) : true
	);

	const schema: any[] = foundFields.map(({ field, description, value_cast }) => {
		const commonProps = {
			name: field,
			label: description ? (
				<div className={styles.tooltip}>
					<div>{field}</div>
					<Tooltip tip={description}>
						<QuestionCircle />
					</Tooltip>
				</div>
			) : (
				field
			),
			existsIf: Boolean(chosenCounterparty),
		};
		const props = {
			...commonProps,
			type: getInputTypeByCast(value_cast),
			validation: getValidationByCast(value_cast),
		};

		if (value_cast === "array") {
			return {
				type: "Group",
				elements: [
					{
						...commonProps,
						...getValueArrayField(field, description),
					},
				],
				render: (children: any) => {
					return <div className={styles.select}>{children}</div>;
				},
			};
		}

		return props;
	});

	const confirmSearchQuery = () => {
		setSearch(inputSearch);
	};

	schema.push({
		type: "Group",
		render: (children: any) =>
			createPortal(
				children,
				//@ts-ignore
				searchRef.current
			),
		elements: [
			{
				type: "SubmitButton",
				label: "Сохранить изменения",
				existsIf: Boolean(chosenCounterparty),
				onSubmit,
			},
		],
	});

	return (
		<>
			<Select
				options={counterparties}
				name="counterparty"
				placeholder="Выберите контрагента"
				//@ts-ignore
				onChange={setChosen}
				value={chosenCounterparty}
				className={styles.filter}
			/>
			<div className={styles.panel}>
				<>
					{chosenCounterparty && (
						<div className={styles.search} ref={searchRef}>
							<div className={styles.searchInput}>
								<TextInput
									placeholder="Поиск"
									name="search"
									value={inputSearch}
									onChange={(e) => setInput(e.target.value)}
								/>
								<button
									type="submit"
									className={styles.searchIcon}
									onClick={() => {
										if (inputSearch) {
											setInput("");
										} else {
											confirmSearchQuery();
										}
									}}
								>
									{inputSearch ? <CrossIcon /> : <SearchIcon />}
								</button>
							</div>
							<Button onClick={confirmSearchQuery}>Найти</Button>
						</div>
					)}
					{Object.keys(initState).length > 0 && schema.length > 1 && (
						<div className={styles.form}>
							<PayKitForm.Builder initialState={initState} schema={schema} />
						</div>
					)}

					{foundFields.length === 0 && <>{search ? <>Ничего не найдено</> : <>Список пуст</>}</>}
				</>
			</div>
		</>
	);
};

export default HiddenSettings;
