import { InsertItemType } from "modules/DefaultSettings/TranslationsList";
import { Button } from "pay-kit";
import { FC, ReactElement, useLayoutEffect } from "react";

import styles from "./addTranslation.module.scss";

const AddTranslation: FC<AddTranslationType> = ({
	children,
	insertItem,
	error,
	textAreaClassName,
	englishTranslationExists,
	isDisabled,
}) => {
	useLayoutEffect(() => {
		const dynamicTextArea = (e: Event) => {
			const target = e.target as HTMLTextAreaElement;
			target.style.height = `${target.scrollHeight}px`;
		};
		const labelsList = document?.querySelectorAll(`.${textAreaClassName}`);
		labelsList.forEach((label) => {
			const textArea = label?.firstChild as HTMLTextAreaElement;
			if (textArea?.style.height !== undefined) textArea.style.height = `${textArea?.scrollHeight}px`;
			textArea?.addEventListener("input", dynamicTextArea);
		});

		return () => {
			labelsList.forEach((label) => {
				const textArea = label?.firstChild;
				textArea?.removeEventListener("input", dynamicTextArea);
			});
		};
	});

	const renderErrorMessage = (message: string) => <div className={styles.errorMessage}>{message}</div>;

	return (
		<>
			<div className={styles.addTranslation}>
				<Button
					data-test-id="YpQhDcbHPAvPTjyK0Ryq_"
					data-testid="add-translation"
					variant="text-primary"
					onClick={() => insertItem({ language: undefined, text: undefined }, "insertAfter")}
					disabled={isDisabled}
				>
					+ Добавить
				</Button>
				<div className={styles.headers}>
					<div className={styles.language}>Язык</div> <div className={styles.translation}>Перевод</div>
				</div>
			</div>
			{!error && !englishTranslationExists && renderErrorMessage(`Английский язык обязателен`)}
			{error && renderErrorMessage(error)}
			{children}
		</>
	);
};

export default AddTranslation;

type AddTranslationType = {
	readonly children: readonly ReactElement[];
	readonly insertItem: InsertItemType["insertItem"];
	readonly error?: string;
	readonly textAreaClassName?: string;
	readonly englishTranslationExists: boolean;
	readonly isDisabled: boolean;
};
