import PaymentSystemLogo from "components/PaymentSystemLogo";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import { ScrollBox } from "pay-kit";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useRef } from "react";

import styles from "./options.module.scss";
const Options: FC<OptionsType> = ({ filteredOptions, onChange, setIsSearchbarOpen, value }) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const psOptionRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		psOptionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
	}, []);

	const renderOptions = () =>
		filteredOptions.map((ps, i) => {
			const isSelected = value.value === ps.value;
			const optionStyle = isSelected ? [styles.option, styles.selectedPS].join(" ") : styles.option;
			const psRef = isSelected ? psOptionRef : null;

			const handleOnclick = () => {
				onChange(ps);
				setIsSearchbarOpen(false);
			};

			return (
				<div data-test-id="ZVwaAl4eZ7LgZVQ-zEqry" key={i} className={optionStyle} onClick={handleOnclick} ref={psRef}>
					<PaymentSystemLogo id={ps.value} classNames={{ wrapper: styles.wrapper, logo: styles.psLogo }} />
					{!walletTypes.length && ps.label}
				</div>
			);
		});
	return (
		<ScrollBox scrollDirection="horizontal" className={styles.scrollBox}>
			{renderOptions()}
		</ScrollBox>
	);
};

export default Options;

type OptionsType = {
	readonly filteredOptions: readonly PsOptionType[];
	readonly onChange: Dispatch<PsOptionType>;
	readonly setIsSearchbarOpen: Dispatch<SetStateAction<boolean>>;
	readonly value: PsOptionType;
};
