import { Panel } from "@paykassma/pay-kit";
import RoutedTabs from "components/RoutedTabs";
import { Outlet } from "react-router-dom";

export const Incidents = () => {
	const tabs = [
		{
			label: "Инциденты",
			value: "/black-list/incidents",
		},

		{
			label: "Реквизиты",
			value: "/black-list/requisites",
		},
		{
			label: "Транзакции",
			value: "/black-list/transactions",
		},
	];

	return (
		<Panel title="Черный список">
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</Panel>
	);
};
