// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Transactions__index-lVS{margin-top:24px}.Transactions__idField-vA8{width:220px}.Transactions__counterpartyField-_hd{width:220px}.Transactions__transactionField-uzg{width:220px}.Transactions__createdField-IWS{width:230px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Incidents/Incident/Transactions/Index.module.scss"],"names":[],"mappings":"AAAA,yBACE,eAAA,CAGF,2BACE,WAAA,CAGF,qCACE,WAAA,CAGF,oCACE,WAAA,CAGF,gCACE,WAAA","sourcesContent":[".index {\n  margin-top: 24px;\n}\n\n.idField {\n  width: 220px;\n}\n\n.counterpartyField {\n  width: 220px;\n}\n\n.transactionField {\n  width: 220px;\n}\n\n.createdField {\n  width: 230px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Transactions__index-lVS`,
	"idField": `Transactions__idField-vA8`,
	"counterpartyField": `Transactions__counterpartyField-_hd`,
	"transactionField": `Transactions__transactionField-uzg`,
	"createdField": `Transactions__createdField-IWS`
};
export default ___CSS_LOADER_EXPORT___;
