import API from "api";
import { Counterparty, CounterpartyGroup } from "api/counterpartyGroup";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import AuthContext from "contexts/AuthContext";

type UseCounterpartyParams = {
	/** Параметр из строки запроса. searchParams.get("group") */
	group: string | null;
	/** Нужно ли применять фильтр для запроса.
	 * Фильтры работают при кнопке "Применить".
	 * Если кнопка не нажата, то фильтры не применяются
	 */
	isNeedToFetchByGroup?: boolean;
};

type UseCounterparty = {
	/**
	 * isListLoading
	 *
	 * Флаг загрузки списка контрагентов
	 */
	isListLoading: boolean;
	/**
	 * counterparties
	 *
	 * Список контрагентов
	 * Alert: Могут быть отфильтрованы по полю group
	 */
	counterparties?: Array<Counterparty>;
	/**
	 * fetchCounterparties
	 *
	 * Запрос контрагентов
	 */
	fetchCounterparties: () => void;
};

const isSearchWithGroup = (params: UseCounterpartyParams = { group: null }): boolean => {
	const { group, isNeedToFetchByGroup } = params;
	return !!group && !!isNeedToFetchByGroup;
};


export function useCounterparty(params: UseCounterpartyParams): UseCounterparty {
	const { group } = params;
	const { token } = useContext(AuthContext);

	const {
		isFetching: isListLoading,
		data: counterparties,
		refetch: fetchCounterparties,
	} = useQuery({
		queryKey: isSearchWithGroup(params) ? ["counterpartiesByGroup", group] : ["counterparties"],
		queryFn: () => {
			const isLoadFithGroup = group && group !== "0";

			return API.counterparty.loadList(isLoadFithGroup ? { group: group as CounterpartyGroup } : undefined);
		},
		initialData: [],
		enabled: !!token,
		refetchOnWindowFocus: false,
	});

	return {
		isListLoading,
		counterparties,
		fetchCounterparties,
	};
}
