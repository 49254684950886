import { Button, Modal, Table } from "@paykassma/pay-kit";
import { Credential } from "api/blackListGroup";
import Filter from "components/Filter";
import { useContext, useState } from "react";
import CreateRequisiteModal from "./components/CreateRequisiteModal";
import DeleteRequisiteModal from "./components/DeleteRequisiteModal";
import EditRequisitesModal from "./components/EditRequisitesModal";
import styles from "./Index.module.scss";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { Control } from "components/Control/Control";
import { useRequisites } from "./useRequisites";
import ReloadButton from "components/ReloadButton";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { Link } from "react-router-dom";

const Requisites = () => {
	const {
		currentPage,
		limit,
		load,
		paginationInfo,
		setCurrentPage,
		setLimit,

		isCredentialTypesLoading,
		credentialTypes,
		isCredentialsLoading,
		credentials,
		setFilter,
		filter,

		isAddModalOpen,
		onAddSuccess,
		toggleAddModal,

		isDeleteModalOpen,
		toggleDeleteModal,
		onDeleteSuccess,

		isEditModalOpen,
		toggleEditModal,
		onEditSuccess,
	} = useRequisites();

	const { hasRole } = useContext(AuthContext);

	const [selectedId, setSelectedId] = useState(undefined);

	return (
		<div className={styles.index}>
			<Modal isOpen={isAddModalOpen} onClose={toggleAddModal(false)} title="Создание реквизита">
				<CreateRequisiteModal onSuccess={onAddSuccess} onClose={toggleAddModal(false)} />
			</Modal>

			<Modal isOpen={isEditModalOpen} onClose={toggleEditModal(false)} title="Редактирование реквизита">
				<EditRequisitesModal id={selectedId} credential={credentials.find((item) => item.id === selectedId)} onClose={toggleEditModal(false)} onSuccess={onEditSuccess} />
			</Modal>

			<Modal isOpen={isDeleteModalOpen} onClose={toggleDeleteModal(false)} title="Удалить реквизит?">
				<DeleteRequisiteModal
					requisiteData={credentials.find((item) => item.id === selectedId)}
					onClose={toggleDeleteModal(false)}
					onSuccess={onDeleteSuccess}
				/>
			</Modal>

			{hasRole(Roles.MANAGE_BLACK_LIST) && (
				<Button variant="primary" classname={styles.addBtn} onClick={toggleAddModal(true)}>
					<PlusIcon />
					<span
						style={{
							marginLeft: "8px",
						}}
					>
						Добавить реквизит
					</span>
				</Button>
			)}

			<Filter
				fields={[
					{
						name: `id`,
						placeholder: "ID",
						component: "textInput",
						className: styles.idField,
					},
					{
						name: `fraud_cases_id`,
						placeholder: "ID Инцидента",
						component: "textInput",
						className: styles.fraudCasesField,
					},
					{
						name: `type`,
						placeholder: "Тип",
						component: "select",
						options: [{ label: "Все", value: "" }, ...credentialTypes],
						isLoading: isCredentialTypesLoading,
						className: styles.typeField,
					},
					{
						name: `value`,
						placeholder: "Значение",
						component: "textInput",
						className: styles.valueField,
					},
					{
						name: `created_at`,
						component: "dateRange",
						fromPlaceholder: "Создан от",
						toPlaceholder: "Создан до",
						dateTimeFormat: "YYYY-MM-DD",
						className: styles.createdField,
					},
				]}
				onApply={setFilter}
				values={filter}
			/>

			<Table
				data={credentials}
				columns={[
					{
						title: "ID",
						dataIndex: "id",
						key: "id",
					},
					{
						title: "ID инцидента",
						dataIndex: "fraud_cases_id",
						key: "fraud_cases_id",
						render: (form: Credential) => <Link to={`/black-list/incidents/${form.fraud_cases_id}/requisites`}>{form.fraud_cases_id}</Link>,
					},
					{
						title: "Тип",
						dataIndex: "type",
						key: "type",
					},
					{
						title: "Значение",
						dataIndex: "value",
						key: "value",
					},
					{
						title: "Создан",
						dataIndex: "created_at",
						key: "created_at",
						render: (data: Credential) => data.created_at.date,
					},
					{
						title: "Действия",
						dataIndex: "actions",
						key: "actions",
						render: (data) => {
							return (
								<div>
									{hasRole(Roles.MANAGE_BLACK_LIST) && (
										<Control
											actions={{
												onEdit: () => {
													setSelectedId(data.id);
													toggleEditModal(true)();
												},
												onDelete: () => {
													setSelectedId(data.id);
													toggleDeleteModal(true)();
												},
											}}
										/>
									)}
								</div>
							);
						},
					},
				]}
				isLoading={isCredentialsLoading}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.offset / limit + 1,
								setCurrentPage: (page: any) => setCurrentPage(page as number),
								limit,
								setLimit: (newLimit) => {
									setCurrentPage(1);
									setLimit(newLimit);
								},
								lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
								prefixElement: (
									<ReloadButton
										data-test-id=""
										isLoading={false}
										onClick={() =>
											load({
												id: filter.id,
												fraud_cases_id: filter.fraud_cases_id,
												created_from: filter.created_at?.from,
												created_to: filter.created_at?.to,
												type: filter.type,
												value: filter.value,
											})
										}
									/>
								),
						  }
						: undefined
				}
				rowKey="id"
			/>
		</div>
	);
};

export default Requisites;
