export const customSelectStyles: CustomSelectStylesType = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#F1FAFF" : "transparent",
		color: state.isSelected ? "#0072c3" : "#333",
		"&:active": {
			backgroundColor: "transparent",
		},
	}),
	menuList: (provided) => ({
		...provided,
		scrollbarWidth: "thin",
		"&::-webkit-scrollbar": {
			width: "4px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#888",
			borderRadius: "4px",
		},
	}),
};

type CustomSelectStylesType = {
	readonly option: (baseStyle: {}, state: { readonly isSelected: boolean }) => unknown;
	readonly menuList: (provided: {}) => unknown;
};
