import API from "api";
import { useState } from "react";


const useGetTimezone = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [timezone, setTimezone] = useState("");

	const load = () => {
		setError(null);
		setIsLoading(true);
		setTimezone("");

		API.settings.getTimezone().then((resp) => {
			if (resp.status === "ok") {
				setTimezone(resp.params.timezone);
			} else {
				throw new Error("Unexpected response in getTimezone");
			}
		}).catch((err) => {
			console.log(err);
			setError(err);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return ({
		load,
		timezone,
		isLoading,
		error
	});
};

export default useGetTimezone;