import { fetchApi } from "./fetch";

export interface ICountry {
	readonly code: string;
	readonly id: number;
	readonly title: string;
}

const countryGroup = {
	// method is in use only in proxies
	getList: () => fetchApi<GetCountriesListType>({url: "/country"})
};

export default countryGroup;


export type CountryType = {
	readonly code: string
	readonly id: number
	readonly title: string
}

export type GetCountriesListType = {
	readonly countries: readonly CountryType[]
}
