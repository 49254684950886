import { Alert, PayKitForm } from "@paykassma/pay-kit";
import { useState } from "react";
import { ValueCast } from "api/hiddenGroup";
import { getValueField } from "modules/Hidden/utils";
import { isRequired, match } from "utils/validate";
import styles from '../../../hiddenStyles.module.scss';
import { maxLength } from "modules/Currencies/ActionsProvider/components/CurrencyForm/utils";

type CreateFieldModalProps = {
    createField: (form: any) => Promise<any>,
    error?: string,
}

const CreateFieldModal = ({ createField, error }: CreateFieldModalProps) => {

    const [cast, setCast] = useState<ValueCast | null>(null);

    const [shouldErrorBeVisible, setErrorVisible] = useState<boolean>(true);

    const onSubmit = (form: any) => {
        setErrorVisible(true);
        const data = {...form};
        if (data.value_cast === ValueCast.ARRAY) {
            data.value = data.value.map((item) => item.value);
        }
        createField(data);
    }

    const onChange = (prev: any, curr: any) => {
        const currCast: ValueCast = curr.value_cast
        setCast(currCast);
        setErrorVisible(false);
        if (prev.value_cast !== currCast) {
            switch (currCast) {
                case ValueCast.ARRAY:
                    curr.value = [
                        {
                            value: "",
                        }
                    ]
                    break;
                case ValueCast.BOOLEAN:
                    curr.value = false
                    break;
                default:
                    curr.value = "";
                    break;
            }
        }
    }
    
    const schema: any[] = [
        {
            type: "TextInput",
            name: "name",
            label: "Название",
            isRequired: true,
            validation: [isRequired, match(/^[-._a-zA-Z0-9]+$/)],
        },
        {
            type: "Textarea",
            name: "description",
            label: "Описание",
            isRequired: true,
            validation: [isRequired, maxLength(191)],
        },
        {
            type: "Select",
            name: "value_cast",
            label: "Тип",
            isRequired: true,
            validation: [isRequired],
            options: [
                {
                    value: ValueCast.STRING,
                    label: "Строка",
                },
                {
                    value: ValueCast.INTEGER,
                    label: "Число",
                },
                {
                    value: ValueCast.BOOLEAN,
                    label: "Логический",
                },
                {
                    value: ValueCast.JSON,
                    label: "JSON",
                },
                {
                    value: ValueCast.ARRAY,
                    label: "Массив",
                },
            ],
        },
        getValueField(cast),
        {
            type: "Group",
            render: () => {
                return (
                    <>
                        {error && (
                            <div className={styles.errorWrapper}>
                                <Alert title={error} variant="danger" withIcon/>
                            </div>
                        )}
                    </>
                )
            },
            elements: [],
            existsIf: !!error && shouldErrorBeVisible,
        },
        {
            type: "Group",
            render: (children: any) => {
                return (
                    <div className={styles.btnWrapper}>
                        {children}
                    </div>
                )
            },
            elements: [{
                type: "SubmitButton",
                label: "Создать",
                onSubmit,
            }],
        },
    ]

    return (
        <div className={styles.wrapper}>
            <PayKitForm.Builder schema={schema} onStateChange={onChange}/>
        </div>
    )
}

export default CreateFieldModal;