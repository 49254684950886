import { Button } from "pay-kit";
import { FC } from "react";

import styles from "./confirmAction.module.scss";

type Props = {
	readonly onConfirm?: () => void;
	readonly onCancel?: () => void;
	readonly isLoading?: boolean;
	readonly isConfirmDisabled?: boolean;
	deleteText?: string;
	isDeleteBtn?: boolean;
};

export const ConfirmationModalButtons: FC<Props> = ({
	onConfirm,
	onCancel,
	isConfirmDisabled,
	deleteText,
	isDeleteBtn = false,
	isLoading = false,
}) => {
	const confirmStyle =
		isLoading || isConfirmDisabled ? [styles.confirm, styles.loadingAction].join(" ") : styles.confirm;

	return (
		<div className={[styles.wrapper, isDeleteBtn && styles.deleteRow].join(" ")}>
			<Button variant="secondary" classname={styles.cancel} onClick={() => onCancel && onCancel()} disabled={isLoading}>
				Отмена
			</Button>

			<Button
				classname={confirmStyle}
				onClick={() => onConfirm && onConfirm()}
				disabled={isLoading || isConfirmDisabled}
				variant={isDeleteBtn ? "delete" : "primary"}
			>
				{deleteText || "Сохранить"}
			</Button>
		</div>
	);
};
