import CaretIcon from "components/Icons/CaretIcon";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useActionsContext } from "modules/DefaultSettings/ActionsProvider";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import { Dispatch, FC, SetStateAction, useContext, useEffect } from "react";

import styles from "./selectedPsTitle.module.scss";

const SelectedPsTitle: FC<SelectedPsTitleType> = ({ value, setIsSearchbarOpen, isSearchbarOpen, onChange }) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { isSaved } = useActionsContext();
	const caretStyle = isSearchbarOpen ? [styles.caret, styles.caretDown].join(" ") : styles.caret;

	const handleOnClick = () => isSaved && setIsSearchbarOpen((prevState) => !prevState);

	useEffect(() => {
		onChange(value);
	}, []);

	return (
		<div data-test-id="gGXVr2C5L1_acd0PkurAB" onClick={handleOnClick} className={styles.selectedPs}>
			<PaymentSystemLogo id={value.value} classNames={{ wrapper: styles.wrapper, logo: styles.psLogo }} />
			{!walletTypes.length && <div className={styles.tooltip}>{value.label}</div>}
			<CaretIcon className={caretStyle} />
		</div>
	);
};

export default SelectedPsTitle;

type SelectedPsTitleType = {
	readonly value: PsOptionType;
	readonly setIsSearchbarOpen: Dispatch<SetStateAction<boolean>>;
	readonly isSearchbarOpen: boolean;
	readonly onChange: Dispatch<PsOptionType>;
};
