import { PayKitForm } from "@paykassma/pay-kit";
import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { blackListGroup, Credential } from "api/blackListGroup";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import { useCredentialTypes } from "modules/BlackList/hooks/useCredentialTypes";
import { hasErrors } from "modules/BlackList/utils";
import { FC, useEffect, useState } from "react";
import { isRequired } from "utils/validate";

type Props = {
	id?: string;
	onSuccess?: () => void;
	onClose: () => void;
	credential: Credential;
};

export const EditRequisitesModal: FC<Props> = ({ id, credential, onSuccess, onClose }) => {
	const [cases, setCases] = useState([]);
	const { getOptions, isCredentialTypesLoading } = useCredentialTypes({ isLoadOnMount: true });

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetcher = async () => {
			try {
				setIsLoading(false);

				const res = await blackListGroup.getCredentialTypes();

				if (res.status === "success") {
					setCredentialsTypes(res.data.map((el) => ({ label: el.type, value: el.type })));
				}
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		};

		fetcher();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		const fetcher = async () => {
			const resp = await blackListGroup.getCasesList();

			if (resp.status === "success") {
				setCases(resp.data);
			}
			setIsLoading(false);
		};

		fetcher();
	}, []);

	useEffect(() => {
		formAPI.setValue("type", getOptions().find((el) => el.value === credential?.type)?.value);
	}, [getOptions().find((el) => el.value === credential?.type)?.value]);

	useEffect(() => {
		formAPI.setValue("fraud_cases_id", cases.find((el) => el.id === credential?.fraud_cases_id)?.id);
	}, [cases.find((el) => el.id === credential?.fraud_cases_id)?.id]);

	const formAPI = useFormDataAPI({
		initialState: {
			type: credential.type,
			value: credential.value,
			fraud_cases_id: credential.fraud_cases_id,
		},
	});

	const submit = async (form: any) => {
		try {
			const { status } = await blackListGroup.updateCredential({ id, ...form });

			if (status === "success") {
				onSuccess && onSuccess();
			}

			if (status === "error") {
				window.pushAlert({ description: "Не удалось создать инцидент", type: "error" });
			}
		} catch (e) {
			window.pushAlert({ description: "Не удалось создать инцидент", type: "error" });
		}
	};

	return (
		<div>
			<PayKitForm.Builder
				formDataAPI={formAPI}
				schema={[
					{
						name: "fraud_cases_id",
						type: "Select",
						label: "ID Инцидента",
						isRequired: true,
						validation: isRequired,
						options: cases.map((el) => ({ label: el.id, value: el.id })),
					},
					{
						name: "type",
						type: "Select",
						label: "Тип реквизита",
						isRequired: true,
						validation: isRequired,
						options: getOptions(),
						loading: isCredentialTypesLoading,
					},
					{
						name: "value",
						type: "TextInput",
						label: "Значение",
						isRequired: true,
						validation: isRequired,
					},
				]}
			></PayKitForm.Builder>
			<ConfirmationModalButtons
				onConfirm={() => submit(formAPI.formState)}
				isConfirmDisabled={hasErrors(formAPI.errors)}
				onCancel={onClose}
			/>
		</div>
	);
};
