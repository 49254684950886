// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReloadButton__svgIcon-IdC{margin-right:8px}.ReloadButton__svgIcon-IdC.ReloadButton__isLoading-Jsd{animation-name:ReloadButton__spin-KFK;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear;animation-delay:300ms}@keyframes ReloadButton__spin-KFK{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/ReloadButton/ReloadButton.module.scss"],"names":[],"mappings":"AAAA,2BACC,gBAAA,CAEA,uDACC,qCAAA,CACA,qBAAA,CACA,kCAAA,CACA,gCAAA,CACA,qBAAA,CAIF,kCACC,KACC,sBAAA,CAED,GACC,wBAAA,CAAA","sourcesContent":[".svgIcon {\n\tmargin-right: 8px;\n\n\t&.isLoading {\n\t\tanimation-name: spin;\n\t\tanimation-duration: 1s;\n\t\tanimation-iteration-count: infinite;\n\t\tanimation-timing-function: linear;\n\t\tanimation-delay: 300ms;\n\t}\n}\n\n@keyframes spin {\n\tfrom {\n\t\ttransform: rotate(0deg);\n\t}\n\tto {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgIcon": `ReloadButton__svgIcon-IdC`,
	"isLoading": `ReloadButton__isLoading-Jsd`,
	"spin": `ReloadButton__spin-KFK`
};
export default ___CSS_LOADER_EXPORT___;
