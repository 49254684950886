import { ChangeLogType } from "api/changeLogGroup";
import DateTime from "components/DateTime";
import Spoiler from "modules/ChangeLog/LogInfoViewer";
import Counterparties from "modules/Wallets/components/Counterparties";

const User = {
	title: "Пользователь",
	dataIndex: "username",
	key: "username",
};

const CounterpartyCol = {
	title: "Контрагент",
	dataIndex: "counterparty_identifier",
	key: "counterparty_identifier",
};

const Date = {
	title: "Дата",
	dataIndex: "created_at",
	key: "created_at",
	render: ({ created_at }: ChangeLogType) => <DateTime dateTime={created_at} />,
};

const ObjectType = {
	title: "Тип объекта",
	dataIndex: "object_type",
	key: "object_type",
};

const ObjectID = {
	title: "ID объекта",
	dataIndex: "object_id",
	key: "object_id",
};

const ActionType = {
	title: "Вид действия",
	dataIndex: "action_type",
	key: "action_type",
	render: ({ action_type }: ChangeLogType) =>
		({
			insert: "Создание",
			update: "Изменение",
			delete: "Удаление",
		}[action_type] || action_type),
};

const OldValue = {
	title: "Старое значение",
	dataIndex: "old_value",
	key: "old_value",
	render: ({ old_value }: ChangeLogType) => (
		<Spoiler infoList={old_value} buttonText={{ showMore: "Показать всё", showLess: "Свернуть" }} />
	),
};

const NewValue = {
	title: "Новое значение",
	dataIndex: "new_value",
	key: "new_value",
	render: ({ new_value }: ChangeLogType) => (
		<Spoiler infoList={new_value} buttonText={{ showMore: "Показать всё", showLess: "Свернуть" }} />
	),
};

export const changeLogColumns = [User, CounterpartyCol, Date, ObjectType, ObjectID, ActionType, OldValue, NewValue];
