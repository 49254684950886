// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateNewModal__modalContent-m7l [class^=Modal__content]{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/modules/Currencies/ActionsProvider/components/CreateNewModal/CreateNewModal.module.scss"],"names":[],"mappings":"AAAA,0DACI,YAAA,CACA,qBAAA","sourcesContent":[".modalContent [class^=\"Modal__content\"] {\n    display: flex;\n    flex-direction: column;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `CreateNewModal__modalContent-m7l`
};
export default ___CSS_LOADER_EXPORT___;
