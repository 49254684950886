// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Requisites__index-k7O{margin-top:24px}.Requisites__addBtn-hpm{margin-bottom:24px}.Requisites__idField-fjh{width:220px}.Requisites__typeField-Kjo{width:220px}.Requisites__valueField-Zl6{width:220px}.Requisites__createdField-CTC{width:230px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Incidents/Incident/Requisites/Index.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CAGF,wBACE,kBAAA,CAGF,yBACE,WAAA,CAGF,2BACE,WAAA,CAGF,4BACE,WAAA,CAGF,8BACE,WAAA","sourcesContent":[".index {\n  margin-top: 24px;\n}\n\n.addBtn {\n  margin-bottom: 24px;\n}\n\n.idField {\n  width: 220px;\n}\n\n.typeField {\n  width: 220px;\n}\n\n.valueField {\n  width: 220px;\n}\n\n.createdField {\n  width: 230px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Requisites__index-k7O`,
	"addBtn": `Requisites__addBtn-hpm`,
	"idField": `Requisites__idField-fjh`,
	"typeField": `Requisites__typeField-Kjo`,
	"valueField": `Requisites__valueField-Zl6`,
	"createdField": `Requisites__createdField-CTC`
};
export default ___CSS_LOADER_EXPORT___;
