import { FC, useState } from "react";
import { blackListGroup, Credential } from "api/blackListGroup";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import styles from "./styles.module.scss";

type Props = {
	credential?: Partial<Credential>;

	onClose?: () => void;
	onSuccess?: () => void;
};

export const DeleteRequisiteModal: FC<Props> = ({ credential, onClose, onSuccess }) => {
	const { id, type, value, created_at } = credential || {};
	const [isLoading, setIsLoading] = useState(false);

	const onDelete = async () => {
		try {
			setIsLoading(true);
			const resp = await blackListGroup.deleteCredential(`${id}`);
			if (resp.status === "success") {
				onSuccess?.();
			}
		} catch (e) {
			window.pushAlert({ description: "Не удалось удалить реквизит", type: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.modal}>
			<div>
				<p>
					ID: <b>{id}</b> Тип: <b>{type}</b> Значение: <b>{value}</b>
				</p>
				<p>
					Создан: <b>{created_at?.date}</b>
				</p>
			</div>

			<ConfirmationModalButtons onCancel={onClose} onConfirm={onDelete} isDeleteBtn={true} deleteText="Удалить" isLoading={isLoading} />
		</div>
	);
};
