import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { RemoveCurrentItemType } from "modules/DefaultSettings/TranslationsList";
import { Button } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./remove.module.scss";

const Remove: FC<RemoveType> = ({ children, removeCurrentItem, setRemoveID }) => {
	return (
		<div className={styles.removeWrapper}>
			<div className={styles.remove}>
				{children}
				<Button
					data-test-id="qOKOPevJ46pBXzNT2azFc"
					data-testid="removeTranslation"
					onClick={(e) => {
						removeCurrentItem(e);
						setRemoveID();
					}}
					classname={styles.removeButton}
				>
					<TrashIcon />
				</Button>
			</div>
		</div>
	);
};

export default Remove;

type RemoveType = {
	readonly children: readonly ReactElement[];
	readonly removeCurrentItem: RemoveCurrentItemType;
	readonly setRemoveID: () => void;
};
