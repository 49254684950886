import { FC, useEffect } from "react"
import styles from "./styles.module.scss"

type Props = {
  id?: string
}

export const Title: FC<Props> = ({ id }) => {
  useEffect(() => {
    document.title = `Инцидент ${id || ""}`
  }, [id])

  return <h1 className={styles.heading}>Инцидент {`${id || ""}`}</h1>
}