// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PsSelect__selectPanelWrapper-Yu0{width:100%;max-width:274px;position:relative}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/psSelect.module.scss"],"names":[],"mappings":"AAAA,kCACE,UAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".selectPanelWrapper {\n  width: 100%;\n  max-width: 274px;\n  position: relative;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectPanelWrapper": `PsSelect__selectPanelWrapper-Yu0`
};
export default ___CSS_LOADER_EXPORT___;
