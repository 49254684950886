import QuestionMark from "assets/icons/questionMark.svg";
import { gettingTimezoneLabel, timeZoneLIstFormatter } from "modules/AppSettings/AppSettingsProfile/helpers";
import useGetTimezone from "modules/AppSettings/AppSettingsProfile/hooks/useGetTimezone";
import useGetTimezoneList from "modules/AppSettings/AppSettingsProfile/hooks/useGetTimezoneList";
import useSetTimeZone from "modules/AppSettings/AppSettingsProfile/hooks/useSetTimeZone";
import TabLoader from "modules/AppSettings/components/TabLoader";
import { PayKitForm, Tooltip } from "pay-kit";
import { ReactElement, useEffect } from "react";

import styles from "modules/AppSettings/AppSettingsProfile/appSettingsProfile.module.scss";

const LOCAL_STORAGE_LANG_TOKEN = "lang";

// This mock is up for removing after realization of translations
const mock = [
	{ value: "RUS", label: "Русский" },
	{ value: "ENG", label: "English" }
];

const AppSettingsProfile = () => {
	const getTimezoneListAPI = useGetTimezoneList();
	const availableTimezonesList = getTimezoneListAPI.list || [];
	const getTimezoneAPI = useGetTimezone();
	const defaultTimezone = gettingTimezoneLabel(availableTimezonesList, getTimezoneAPI.timezone);
	const timeZoneOptions = timeZoneLIstFormatter(availableTimezonesList);
	const setTimeZoneAPI = useSetTimeZone();

	useEffect(() => {
		getTimezoneAPI.load();
		getTimezoneListAPI.load();
	}, []);

	const submitLang: OnSubmitType = (state) => {
		if (state.lang) {
			localStorage.setItem(LOCAL_STORAGE_LANG_TOKEN, state.lang);
			window.pushAlert({
				type: `success`,
				description: `Запись обновлена`
			});
		}
	}

	const submitTimezone: OnSubmitType = (state) => {
		if (state.timeZone) {
			setTimeZoneAPI.update(state.timeZone);
		}
	}

	const timezoneSchema: any = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.appSettingsProfile}>
					<div className={styles.top}>Часовой профиль</div>
					<div className={styles.selectsContainer}>
						<div className={styles.selects}>
							{element}
						</div>
					</div>
				</div>
			),
			elements: [
				{
					name: "timeZone",
					type: "Select",
					label: (
						<div className={styles.timeZoneLabel}>
							Часовой пояс профиля
						</div>
					),
					options: timeZoneOptions,
					isLoading: getTimezoneListAPI.isLoading
				},
				{
					name: "submit",
					type: "SubmitButton",
					label: "Сохранить",
					onSubmit: submitTimezone,
					isLoading: setTimeZoneAPI.isLoading
				}
			]
		},
	];

	const timezoneInitState = {
		timeZone: defaultTimezone?.value,
	}

	const langSchema: any = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.appSettingsProfile}>
					<div className={styles.top}>Настройки профиля</div>
					<div className={styles.selectsContainer}>
						<div className={styles.selects}>
							{element}
						</div>
					</div>
				</div>
			),
			elements: [
				{
					name: "lang",
					type: "Select",
					label: "Язык",
					options: mock
				},
				{
					name: "submit",
					type: "SubmitButton",
					label: "Сохранить",
					onSubmit: submitLang,
				}
			]
		},
	];

	const langInitState = {
		lang: localStorage.getItem(LOCAL_STORAGE_LANG_TOKEN) || mock[0].value,
	};

	if (!getTimezoneAPI.timezone || !getTimezoneListAPI.list) return <TabLoader />;

	return (
		<div className={styles.appSettingsProfileWrapper}>
			<PayKitForm.Builder
				schema={langSchema}
				initialState={langInitState}
			/>
			<PayKitForm.Builder
				schema={timezoneSchema}
				initialState={timezoneInitState}
			/>
		</div>
	);
};

export default AppSettingsProfile;

type OnSubmitType = (state: Partial<{
	readonly "timeZone": string
	readonly "lang": string
}>) => void
