import { useContext, useEffect, useState } from "react";
import { Button, Table, Tooltip } from "@paykassma/pay-kit";
import PlusIcon from "src/assets/icons/plus.svg";
import API from "api";
import { HiddenField, ValueCast } from "api/hiddenGroup";
import RefreshIcon from 'assets/icons/refresh.svg';
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import styles from "./fields.module.scss";
import { HiddenSettingsContext } from "contexts/HiddenFieldsContext";

const HiddenFields = () => {
	const { 
		fetchFields, 
		hiddenFields, 
		isLoading, 
		openDeleteFieldModal,
		openCreateFieldModal,
	} = useContext(HiddenSettingsContext);

	useEffect(() => {
		fetchFields();
    }, []);

	const columns: any[] = [
		{
			key: "id",
			dataIndex: "id",
			title: "ID",
		},
		{
			key: "name",
			dataIndex: "name",
			title: "Название",
		},
		{
			key: "value_cast",
			dataIndex: "value_cast",
			title: "Тип",
			render: ({ value_cast }: any) => {
				const castsLabels: Record<ValueCast, string> = {
					[ValueCast.ARRAY]: "Массив",
					[ValueCast.BOOLEAN]: "Логический",
					[ValueCast.INTEGER]: "Число",
					[ValueCast.JSON]: "JSON",
					[ValueCast.STRING]: "Строка"
				}

				return (
					<div>
						{castsLabels[value_cast as ValueCast]}
					</div>
				)
			}
		},
		{
			key: "description",
			dataIndex: "description",
			title: "Описание",
		},
		{
			key: "value",
			dataIndex: "value",
			title: "Значение",
			render: ({ value, value_cast }: any) => {
				if ([ValueCast.ARRAY, ValueCast.JSON].includes(value_cast)) return JSON.stringify(value);
				return value;
			}
		},
		{
			key: "actions",
			dataIndex: "actions",
			title: "Действия",
			render: ({ id, name }: HiddenField) => {
				return (
					<Tooltip tip="Удалить поле" preferredSide="bottom">
						<Button variant="text-secondary" onClick={() => openDeleteFieldModal(id, name)}>
							<TrashIcon />
						</Button>
					</Tooltip>
				)
			}
		},
	];

	return (
		<>
			<div className={styles.header}>
				<Button variant="primary" onClick={openCreateFieldModal}>
					<PlusIcon />
					&nbsp; Добавить поле
				</Button>
				<Button variant="text-primary" onClick={fetchFields}>
					<RefreshIcon />
					&nbsp; Обновить
				</Button>
			</div>

			<Table
				data={hiddenFields}
				columns={columns}
				rowKey="id"
				isLoading={isLoading}
				skeleton={{
					rowsCount: 3,
				}}
				emptyPlaceholder={""}
			/>
		</>
	);
}

export default HiddenFields;