import { CounterpartyGroup } from "api/counterpartyGroup";

/**
 * Каждой группе соответсвует номер.
 * 1 - A
 * 2 - B
 */
type GroupLetters = "A" | "B" | "C" | "D";

const getGroups = (): Record<CounterpartyGroup, GroupLetters> => ({
	1: "A",
	2: "B",
	3: "C",
	4: "D",
});

export const getGroupsOptions = ({ withAll }: {withAll?: boolean} = {}) => {
	const groups = getGroups();

	if (withAll) {
		groups[0] = "Все";
	}

	return Object.keys(groups).map((key) => ({
		label: groups[key as CounterpartyGroup],
		value: key,
	}));
};

export const getGroupByKey = (key: CounterpartyGroup): GroupLetters => {
	const groups = getGroups();

	return groups[key];
};
