import { fetchApi } from "api/fetch";

const walletsDebuggingGroup = {
	loadLogsCategoryList: () => fetchApi<LoadLogsCategoryList>({url: "wallet-log/categories"}),
	loadLogList: (params: Record<string, string>) => fetchApi<LoadLogListType[]>({url: `wallet-log`, params }),
};

export default walletsDebuggingGroup;

export type WithdrawalsLogType = {
	readonly created_at: string
	readonly id: number
	readonly message: string
	readonly wallet_type: string
	readonly withdrawal_id: number
}

export type LogsCategory = {
	readonly text: string
	readonly value: string
}

export type LoadLogsCategoryList = {
	readonly categories: readonly LogsCategory[]
}

export type LogListData = {
	readonly category: string
	readonly created_at: string
	readonly error_type: string
	readonly id: number
	readonly message: string
	readonly wallet_hash_id: number
	readonly wallet_identifier: string
	readonly wallet_type_code: string
}

export type LoadLogListType = {
	readonly current_page: number
	readonly data: readonly LogListData[]
	readonly from: number
	readonly last_available_page: number
	readonly per_page: number
	readonly to: number
}
