import {ValidationError} from "yup";

export const validate = (schema: any, data: any): Record<string, string> => {
	let newErrors = {};
	try {
		schema.validateSync(data, { abortEarly: false });
	} catch (e: any) {
		const validationErrors: readonly ValidationError[] = e.inner;
		validationErrors.forEach((error) => {
			newErrors = { ...newErrors, [error.path || ""]: error.message };
		});
	}

	return newErrors;
};


export const isRequired = (value: any) => {
	return value ? undefined : "Обязательное поле";
}

export const isJSON = (value: string) => {
	if (value) {
		try {
			JSON.parse(value);
			return undefined
		} catch {
			return "Неправильный формат JSON"
		}
	}
}

export const isValidNumber = (value: string) => {
	if (value) {
		const numVal = +value;
		if (Number.isNaN(numVal)) {
			return "Неправильный формат числа"
		}
	}
	return undefined
}

export const match = (regex: RegExp) => (value: string) => {
	if (value) {
		if (!regex.test(value)) {
			return "Неверный формат"
		}
	}
	return undefined;
}