import PageTitle from "components/PageTitle";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import DefaultSettings from "modules/DefaultSettings";
import { useEffect } from "react";

const DefaultSettingsPage = () => {
	const { hasRole } = useAuthContext();

	hasRole(Roles.PAYMENT_SYSTEM_SETTINGS, { redirectToErrorPage: true });
	useEffect(() => {
		document.title = "Настройки по умолчанию";
	}, []);

	return (
		<>
			<PageTitle title="Настройки по умолчанию" />
			<DefaultSettings />
		</>
	);
};

export default DefaultSettingsPage;
