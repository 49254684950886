// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Remove__removeWrapper-Hws{display:flex;flex-direction:column;margin-top:16px}.Remove__removeWrapper-Hws .Remove__remove-dC_{display:flex;gap:16px}.Remove__removeWrapper-Hws .Remove__remove-dC_ .Remove__removeButton-tsK{min-width:initial;padding:8px;background:initial}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/TranslationsList/components/Remove/remove.module.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,qBAAA,CACA,eAAA,CAEA,+CACE,YAAA,CACA,QAAA,CAEA,yEACE,iBAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".removeWrapper {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n\n  .remove {\n    display: flex;\n    gap: 16px;\n\n    .removeButton {\n      min-width: initial;\n      padding: 8px;\n      background: initial;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeWrapper": `Remove__removeWrapper-Hws`,
	"remove": `Remove__remove-dC_`,
	"removeButton": `Remove__removeButton-tsK`
};
export default ___CSS_LOADER_EXPORT___;
