// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationModalButtons__wrapper-rcn{display:flex;justify-content:space-between}.ConfirmationModalButtons__deleteRow-_UE{justify-content:flex-end;gap:16px}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModalButtons/confirmAction.module.scss"],"names":[],"mappings":"AAAA,uCACC,YAAA,CACA,6BAAA,CAGD,yCACC,wBAAA,CACA,QAAA","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.deleteRow {\n\tjustify-content: flex-end;\n\tgap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ConfirmationModalButtons__wrapper-rcn`,
	"deleteRow": `ConfirmationModalButtons__deleteRow-_UE`
};
export default ___CSS_LOADER_EXPORT___;
