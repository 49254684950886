import { useMutation } from "@tanstack/react-query";
import API from "api";
import { UpdateBodyType } from "api/counterpartyGroup";

type UseUpdateCounterparty = {
	/**
	 * id
	 *
	 * Идентификатор контрагента для обновления
	 */
	id: string;

	/**
	 * onSuccess
	 *
	 * Хэндлер, вызываемый после успешного обновления контрагента
	 */
	onSuccess?: () => void;
};

export function useUpdateCounterparty({ id, onSuccess }: UseUpdateCounterparty) {
	const { mutate: updateCounterparty } = useMutation({
		mutationFn: ({ id, data }: { id: string; data: UpdateBodyType }) => API.counterparty.update(id, data),
		mutationKey: ["counterparty-update", id],
		onSuccess,
		onError: (error) => {
			window.pushAlert({
				type: "error",
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore Промис реджектится только со строкой в апи
				description: error,
			});
		},
	});

	return { updateCounterparty };
}
