// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageTitle__heading-ePW{margin-bottom:16px;color:var(--Blue_gray-90, #21272a);font-family:Inter;font-size:32px;font-style:normal;font-weight:600;line-height:40px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/components/PageTitle/styles.module.scss"],"names":[],"mappings":"AAAA,wBACE,kBAAA,CAED,kCAAA,CAEA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".heading {\n  margin-bottom: 16px;\n\n\tcolor: var(--Blue_gray-90, #21272a);\n\n\tfont-family: Inter;\n\tfont-size: 32px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `PageTitle__heading-ePW`
};
export default ___CSS_LOADER_EXPORT___;
