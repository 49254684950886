import styles from './DeleteModal.module.scss';
import {ReactNode, useState} from "react";
import {ModalProps} from "utils/types";
import { Button } from '@paykassma/pay-kit';

type IDeleteModalProps = ModalProps & {
	readonly actions: {
		readonly onDelete: () => Promise<any>,
	}
	readonly text: ReactNode,
	readonly closeLabel?: string,
	readonly deleteLabel?: string,
	readonly btnClassname?: string;
}

export const DeleteModal = ({ closeModal, actions, text, closeLabel = "Закрыть", deleteLabel = "Удалить", btnClassname = `` }: IDeleteModalProps) => {
	const [loading, setLoading] = useState<boolean>(false);

	const handleClose = () => {
		closeModal && closeModal();
	};

	const handleDelete = () => {
		setLoading(true);
		actions.onDelete().then(() => {
			setLoading(false);
			handleClose();
		});
	};
	return (
		<div className={styles.wrapper}>
			<div className={styles.text}>
				{text}
			</div>
			<div className={styles.controls}>
				<Button classname={styles.closeBtn} data-test-id="UdljijEGncDvVtSnmDxr4" variant="secondary" onClick={handleClose}>
					{closeLabel}
				</Button>
				<Button isLoading={loading} classname={btnClassname} data-test-id="UdljijEGncDvVtSnmDxr4" variant="delete" onClick={handleDelete}>
					{deleteLabel}
				</Button>
			</div>
		</div>
	);
};
