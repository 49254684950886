import API from "api";
import { GetLanguagesType } from "api/defaultSettingsGroup";
import { useCallback, useState } from "react";

const useGetLanguages = () => {
	const [list, setList] = useState<readonly GetLanguagesType[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load = useCallback(() => {
		setIsLoading(true);
		setError(null);

		API.defaultSetting
			.getLanguages()
			.then((res) => {
				if (res.status === "success") {
					setList(res.data);
				} else {
					throw new Error("Unexpected response in useGetLanguages");
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useGetLanguages;
