import { Modal } from "@paykassma/pay-kit";
import API from "api";
import { HiddenField } from "api/hiddenGroup";
import { deepClone } from "helpers/deepClone";
import CreateFieldModal from "modules/Hidden/Fields/modals/Create/CreateFieldModal";
import DeleteFieldModal from "modules/Hidden/Fields/modals/DeleteFieldModal";
import React, { useState } from "react";

export type IHiddenSettings = {
    hiddenFields: HiddenField[],
    isLoading: boolean,
    fetchFields: () => void,
    openDeleteFieldModal: (id: number, name: string) => void,
    openCreateFieldModal: () => void,
}

const initialState: IHiddenSettings = {
    hiddenFields: [],
    isLoading: false,
    fetchFields: () => {},
    openDeleteFieldModal: () => {},
    openCreateFieldModal: () => {},
}

type ModalData = {
    open: boolean,
}

type DeleteModalData = ModalData & {
    id: null | number,
    name: string,
}

type CreateModalData = ModalData & {
    error?: string
}

export const HiddenSettingsContext = React.createContext<IHiddenSettings>(initialState);
HiddenSettingsContext.displayName = "HiddenSettingsContext";


export default function HiddenSettingsContextProvider(props: any) {
	const [hiddenFields, setHiddenFields] = useState<HiddenField[]>([]);
	const [isLoading ,setLoading] = useState<boolean>(false);

    const [deleteFieldModalData, setDeleteFieldModalData] = useState<DeleteModalData>({
        open: false,
        id: null,
        name: "",
    });

    const [createModalData, setCreateModalData] = useState<CreateModalData>({
        open: false,
        error: "",
    })

    const deleteField = (id: number): Promise<any> => {
        return API.hidden.deleteHiddenField(id).then((resp) => {
            if (resp.status === "success") {
                fetchFields()
            }
        });;
    }

    const openDeleteFieldModal = (id: number, name: string) => {
        setDeleteFieldModalData({
            open: true,
            id,
            name,
        })
    }

    const closeDeleteFieldModal = () => {
        setDeleteFieldModalData({
            open: false,
            id: null,
            name: "",
        })
    }

    const openCreateFieldModal = () => {
        setCreateModalData({
            open: true,
            error: "",
        });
    }

    const closeCreateFieldModal = () => {
        setCreateModalData({
            open: false,
            error: "",
        });
    }

    const fetchFields = () => {
		setLoading(true);
		API.hidden.getHiddenFields().then(resp => {
			if (resp.status === "success") {
				setHiddenFields(resp.data);
				setLoading(false);
			}
		})
	}

    const createField = (form: Partial<HiddenField>) => {
        setCreateModalData({
            open: true,
            error: "",
        })
        const data = deepClone(form);
        if (data.value_cast === "array") {
            data.value = data.value.map((v: any) => v.value);
        } else if (data.value_cast === "json") {
            data.value = JSON.parse(data.value);
        }
        return API.hidden.createHiddenField(data).then((resp) => {
            if (resp.status === "success") {
                fetchFields()
                closeCreateFieldModal()
            } else {
                setCreateModalData({
                    open: true,
                    error: resp.error_message,
                })
            }
        });
    }

    const value = {
        fetchFields,
        hiddenFields,
        isLoading,
        openDeleteFieldModal,
        openCreateFieldModal,
    }
    return (
        <HiddenSettingsContext.Provider value={value}>
            {props.children}
            <Modal 
                title="Удалить поле" 
                isOpen={deleteFieldModalData.open} 
                onClose={closeDeleteFieldModal}
            >
                <DeleteFieldModal 
                    name={deleteFieldModalData.name} 
                    id={deleteFieldModalData.id} 
                    onDelete={deleteField}
                    closeModal={closeDeleteFieldModal}
                />
            </Modal>
            <Modal
                title="Добавление поля" 
                isOpen={createModalData.open} 
                onClose={closeCreateFieldModal}
            >
                <CreateFieldModal createField={createField} error={createModalData.error}/>
            </Modal>
        </HiddenSettingsContext.Provider>
    );
}