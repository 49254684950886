import { FC } from "react";
import { Button, Select } from "@paykassma/pay-kit";
import styles from "./Filters.module.scss";
import { getGroupsOptions } from "modules/Counterparty/utils/mapGroups";
import { useFilters } from "./useFilters";

export const Filters: FC = () => {
	const { selectedFilter, handleGroupChange, handleFetchByFilters, handleResetFilters } = useFilters();

	return (
		<div className={styles.filters}>
			<Select
				name="group"
				value={selectedFilter}
				onChange={handleGroupChange}
				placeholder="Группа"
				options={getGroupsOptions({ withAll: true })}
				className={styles.select}
			/>
			<div className={styles.buttons}>
				<Button onClick={handleFetchByFilters}>Применить</Button>
				{selectedFilter && <Button onClick={handleResetFilters}>Очистить фильтры</Button>}
			</div>
		</div>
	);
};
