import { useState } from "react";
import API from "api/currenciesGroup";

const useUpdateCurrencyRateHook = ({ onSuccess }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const update = (codes: string[]) => {
		setIsLoading(true);

		API.updateRates(codes)
			.then((res) => {
				if (res.status === "success") {
                    onSuccess();
					window.pushAlert({
						description: codes.length > 1 ? "Обновление курсов валют успешно запущено" : "Курс валюты успешно обновлен",
						type: "success"
					})

					return res;
				}

				if (res.status === "error") {
					window.pushAlert({
						description: res.error_message,
						type: "error"
					})

					return res;
				}

				throw new Error("Unexpected response in useUpdateCurrencyRateHook");
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return {
		update,
		isLoading,
	};
};

export default useUpdateCurrencyRateHook;
