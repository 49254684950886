import { GetHintType, GetWalletTypesType } from "api/defaultSettingsGroup";
import ActionsProvider from "modules/DefaultSettings/ActionsProvider";
import FormTypesSelector from "modules/DefaultSettings/FormTypes";
import useGetFormTypes from "modules/DefaultSettings/hooks/useGetFormTypes";
import useGetLanguages from "modules/DefaultSettings/hooks/useGetLanguages";
import useGetWalletTypesList from "modules/DefaultSettings/hooks/useGetWalletTypesList";
import PsSelect, { PsOptionType } from "modules/DefaultSettings/PsSelect";
import TranslationsList from "modules/DefaultSettings/TranslationsList";
import { useEffect, useState } from "react";

import { WalletTypesItemType } from "api/walletTypesGroup";
import { rejectSettlement } from "utils/filterSettlement";

const getSorted: GetSortedType = (list) => {
	const sortedList = [...(list || [])].sort((a, b) => a.name.localeCompare(b.name));
	return sortedList.map((type) => ({ label: type.name, value: type.code, payment: type.type }));
};

const DefaultSettings = () => {
	const [ps, setPs] = useState<PsOptionType | null>(null);
	const [formType, setFormType] = useState<GetHintType["form_type"]>("requisite");
	const walletTypesAPI = useGetWalletTypesList();
	const formTypesAPI = useGetFormTypes();
	const languagesAPI = useGetLanguages();

	useEffect(() => {
		walletTypesAPI.load();
	}, [walletTypesAPI.load]);

	useEffect(() => {
		formTypesAPI.load();
	}, [formTypesAPI.load]);

	useEffect(() => {
		languagesAPI.load();
	}, [languagesAPI.load]);

	const options = getSorted(rejectSettlement(walletTypesAPI.list));
	const selectedPs = ps || options[0];

	return (
		<ActionsProvider>
			<>
				<PsSelect options={options} value={selectedPs} onChange={setPs} />
				<FormTypesSelector
					formTypes={formTypesAPI.list}
					setFormType={setFormType}
					paymentType={ps?.payment}
					selectedPs={selectedPs}
				>
					<TranslationsList selectedPs={selectedPs} formType={formType} languageList={languagesAPI.list} />
				</FormTypesSelector>
			</>
		</ActionsProvider>
	);
};

export default DefaultSettings;

type GetSortedType = (list: readonly WalletTypesItemType[] | null) => readonly PsOptionType[];
