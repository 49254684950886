import { PayKitForm } from "@paykassma/pay-kit";
import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { blackListGroup } from "api/blackListGroup";
import { ConfirmationModalButtons } from "components/ConfirmationModalButtons";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isRequired } from "utils/validate";

import styles from "./styles.module.scss";
import { hasErrors } from "modules/BlackList/utils";

type Props = {
	onClose: () => void;
};

export const CreateIncidentModal: FC<Props> = ({ onClose }) => {
	const navigate = useNavigate();

	const formAPI = useFormDataAPI({});

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (form: any) => {
		try {
			setIsLoading(true);
			const resp = await blackListGroup.createCase(form);

			if (resp.status === "success") {
				if (resp.data.id) {
					navigate(`/black-list/incidents/${resp.data.id}/requisites`);
				}
			}

			if (resp.status === "error") {
				window.pushAlert({ description: "Не удалось создать инцидент", type: "error" });
			}
		} catch (e) {
			window.pushAlert({ description: "Не удалось создать инцидент", type: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.modal}>
			<PayKitForm.Builder
				formDataAPI={formAPI}
				schema={[
					{
						name: "details",
						label: "Комментарий",
						type: "Textarea",
						isRequired: true,
						placeholder: "Опишите детали инцидента",
						validation: [isRequired],
					},
				]}
			></PayKitForm.Builder>
			<ConfirmationModalButtons
				isLoading={isLoading}
				onCancel={onClose}
				isConfirmDisabled={hasErrors(formAPI.errors)}
				onConfirm={() => onSubmit(formAPI.formState)}
			/>
		</div>
	);
};
