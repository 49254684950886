import AuthContext from "contexts/AuthContext";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Incidents } from "./IncidentList";
import { OneIncident } from "./OneIncident";
import { Roles } from "contexts/AuthContext/utils/enums";

const BlackListPage = () => {
	const { hasRole } = useContext(AuthContext);
	const { id } = useParams();

	const isOneIncidentPage = () => Boolean(id);

	useEffect(() => {
		document.title = "Черный список"

		hasRole(Roles.VIEW_BLACK_LIST, { redirectToErrorPage: true });
	}, [hasRole]);

	// В зависимости от наличия id нужны разные страницы
	return isOneIncidentPage() ? <OneIncident id={id} /> : <Incidents />;
};

export default BlackListPage;
