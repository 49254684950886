import PageTitle from "components/PageTitle";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import Currencies from "modules/Currencies";
import { useEffect } from "react";

const CurrenciesPage = () => {
	const { hasRole } = useAuthContext();

	useEffect(() => {
		document.title = "Валюты";

		hasRole(Roles.MANAGE_CURRENCIES, { redirectToErrorPage: true });
	}, [hasRole]);

	return (
		<>
			<PageTitle title="Валюты" />
			<Currencies />
		</>
	);
};

export default CurrenciesPage;
