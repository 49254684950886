
import { fetchApi, fetchOldApi } from "./fetch";
import { infer as Infer, array, number, object, string, union, literal } from "zod";

const resource = "counterparty";

export type CounterpartyGroup = 1 | 2 | 3 | 4;

const counterpartySchema = object({
	/* UUID контрагента. */
	id: string(),
	/* Идентификатор контрагента. */
	identifier: string(),
	/* Включенные парсеры у контрагента.  */
	applied_scarpers: array(number()),
	/* Доступные парсеры. */
	available_scarpers: array(
		object({
			/* Идентификатор записи в таблице с парсерами */
			id: number(),
			/* Наименование парсера */
			name: string(),
		})
	).nullable(),
	count: object({
		/* 	Число включенных парсеров */
		scrapers: number(),
	}),
	/* 	Дата создания */
	created_at: string(),
	/* Домен контрагента */
	domain: string(),
	/* IP домена */
	domain_ip: string(),
	/* ссылка */
	link: string(),
	/* Дата обновления */
	updated_at: string(),
	/* Группа контрагента, возможные значения :
		1-А
		2-B
		3-C
		4-D */
	group: union([literal(0), literal(1), literal(2), literal(3), literal(4)]).optional(),
});

export type Counterparty = Infer<typeof counterpartySchema>;

const counterpartyListSchema = array(counterpartySchema);

type CounterpartyList = Infer<typeof counterpartyListSchema>;

export type UpdateBodyType = {
	identifier: string;
	applied_scarpers?: Array<number>;
	group?: CounterpartyGroup;
	parsing_start_date?: string;
};

const updateResponseSchema = object({
	id: string(),
	identifier: string(),
	applied_scarpers: array(number()),
	available_scarpers: array(object({
		id: number(),
		name: string(),
	})).nullable(),
	count: object({
		scrapers: number(),
	}),
	created_at: string().nullable(),
	domain: string(),
	link: string(),
	updated_at: string().nullable(),
});

export type UpdateResponse = Infer<typeof updateResponseSchema>;

const counterpartyGroup = {
	loadList: async (params?: { group: CounterpartyGroup }) => {
		try {
			const response = await fetchOldApi<{ counterparties: CounterpartyList }>({
				url: resource,
				params,
			});

			if (response.status === "ok") {
				try {
					const counterparties = await counterpartyListSchema.parse(response.counterparties);

					return counterparties;
				} catch (e) {
					console.error(`Counterparty schema error: ${e}`);
				}
			}
		} catch (e) {
			console.error(e);
		}
	},
	update: async (id: string, body: UpdateBodyType) => {
		try {
			const response = await fetchApi<{ counterparty: UpdateResponse }, UpdateBodyType>({
				url: `${resource}/${id}`,
				method: "PUT",
				config: {
					data: body,
					withToken: true,
				},
			});

			if (response.status === "error") {
				return new Promise<Error>((_, reject) => reject(response?.error_message));
			}

			if (response.status == "success") {
				try {
					const counterparty = await updateResponseSchema.parse(response.data);

					return counterparty;
				} catch (e) {
					console.error(e);
				}
			}
		} catch (e) {
			console.error(e);
		}
	},
	delete: async (id: string) => {
		const response = await fetchOldApi({
			url: `${resource}/remove`,
			method: "POST",
			config: {
				data: { counterparty_id: id },
			},
		});

		if (response) {
			if (response?.code === 50003) {
				throw new Error(response?.error_message);
			}
		}
	},
};

export default counterpartyGroup;
