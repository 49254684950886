export enum WalletTypes {
	PAY_TM = `paytm`,
	PAY_TM_BIZ = `paytm_biz`,
	SKRILL = `skrill`,
	NETELLER = `neteller`,
	PERFECT_MONEY = `perfect_money`,
	EPAY = `epay`,
	HELP_2_PAY = `help2pay`,
	CERTUS_FINANCE = `certus_fin`,
	IMPS_IB = `imps_ib`,
	UPI_IB = `upi_ib`,
	BKASH = `bkash`,
	DUSUPAY = `dusupay`,
	NGAN_LUONG = `nganluong`,
	NGAN_LUONG_EMAIL = `nganluong_email`,
	PAY_OP = `payop`,
	PAYKASSA = `paykassa`,
	NAGAD = `nagad`,
	NAGAD_API = `nagad_api`,
	ROCKET = `rocket`,
	PHONE_PE = `phone_pe`,
	VIETCOM_BANK = `vietcombank`,
	CASHMAAL = `cashmaal`,
	PAYTRUST88 = `paytrust88`,
	PAYME_UZ = `payme_uz`,
	SUREPAY = `surepay`,
	PAYTECA = `payteca`,
	PAYZEN = `payzen`,
	TECHCOMBANK = `techcombank`,
	PAYGIGA = `paygiga`,
	PAG_SMILE = `pagsmile`,
	AKBANK = `akbank`,
	YAAR_PAY = `yaarpay`,
	BCA_BANK = `bcabank`,
	RHB_BANK = `rhbbank`,
	MAYBANK = `maybank`,
	UPI_AB = `upi_ab`,
	UPI_HB = `upi_hb`,
	IMPS_HB = `imps_hb`,
	LOCAL_CRYPTO = `local_crypto`,
	SAFECOMMERZ = `safecommerz`,
	UPI_NAAP = `upi_naap`,
	IMPS_AB = `imps_ab`,
	IMPS_IDB = `imps_idb`,
	UPI_IDB = `upi_idb`,
	TEST = `test`,
	LAYKAPAY = `layka_pay`,
	UPI_AP = `upi_ap`,
	UPI_L = `upi_l`,
	UZCARD_API = `uzcard_api`,
	PAYTM_API = `paytm_api`,
	UPI_PB = `upi_pb`,
	UPI_S = `upi_s`,
	BKASH_API = `bkash_api`,
	MPESA = `mpesa`,
	EASYPAISA = `easypaisa`,
	/*
		См. https://jira.fixmost.com/browse/PAY-12492,
		раскомментить как будет понятно как должно работать
	*/
	//UPI_SB: `upi_sb`,
	UPI_SF = `upi_sf`,
	UPI_IN = `upi_in`,
	UPI_W = `upi_w`,
	BKASH_API_CEBALRAI = `bkash_api_cebalrai`,
	CLICK_UZS = `click_uzs`,
	WAVEPAY = `wavepay`,
	UPI_M = `upi_m`,
	PHONE_PE_S = `phonepe_s`,
	JAZZCASH = `jazzcash`,
	IPAY = `ipay`,
	IPAY_API = `ipay_api`,
	JAZZCASH_BUSINESS = `jazzcash_business`,
	EASYPAISA_API = `easypaisa_api`,
}

export const errorsMap = {
	required: "",
	// Общая ошибка. Неизвестно, в чем дело. Замена для: Something went wrong!
	any: "Ошибка",
	anyResponse: "Ошибка запроса",
	anySending: "Ошибка отправки",
	anyCreate: "Ошибка создания",
	noAccess: "Нет доступа",
	cantCopy: "Не удалось скопировать",
	cantUpdate: "Произошла ошибка при попытке внести изменения",
	cantUpdateComission: "Произошла ошибка при изменении комиссии",
	cantGetWalletData: "Не удалось получить данные о кошельке",
	cantConfirmPhone: "Не удалось подтвердить телефон",
	cantConfirmComment: "Не удалось изменить комментарий",
	cantGetCurrenciesData: "Не удалось загрузить данные о валютах",
	cantGetWalletsData: "Не удалось загрузить данные о кошельках",
	cantGetMetricsData: "Не удалось загрузить данные о метриках",
	cantGetLoadSummaryData: "Не удалось загрузить данные о нагрузках",
	cantGetStatisticData: "Не удалось получить статистику по кошелькам",
	cantCreateManualPaymentSystem: "Произошла ошибка при создании Ручной ПС",
	cantSaveTheSettings: "Произошла ошибка при сохранении настроек",
	checkTheFieldForCorrectness: "Проверьте правильность заполнения полей",
	urlsIsrequired: "Список url обязателен",


	getNotFoundRoute: (url: string | undefined) => `Роут ${url} не найден`,
	getStatusForEndpoint: (url: string | undefined, status: number) => `${url} - ошибка ${status}`,
	getCurrencyError: (type: string | undefined) => `Не удалось загрузить валюты для ${type} платежной системы`,
	getConstructor: (type: string) => `Не удалось получить настройки для ${type} платежной системы`,
	getProxy: (type: string) => `Не удалось получить прокси для ${type} платежной системы`,
	getWorkTime: (type: string) => `Не удалось получить рабочее время для ${type} платежной системы`,
	getWrongExtension: (file: string) => `${file} имеет некорректный формат`,
};
