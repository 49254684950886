export const isRequired = (string: string) => (!string ? "Необходимо заполнить" : undefined);

export const minLength = (min: number) => (str: string) => str.length < min ? `Минимальная длина ${min}` : undefined;

export const maxLength = (max: number) => (str: string) => str.length > max ? `Максимальная длина: ${max}` : undefined;

export const isValidCode = (code: string) => (/^[A-Za-z]*$/.test(code) ? undefined : "Некорректный код");

export const isValidSymbol = (symbol: string) => (!symbol.includes(" ") ? undefined : "Некорректный символ");

export const isValidName = (name: string, { is_crypto }) => {
	if (!is_crypto && !name) {
		return undefined;
	}

	if (is_crypto && !name) return "Необходимо заполнить";

	if (!/^[A-Z a-z 0-9\.\-\(\)]*$/.test(name)) {
		return "Разрешенные символы: a-Z 0-9 .-()";
	}

	return maxLength(50)(name);
};
