import { PsOptionType } from "modules/DefaultSettings/PsSelect";
import Options from "modules/DefaultSettings/PsSelect/components/Options";
import SearchBar from "modules/DefaultSettings/PsSelect/components/SearchBar";
import { Dispatch, FC, SetStateAction, useState } from "react";

import styles from "./selectBox.module.scss";
const SelectBox: FC<SelectBoxType> = ({ options, onChange, setIsSearchbarOpen, value }) => {
	const [filteredOptions, setFilteredOptions] = useState(options);

	return (
		<div className={styles.searchBarWrapper}>
			<SearchBar setFilteredOptions={setFilteredOptions} options={options}>
				<Options
					filteredOptions={filteredOptions}
					onChange={onChange}
					setIsSearchbarOpen={setIsSearchbarOpen}
					value={value}
				/>
			</SearchBar>
		</div>
	);
};

export default SelectBox;

type SelectBoxType = {
	readonly options: readonly PsOptionType[];
	readonly onChange: Dispatch<PsOptionType>;
	readonly setIsSearchbarOpen: Dispatch<SetStateAction<boolean>>;
	readonly value: PsOptionType;
};
