import { fetchApi } from "api/fetch";

const defaultSettingsGroup = {
	getLanguages: () => fetchApi<readonly GetLanguagesType[]>({ url: `translations/language` }),
	getWalletTypes: () => fetchApi<readonly GetWalletTypesType[]>({ url: `translations/wallet-types` }),
	getHints: () => fetchApi<readonly GetHintType[]>({ url: `translations/hint` }),
	createHints: (data: CreateHintsParams) =>
		fetchApi<CreateHintsType>({ url: `translations/hint`, config: { data }, method: "POST" }),
	deleteHints: (id: HintType["uuid"], data: DeleteHintsParams) =>
		fetchApi<DeleteHintsType>({ url: `translations/hint/${id}`, config: { data }, method: "PUT" }),
	updateHints: (id: string, data: UpdateHintsParams) =>
		fetchApi<UpdateHintsType>({ url: `translations/hint/${id}`, config: { data }, method: "PUT" }),
	getFormTypes: () => fetchApi<readonly GetFormTypesType[]>({ url: `translations/form-type` }),
};

export default defaultSettingsGroup;

export type DeleteHintsType = readonly unknown[];

export type DeleteHintsParams = {
	readonly name: HintType["name"];
	readonly translations: readonly TranslationType[];
};

export type CreateHintsType = {
	readonly uuid: HintType["uuid"];
};

export type CreateHintsParams = {
	readonly form_type: HintType["form_type"];
	readonly name: HintType["name"];
	readonly translations: readonly TranslationType[];
	readonly wallet_type: HintType["wallet_type"];
};

export type UpdateHintsType = readonly unknown[];

export type UpdateHintsParams = {
	readonly name: HintType["name"];
	readonly translations: readonly TranslationType[];
};

export type GetLanguagesType = string;

export type GetFormTypesType = {
	readonly title: string;
	readonly type: GetHintType["form_type"];
};

export type GetWalletTypesType = {
	readonly code: string;
	readonly name: string;
	readonly payment_type: "manual" | "p2p" | "api";
};

export type TranslationType = {
	readonly language: string;
	readonly text: string;
};

export type GetHintType = HintType;

export type HintType = {
	readonly created_at: string;
	readonly updated_at: string;
	readonly form_type: "payment" | "api" | "requisite";
	readonly is_default: boolean;
	readonly is_manual: boolean;
	readonly name: string;
	readonly translations: readonly TranslationType[];
	readonly uuid: string;
	readonly wallet_type: string;
	readonly wallet_type_uuid: string | null;
};
