// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Domain__domain-E8s{color:#0072c3}.Domain__wrapper-P6q{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/modules/Counterparty/components/Domain/Domain.module.scss"],"names":[],"mappings":"AAAA,oBACI,aAAA,CAGJ,qBACI,YAAA,CACA,6BAAA","sourcesContent":[".domain {\n    color: #0072c3;\n}\n\n.wrapper {\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"domain": `Domain__domain-E8s`,
	"wrapper": `Domain__wrapper-P6q`
};
export default ___CSS_LOADER_EXPORT___;
