import { Loader as PayKitLoader } from "pay-kit";
import { FC, ReactElement } from "react";

const Loader: FC<LoaderType> = ({ children, className, color, isLoading }) => {
	return isLoading ? (
		<div className={className}>
			<PayKitLoader color={color} />
		</div>
	) : (
		children
	);
};

export default Loader;

type LoaderType = {
	readonly children: ReactElement;
	readonly className?: string;
	readonly isLoading: boolean;
	readonly color?: string;
};
