// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages__actions-G8K{overflow:visible !important}.pages__statistics-Ti0{display:flex;justify-content:space-between;align-items:center}.pages__info-Iuu{display:flex}.pages__info-Iuu svg{margin-right:6px}.pages__dropdownBtn-CR1{border:none;background:none}.pages__dropdownOption-pzc{right:0 !important;width:160px !important}.pages__button-Gef{display:flex;align-items:center}.pages__button-Gef span{margin-right:4px;display:block}`, "",{"version":3,"sources":["webpack://./src/modules/Counterparty/pages/IndexPage.module.scss"],"names":[],"mappings":"AAAA,oBACC,2BAAA,CAGD,uBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,iBACE,YAAA,CAEA,qBACE,gBAAA,CAIJ,wBACC,WAAA,CACA,eAAA,CAGD,2BACE,kBAAA,CACD,sBAAA,CAGD,mBACE,YAAA,CACA,kBAAA,CAEA,wBACE,gBAAA,CACA,aAAA","sourcesContent":[".actions {\n\toverflow: visible !important;\n}\n\n.statistics {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.info {\n  display: flex;\n\n  svg {\n    margin-right: 6px;\n  }\n}\n\n.dropdownBtn {\n\tborder: none;\n\tbackground: none;\n}\n\n.dropdownOption {\n  right: 0 !important;\n\twidth: 160px !important;\n}\n\n.button {\n  display: flex;\n  align-items: center;\n\n  span {\n    margin-right: 4px;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `pages__actions-G8K`,
	"statistics": `pages__statistics-Ti0`,
	"info": `pages__info-Iuu`,
	"dropdownBtn": `pages__dropdownBtn-CR1`,
	"dropdownOption": `pages__dropdownOption-pzc`,
	"button": `pages__button-Gef`
};
export default ___CSS_LOADER_EXPORT___;
