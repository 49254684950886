import useClickOutside from "@paykassma/pay-kit/lib/utils/useOutsideClick";
import { useActionsContext } from "modules/DefaultSettings/ActionsProvider";
import SelectBox from "modules/DefaultSettings/PsSelect/components/SelectBox";
import SelectedPsTitle from "modules/DefaultSettings/PsSelect/components/SelectedPsTitle";
import SelectionDisplay from "modules/DefaultSettings/PsSelect/components/SelectionDisplay";
import SelectPanel from "modules/DefaultSettings/PsSelect/components/SelectPanel";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import styles from "./psSelect.module.scss";
const PsSelect: FC<PsSelectType> = ({ options, value, onChange, className }) => {
	const [isSearchbarOpen, setIsSearchbarOpen] = useState(false);
	const selectionSelectPanelRef = useRef(null);
	const { isSaved, open } = useActionsContext();

	useClickOutside(selectionSelectPanelRef, () => {
		setIsSearchbarOpen(false);
	});

	return (
		<SelectPanel>
			<div
				data-test-id="xFNDng5_AYts3_0jsSqQY"
				ref={selectionSelectPanelRef}
				className={[styles.selectPanelWrapper, className].join(" ")}
				onClick={() => !isSaved && open()}
			>
				<SelectionDisplay value={value}>
					<SelectedPsTitle
						value={value}
						setIsSearchbarOpen={setIsSearchbarOpen}
						isSearchbarOpen={isSearchbarOpen}
						onChange={onChange}
					/>
				</SelectionDisplay>
				{isSearchbarOpen && (
					<SelectBox options={options} onChange={onChange} setIsSearchbarOpen={setIsSearchbarOpen} value={value} />
				)}
			</div>
		</SelectPanel>
	);
};

export default PsSelect;

type PsSelectType = {
	readonly options: readonly PsOptionType[];
	readonly value: PsOptionType;
	readonly onChange: Dispatch<SetStateAction<PsOptionType | null>>;
	readonly className?: string;
};

export type PsOptionType = {
	readonly payment?: "manual" | "p2p" | "api";
	readonly label: string;
	readonly value: string;
};
