import API from "api";
import { TimezoneType } from "api/settingsGroup";
import { useState } from "react";


const useSetTimeZone = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const update = (timeZone: TimezoneType[`params`][`timezone`]) => {
		setError(null);
		setIsLoading(true);

		API.settings.setTimeZone(timeZone)
			.then((res) => {
				if (res.status === "ok") {

					window.pushAlert({
						type: `success`,
						description: `Запись обновлена`
					});
				} else {
					throw new Error("Unexpected response in useChangeLogUsers");
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			}).finally(() => {
				setIsLoading(false);
			});
	};

	return ({
		update,
		isLoading,
		error
	});
};

export default useSetTimeZone;