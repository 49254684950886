import { Table } from "@paykassma/pay-kit";
import { Transaction } from "api/blackListGroup";
import Filter from "components/Filter";
import styles from "./Index.module.scss";
import { useTransactions } from "./useTransactions";
import ReloadButton from "components/ReloadButton";
import { Link } from "react-router-dom";

const Transactions = () => {
	const {
		
		currentPage,
		limit,
		load,
		paginationInfo,
		setCurrentPage,
		setLimit,
		filter,
		setFilter,
		counterparties,
		isCounterpartiesLoading,
		transactions,
		isTransactionsLoading,
	} = useTransactions();

	return (
		<div className={styles.index}>
			<Filter
				fields={[
					{
						name: `id`,
						placeholder: "ID",
						component: "textInput",
						className: styles.idField,
					},
					{
						name: `fraud_cases_id`,
						placeholder: "ID Инцидента",
						component: "textInput",
						className: styles.caseField,
					},
					{
						name: `counterparty_uuid`,
						placeholder: "Контрагент",
						component: "select",
						options: [{ label: "Все", value: "" }, ...counterparties],
						className: styles.counterpartyField,
						isLoading: isCounterpartiesLoading,
					},
					{
						name: `transactions_id`,
						placeholder: "ID Транзакции контрагента",
						component: "textInput",
						className: styles.transactionField,
					},
					{
						name: `created_at`,
						component: "dateRange",
						fromPlaceholder: "Создан от",
						toPlaceholder: "Создан до",
						dateTimeFormat: "YYYY-MM-DD",
						className: styles.createdField,
					},
				]}
				onApply={setFilter}
				values={filter}
			/>

			<Table
				data={transactions}
				columns={[
					{
						title: "ID",
						dataIndex: "id",
						key: "id",
					},
					{
						title: "ID инцидента",
						dataIndex: "fraud_cases_id",
						key: "fraud_cases_id",
						render: (form: Transaction) => <Link to={`/black-list/incidents/${form.fraud_cases_id}/requisites`}>{form.fraud_cases_id}</Link>,
					},
					{
						title: "Контрагент",
						dataIndex: "counterparty_uuid",
						key: "counterparty_uuid",
					},
					{
						title: "ID Транзакции клиента",
						dataIndex: "transactions_id",
						key: "transactions_id",
					},
					{
						title: "Создан",
						dataIndex: "created_at",
						key: "created_at",
						render: (data: Transaction) => data.created_at.date,
					},
				]}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.offset / limit + 1,
								setCurrentPage: (page: any) => setCurrentPage(page as number),
								limit,
								setLimit: (newLimit) => {
									setCurrentPage(1);
									setLimit(newLimit);
								},
								lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
								prefixElement: (
									<ReloadButton
										data-test-id=""
										isLoading={false}
										onClick={() =>
											load({
												id: filter.id,
												counterparty_uuid: filter.counterparty_uuid,
												fraud_cases_id: filter.fraud_cases_id,
												transactions_id: filter.transactions_id,
												created_from: filter.created_at?.from,
												created_to: filter.created_at?.to,
												limit,
												offset: (currentPage - 1) * limit,
											})
										}
									/>
								),
						  }
						: undefined
				}
				isLoading={isTransactionsLoading}
				rowKey="id"
			/>
		</div>
	);
};

export default Transactions;
