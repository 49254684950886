// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Transactions__index-Nwr{margin-top:24px}.Transactions__idField-WId{width:220px}.Transactions__caseField-uv1{width:220px}.Transactions__counterpartyField-jlZ{width:220px}.Transactions__transactionField-sc5{width:220px}.Transactions__createdField-_qL{width:230px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Transactions/Index.module.scss"],"names":[],"mappings":"AAAA,yBACE,eAAA,CAGF,2BACE,WAAA,CAGF,6BACE,WAAA,CAGF,qCACE,WAAA,CAGF,oCACE,WAAA,CAGF,gCACE,WAAA","sourcesContent":[".index {\n  margin-top: 24px;\n}\n\n.idField {\n  width: 220px;\n}\n\n.caseField {\n  width: 220px;\n}\n\n.counterpartyField {\n  width: 220px;\n}\n\n.transactionField {\n  width: 220px;\n}\n\n.createdField {\n  width: 230px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Transactions__index-Nwr`,
	"idField": `Transactions__idField-WId`,
	"caseField": `Transactions__caseField-uv1`,
	"counterpartyField": `Transactions__counterpartyField-jlZ`,
	"transactionField": `Transactions__transactionField-sc5`,
	"createdField": `Transactions__createdField-_qL`
};
export default ___CSS_LOADER_EXPORT___;
