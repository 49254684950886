// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectedPsTitle__selectedPs-UBp{display:flex;align-items:center;gap:8px}.SelectedPsTitle__selectedPs-UBp .SelectedPsTitle__wrapper-UDl{display:flex;align-items:center;flex-direction:row;gap:12px}.SelectedPsTitle__selectedPs-UBp .SelectedPsTitle__psLogo-CrT.SelectedPsTitle__psLogo-CrT{width:62px;background-position:center}.SelectedPsTitle__selectedPs-UBp .SelectedPsTitle__caret-dWJ.SelectedPsTitle__caret-dWJ{margin-left:auto;display:flex;width:30px;padding:0 8px 0 8px}.SelectedPsTitle__selectedPs-UBp .SelectedPsTitle__caretDown-BGw{transform:rotateX(180deg)}.SelectedPsTitle__selectedPs-UBp .SelectedPsTitle__tooltip-fCB{white-space:nowrap;width:max-content;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/modules/DefaultSettings/PsSelect/components/SelectedPsTitle/selectedPsTitle.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,+DACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CAGF,0FACE,UAAA,CACA,0BAAA,CAGF,wFACE,gBAAA,CACA,YAAA,CACA,UAAA,CACA,mBAAA,CAGF,iEACE,yBAAA,CAGF,+DACE,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".selectedPs {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  .wrapper {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    gap: 12px;\n  }\n\n  .psLogo.psLogo {\n    width: 62px;\n    background-position: center;\n  }\n\n  .caret.caret {\n    margin-left: auto;\n    display: flex;\n    width: 30px;\n    padding: 0 8px 0 8px;\n  }\n\n  .caretDown {\n    transform: rotateX(180deg);\n  }\n\n  .tooltip {\n    white-space: nowrap;\n    width: max-content;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedPs": `SelectedPsTitle__selectedPs-UBp`,
	"wrapper": `SelectedPsTitle__wrapper-UDl`,
	"psLogo": `SelectedPsTitle__psLogo-CrT`,
	"caret": `SelectedPsTitle__caret-dWJ`,
	"caretDown": `SelectedPsTitle__caretDown-BGw`,
	"tooltip": `SelectedPsTitle__tooltip-fCB`
};
export default ___CSS_LOADER_EXPORT___;
