// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditModal__modal-Ejk{width:100%}.EditModal__button-iWX{margin-right:0;margin-left:auto}.EditModal__group-YQT{display:none}.EditModal__groupPart-C1s{margin-bottom:32px;padding:16px;border-radius:8px;border:1px solid var(--blue-gray-30, #dde1e6)}.EditModal__groupTitle-uXm{margin-bottom:16px;color:var(--type-primary, #21272a);font-family:Inter;font-size:14px;font-style:normal;font-weight:600;line-height:20px}.EditModal__toggler-B_t{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/modules/Counterparty/components/EditModal/EditModal.module.scss"],"names":[],"mappings":"AAAA,sBACC,UAAA,CAGD,uBACC,cAAA,CACA,gBAAA,CAGD,sBACC,YAAA,CAGD,0BACC,kBAAA,CACC,YAAA,CACD,iBAAA,CACA,6CAAA,CAID,2BACE,kBAAA,CACD,kCAAA,CAEA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGD,wBACC,YAAA,CACA,6BAAA","sourcesContent":[".modal {\n\twidth: 100%;\n}\n\n.button {\n\tmargin-right: 0;\n\tmargin-left: auto;\n}\n\n.group {\n\tdisplay: none;\n}\n\n.groupPart {\n\tmargin-bottom: 32px;\n  padding: 16px;\n\tborder-radius: 8px;\n\tborder: 1px solid var(--blue-gray-30, #dde1e6);\n  \n}\n\n.groupTitle {\n  margin-bottom: 16px;\n\tcolor: var(--type-primary, #21272a);\n\t/* Base_1/14-20-sb */\n\tfont-family: Inter;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: 20px; /* 142.857% */\n}\n\n.toggler {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `EditModal__modal-Ejk`,
	"button": `EditModal__button-iWX`,
	"group": `EditModal__group-YQT`,
	"groupPart": `EditModal__groupPart-C1s`,
	"groupTitle": `EditModal__groupTitle-uXm`,
	"toggler": `EditModal__toggler-B_t`
};
export default ___CSS_LOADER_EXPORT___;
