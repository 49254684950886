import { FC, useContext } from "react";
import { Button, DatePicker, Modal, Select, TextInput, Toggler } from "@paykassma/pay-kit";
import { Counterparty, CounterpartyGroup } from "api/counterpartyGroup";
import styles from "./EditModal.module.scss";
import { getGroupsOptions } from "modules/Counterparty/utils/mapGroups";
import { useUpdateCounterparty } from "modules/Counterparty/hooks/updateCounterparty";
import { useIndexPage } from "modules/Counterparty/pages/useIndexPage";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { ErrorBoundary } from "react-error-boundary";
import { WalletTypesContext } from "contexts/WalletTypesContext";

type Props = {
	onClose: () => void;
	isOpen: boolean;
	// Counterparty для редактирования.
	selectedElement: Counterparty;
};

const getCheckedScrapers = (checkedScraperIds?: Array<number>): Record<string, true> => {
	const keys: Record<string, true> = {};

	checkedScraperIds?.forEach((el) => {
		keys[el] = true;
	});

	return keys;
};
export const EditModal: FC<Props> = ({ isOpen, onClose, selectedElement }) => {
	const { handleCloseModal } = useIndexPage();
	const { walletTypes } = useContext(WalletTypesContext);
	const client = useQueryClient();
	const [searchParams] = useSearchParams();
	const { updateCounterparty } = useUpdateCounterparty({
		id: selectedElement?.id || "",
		onSuccess: () => {
			handleCloseModal();
			window.pushAlert({ type: "success", description: "Контрагент обновлен" });
			client.invalidateQueries({
				queryKey: searchParams.get("group") ? ["counterPartiesByGroup", searchParams.get("group")] : ["counterparties"],
			});
			onClose();
		},
	});

	const formik = useFormik({
		initialValues: {
			identifier: selectedElement?.identifier,
			group: selectedElement?.group?.toString(),
			parsers: getCheckedScrapers(selectedElement?.applied_scarpers),
			// parsing_start_date?: string
		},
		onSubmit: (values) => {
			updateCounterparty({
				id: selectedElement?.id || "",
				data: {
					applied_scarpers: Object.entries(values.parsers)
						.filter((ent) => ent[1])
						.map((ent) => +ent[0]),
					// TODO Добавить тип для "Не выбрано ничего"
					...(values.group !== "null" ? { group: +values.group as CounterpartyGroup } : {}),
					identifier: values.identifier,
					parsing_start_date: values.parsing_start_date,
				},
			});
		},
	});

	return (
		<Modal title="Редактирование контрагента" onClose={onClose} isOpen={isOpen}>
			<ErrorBoundary fallback={<div>Error</div>}>
				<div className={styles.modal}>
					<form onSubmit={formik.handleSubmit}>
						<TextInput
							name={"domain"}
							label="ID"
							onChange={(e) => formik.handleChange({ target: { name: "domain", value: e.target.value } })}
							value={formik.values.identifier}
						/>

						<Select
							name="group"
							label="Группа"
							options={[{ value: "null", label: "Не определена" }, ...getGroupsOptions()]}
							onChange={(v) => formik.handleChange({ target: { name: "group", value: v } })}
							value={formik.values.group}
						/>
						<div className={styles.groupTitle}>Парсеры</div>
						<div className={styles.groupPart}>
							{selectedElement?.available_scarpers?.map((el) => (
								<Toggler
									className={styles.toggler}
									key={el.id}
									label={el.name}
									value={formik.values.parsers[el.id]}
									onChange={(v) => formik.handleChange({ target: { name: `parsers.${el.id}`, value: v } })}
								/>
							))}
						</div>
						<Button classname={styles.button} type="submit">
							Сохранить
						</Button>
					</form>
				</div>
			</ErrorBoundary>
		</Modal>
	);
};
