// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Requisites__index-OHD{margin-top:24px}.Requisites__addBtn-vhS{margin-bottom:24px}.Requisites__idField-nNH{width:220px}.Requisites__fraudCasesField-q46{width:220px}.Requisites__typeField-Lk3{width:220px}.Requisites__valueField-Fey{width:220px}.Requisites__createdField-oov{width:230px}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Requisites/Index.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CAGF,wBACE,kBAAA,CAGF,yBACE,WAAA,CAGF,iCACE,WAAA,CAGF,2BACE,WAAA,CAGF,4BACE,WAAA,CAGF,8BACE,WAAA","sourcesContent":[".index {\n  margin-top: 24px;\n}\n\n.addBtn {\n  margin-bottom: 24px;\n}\n\n.idField {\n  width: 220px;\n}\n\n.fraudCasesField {\n  width: 220px;\n}\n\n.typeField {\n  width: 220px;\n}\n\n.valueField {\n  width: 220px;\n}\n\n.createdField {\n  width: 230px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": `Requisites__index-OHD`,
	"addBtn": `Requisites__addBtn-vhS`,
	"idField": `Requisites__idField-nNH`,
	"fraudCasesField": `Requisites__fraudCasesField-q46`,
	"typeField": `Requisites__typeField-Lk3`,
	"valueField": `Requisites__valueField-Fey`,
	"createdField": `Requisites__createdField-oov`
};
export default ___CSS_LOADER_EXPORT___;
