import { DatePicker, DateRangePicker, Select, TextInput } from "@paykassma/pay-kit";
import React from "react";

import CheckboxGroup from "./CheckboxGroup";
import Spoiler from "./Spoiler";

export type ReservedComponentsType = "textInput" | "select" | "checkboxGroup" | "date" | "dateRange";

export type OptionType = { readonly value: string | undefined | null; readonly label: string };

export type FieldPropsType = {
	readonly name: string;
	readonly value: any;
	readonly onChange: (v: any) => void;
	readonly onClear: () => void;
	readonly label?: string;
	readonly placeholder?: string;
	readonly className?: string;
	readonly component: ReservedComponentsType;
	readonly options?: readonly OptionType[];
	readonly isAdditional?: boolean;
	readonly isMulti?: boolean;
	readonly isLoading?: boolean;
	readonly dateTimeFormat?: string;
	readonly toPlaceholder?: string;
	readonly fromPlaceholder?: string;
	readonly blockPredicate?: (date: Date) => boolean;
	readonly hide?: boolean;
};

const Field: React.FC<FieldPropsType> = ({
	name,
	label,
	placeholder,
	value,
	component,
	options,
	className,
	onChange,
	onClear,
	isAdditional,
	isLoading,
	dateTimeFormat,
	isMulti,
	toPlaceholder,
	fromPlaceholder,
	blockPredicate,
}) => {
	let renderComponent;

	switch (component) {
		case "textInput":
			renderComponent = (
				<TextInput
					className={className}
					name={name}
					placeholder={placeholder}
					label={label}
					value={value || ""}
					onChange={(e) => onChange(e.target.value)}
					data-test-id=""
					noError
				/>
			);
			break;
		case "select":
			renderComponent = (
				<Select
					className={className}
					name={name}
					placeholder={placeholder}
					label={label}
					value={value}
					options={options || []}
					onChange={onChange}
					data-test-id=""
					isLoading={isLoading}
					isMulti={isMulti}
					noError
				/>
			);
			break;
		case "checkboxGroup":
			renderComponent = (
				<CheckboxGroup
					className={className}
					name={name}
					placeholder={placeholder}
					label={label}
					value={value}
					options={options || []}
					onChange={onChange}
					data-test-id=""
				/>
			);
			break;
		case "date":
			renderComponent = (
				<DatePicker
					className={className}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					data-test-id=""
					dateTimeFormat={dateTimeFormat}
					withErrorContainer={false}
					blockPredicate={blockPredicate}
				/>
			);
			break;
		case "dateRange":
			renderComponent = (
				<DateRangePicker
					className={className}
					onChange={onChange}
					value={value}
					dateFormat={dateTimeFormat}
					data-test-id="useless-data-test-id"
					toPlaceholder={toPlaceholder}
					fromPlaceholder={fromPlaceholder}
					blockPredicate={blockPredicate}
				/>
			);
			break;
	}

	if (isAdditional) {
		return (
			<Spoiler
				label={label || (placeholder as string)}
				expanded={value !== undefined}
				onClear={value !== undefined ? onClear : undefined}
			>
				{renderComponent}
			</Spoiler>
		);
	}

	return renderComponent;
};

export default Field;
