import { GetHintType, HintType, TranslationType } from "api/defaultSettingsGroup";
import { PsOptionType } from "modules/DefaultSettings/PsSelect";

export const isRequired: IsRequired = (value) => (!value ? `Обязательное поле` : undefined);

export const isLanguageRequired: IsLanguageRequiredType = (value) =>
	(value?.length || 0) < 1 ? "Выберите хотя бы один язык" : undefined;

export const isEnglishTranslationRequired: IsLanguageRequiredType = (value) => {
	const englishTranslationExists = value?.find((translation) => translation.language === `en`);
	return !englishTranslationExists ? `Английский язык обязателен` : undefined;
};

export const processTranslationOptions: ProcessTranslationOptionsType = (options) => (formState, currentValue) => {
	const chosenLanguages = formState.translations.map((translation) => translation.language);
	return options.filter(
		(translation) => currentValue === translation.value || !chosenLanguages.includes(translation.value)
	);
};

export const findHint: FindHintType = (list, isLoading, formType, selectedPs) => {
	return !isLoading
		? list?.find((hint) => hint.form_type === formType && hint.wallet_type === selectedPs.value)
		: undefined;
};

export const arraysAreEqual: ArraysAreEqualType = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const sortedArr1 = arr1.slice().sort((a, b) => (a > b ? 1 : -1));
	const sortedArr2 = arr2.slice().sort((a, b) => (a > b ? 1 : -1));

	for (let i = 0; i < sortedArr1.length; i++) {
		const obj1 = sortedArr1[i];
		const obj2 = sortedArr2[i];

		if (obj1.language !== obj2.language || obj1.text !== obj2.text) {
			return false;
		}
	}

	return true;
};

type IsLanguageRequiredType = (value: readonly TranslationType[]) => string | undefined;
type IsRequired = (value: string) => string | undefined;

type ArraysAreEqualType = (arr1: readonly TranslationType[], arr2: readonly TranslationType[]) => boolean;

type FindHintType = (
	list: readonly HintType[] | null,
	isLoading: boolean,
	formType: GetHintType["form_type"],
	selectedPs: PsOptionType
) => HintType | undefined;

type ProcessTranslationOptionsType = (
	options: readonly { readonly label: string; readonly value: string }[]
) => (
	formState: { readonly translations: readonly TranslationType[] },
	currentValue: string
) => readonly { readonly label: string; readonly value: string }[];
